export default {
  appContainer: 1,
  breadcrumbs: 100,
  footer: 100,
  header: 100,
  businessCard: 50,
  notificationBanner: 999,
  purposeCard: 50,
  pageLayoutFullscreen: 30,
  pageLayoutPageActions: 20,
  pageLayout: 10,
  homeLogo: 5,
  particleCanvas: 0,
};
