export const staticC19Data = {
  countries: [
    {
      name: 'Afghanistan',
      alpha2code: 'AF',
      alpha3code: 'AFG',
      latitude: 33.93911,
      longitude: 67.709953,
    },
    {
      name: 'Åland Islands',
      alpha2code: 'AX',
      alpha3code: 'ALA',
      latitude: 60.1995487,
      longitude: 20.3711715,
    },
    {
      name: 'Albania',
      alpha2code: 'AL',
      alpha3code: 'ALB',
      latitude: 41.153332,
      longitude: 20.168331,
    },
    {
      name: 'Algeria',
      alpha2code: 'DZ',
      alpha3code: 'DZA',
      latitude: 28.033886,
      longitude: 1.659626,
    },
    {
      name: 'American Samoa',
      alpha2code: 'AS',
      alpha3code: 'ASM',
      latitude: -14.270972,
      longitude: -170.132217,
    },
    {
      name: 'Andorra',
      alpha2code: 'AD',
      alpha3code: 'AND',
      latitude: 42.546245,
      longitude: 1.601554,
    },
    {
      name: 'Angola',
      alpha2code: 'AO',
      alpha3code: 'AGO',
      latitude: -11.202692,
      longitude: 17.873887,
    },
    {
      name: 'Anguilla',
      alpha2code: 'AI',
      alpha3code: 'AIA',
      latitude: 18.220554,
      longitude: -63.068615,
    },
    {
      name: 'Antarctica',
      alpha2code: 'AQ',
      alpha3code: 'ATA',
      latitude: -75.250973,
      longitude: -0.071389,
    },
    {
      name: 'Antigua and Barbuda',
      alpha2code: 'AG',
      alpha3code: 'ATG',
      latitude: 17.060816,
      longitude: -61.796428,
    },
    {
      name: 'Argentina',
      alpha2code: 'AR',
      alpha3code: 'ARG',
      latitude: -38.416097,
      longitude: -63.616672,
    },
    {
      name: 'Armenia',
      alpha2code: 'AM',
      alpha3code: 'ARM',
      latitude: 40.069099,
      longitude: 45.038189,
    },
    {
      name: 'Aruba',
      alpha2code: 'AW',
      alpha3code: 'ABW',
      latitude: 12.52111,
      longitude: -69.968338,
    },
    {
      name: 'Australia',
      alpha2code: 'AU',
      alpha3code: 'AUS',
      latitude: -25.274398,
      longitude: 133.775136,
    },
    {
      name: 'Austria',
      alpha2code: 'AT',
      alpha3code: 'AUT',
      latitude: 47.516231,
      longitude: 14.550072,
    },
    {
      name: 'Azerbaijan',
      alpha2code: 'AZ',
      alpha3code: 'AZE',
      latitude: 40.143105,
      longitude: 47.576927,
    },
    {
      name: 'Bahamas',
      alpha2code: 'BS',
      alpha3code: 'BHS',
      latitude: 25.03428,
      longitude: -77.39628,
    },
    {
      name: 'Bahrain',
      alpha2code: 'BH',
      alpha3code: 'BHR',
      latitude: 25.930414,
      longitude: 50.637772,
    },
    {
      name: 'Bangladesh',
      alpha2code: 'BD',
      alpha3code: 'BGD',
      latitude: 23.684994,
      longitude: 90.356331,
    },
    {
      name: 'Barbados',
      alpha2code: 'BB',
      alpha3code: 'BRB',
      latitude: 13.193887,
      longitude: -59.543198,
    },
    {
      name: 'Belarus',
      alpha2code: 'BY',
      alpha3code: 'BLR',
      latitude: 53.709807,
      longitude: 27.953389,
    },
    {
      name: 'Belgium',
      alpha2code: 'BE',
      alpha3code: 'BEL',
      latitude: 50.503887,
      longitude: 4.469936,
    },
    {
      name: 'Belize',
      alpha2code: 'BZ',
      alpha3code: 'BLZ',
      latitude: 17.189877,
      longitude: -88.49765,
    },
    {
      name: 'Benin',
      alpha2code: 'BJ',
      alpha3code: 'BEN',
      latitude: 9.30769,
      longitude: 2.315834,
    },
    {
      name: 'Bermuda',
      alpha2code: 'BM',
      alpha3code: 'BMU',
      latitude: 32.321384,
      longitude: -64.75737,
    },
    {
      name: 'Bhutan',
      alpha2code: 'BT',
      alpha3code: 'BTN',
      latitude: 27.514162,
      longitude: 90.433601,
    },
    {
      name: 'Bolivia',
      alpha2code: 'BO',
      alpha3code: 'BOL',
      latitude: -16.290154,
      longitude: -63.588653,
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      alpha2code: 'BQ',
      alpha3code: 'BES',
      latitude: 12.1683718,
      longitude: -68.308183,
    },
    {
      name: 'Bosnia and Herzegovina',
      alpha2code: 'BA',
      alpha3code: 'BIH',
      latitude: 43.915886,
      longitude: 17.679076,
    },
    {
      name: 'Botswana',
      alpha2code: 'BW',
      alpha3code: 'BWA',
      latitude: -22.328474,
      longitude: 24.684866,
    },
    {
      name: 'Bouvet Island',
      alpha2code: 'BV',
      alpha3code: 'BVT',
      latitude: -54.423199,
      longitude: 3.413194,
    },
    {
      name: 'Brazil',
      alpha2code: 'BR',
      alpha3code: 'BRA',
      latitude: -14.235004,
      longitude: -51.92528,
    },
    {
      name: 'British Indian Ocean Territory',
      alpha2code: 'IO',
      alpha3code: 'IOT',
      latitude: -6.343194,
      longitude: 71.876519,
    },
    {
      name: 'British Virgin Islands',
      alpha2code: 'VG',
      alpha3code: 'VGB',
      latitude: 18.420695,
      longitude: -64.639968,
    },
    {
      name: 'Brunei',
      alpha2code: 'BN',
      alpha3code: 'BRN',
      latitude: 4.535277,
      longitude: 114.727669,
    },
    {
      name: 'Bulgaria',
      alpha2code: 'BG',
      alpha3code: 'BGR',
      latitude: 42.733883,
      longitude: 25.48583,
    },
    {
      name: 'Burkina Faso',
      alpha2code: 'BF',
      alpha3code: 'BFA',
      latitude: 12.238333,
      longitude: -1.561593,
    },
    {
      name: 'Burundi',
      alpha2code: 'BI',
      alpha3code: 'BDI',
      latitude: -3.373056,
      longitude: 29.918886,
    },
    {
      name: 'Cabo Verde',
      alpha2code: 'CV',
      alpha3code: 'CPV',
      latitude: 16.002082,
      longitude: -24.013197,
    },
    {
      name: 'Cambodia',
      alpha2code: 'KH',
      alpha3code: 'KHM',
      latitude: 12.565679,
      longitude: 104.990963,
    },
    {
      name: 'Cameroon',
      alpha2code: 'CM',
      alpha3code: 'CMR',
      latitude: 7.369722,
      longitude: 12.354722,
    },
    {
      name: 'Canada',
      alpha2code: 'CA',
      alpha3code: 'CAN',
      latitude: 56.130366,
      longitude: -106.346771,
    },
    {
      name: 'CAR',
      alpha2code: 'CF',
      alpha3code: 'CAF',
      latitude: 6.611111,
      longitude: 20.939444,
    },
    {
      name: 'Cayman Islands',
      alpha2code: 'KY',
      alpha3code: 'CYM',
      latitude: 19.513469,
      longitude: -80.566956,
    },
    {
      name: 'Chad',
      alpha2code: 'TD',
      alpha3code: 'TCD',
      latitude: 15.454166,
      longitude: 18.732207,
    },
    {
      name: 'Channel Islands',
      alpha2code: null,
      alpha3code: null,
      latitude: 49.372284,
      longitude: -2.364351,
    },
    {
      name: 'Chile',
      alpha2code: 'CL',
      alpha3code: 'CHL',
      latitude: -35.675147,
      longitude: -71.542969,
    },
    {
      name: 'China',
      alpha2code: 'CN',
      alpha3code: 'CHN',
      latitude: 35.86166,
      longitude: 104.195397,
    },
    {
      name: 'Christmas Island',
      alpha2code: 'CX',
      alpha3code: 'CXR',
      latitude: -10.447525,
      longitude: 105.690449,
    },
    {
      name: 'Cocos (Keeling) Islands',
      alpha2code: 'CC',
      alpha3code: 'CCK',
      latitude: -12.164165,
      longitude: 96.870956,
    },
    {
      name: 'Colombia',
      alpha2code: 'CO',
      alpha3code: 'COL',
      latitude: 4.570868,
      longitude: -74.297333,
    },
    {
      name: 'Comoros',
      alpha2code: 'KM',
      alpha3code: 'COM',
      latitude: -11.875001,
      longitude: 43.872219,
    },
    {
      name: 'Congo',
      alpha2code: 'CG',
      alpha3code: 'COG',
      latitude: -0.228021,
      longitude: 15.827659,
    },
    {
      name: 'Cook Islands',
      alpha2code: 'CK',
      alpha3code: 'COK',
      latitude: -21.236736,
      longitude: -159.777671,
    },
    {
      name: 'Costa Rica',
      alpha2code: 'CR',
      alpha3code: 'CRI',
      latitude: 9.748917,
      longitude: -83.753428,
    },
    {
      name: 'Croatia',
      alpha2code: 'HR',
      alpha3code: 'HRV',
      latitude: 45.1,
      longitude: 15.2,
    },
    {
      name: 'Cuba',
      alpha2code: 'CU',
      alpha3code: 'CUB',
      latitude: 21.521757,
      longitude: -77.781167,
    },
    {
      name: 'Curaçao',
      alpha2code: 'CW',
      alpha3code: 'CUW',
      latitude: 12.16957,
      longitude: -68.990021,
    },
    {
      name: 'Cyprus',
      alpha2code: 'CY',
      alpha3code: 'CYP',
      latitude: 35.126413,
      longitude: 33.429859,
    },
    {
      name: 'Czech Republic',
      alpha2code: 'CZ',
      alpha3code: 'CZE',
      latitude: 49.817492,
      longitude: 15.472962,
    },
    {
      name: 'Denmark',
      alpha2code: 'DK',
      alpha3code: 'DNK',
      latitude: 56.26392,
      longitude: 9.501785,
    },
    {
      name: 'Diamond Princess',
      alpha2code: null,
      alpha3code: null,
      latitude: null,
      longitude: null,
    },
    {
      name: 'Djibouti',
      alpha2code: 'DJ',
      alpha3code: 'DJI',
      latitude: 11.825138,
      longitude: 42.590275,
    },
    {
      name: 'Dominica',
      alpha2code: 'DM',
      alpha3code: 'DMA',
      latitude: 15.414999,
      longitude: -61.370976,
    },
    {
      name: 'Dominican Republic',
      alpha2code: 'DO',
      alpha3code: 'DOM',
      latitude: 18.735693,
      longitude: -70.162651,
    },
    {
      name: 'DRC',
      alpha2code: 'CD',
      alpha3code: 'COD',
      latitude: -4.038333,
      longitude: 21.758664,
    },
    {
      name: 'Ecuador',
      alpha2code: 'EC',
      alpha3code: 'ECU',
      latitude: -1.831239,
      longitude: -78.183406,
    },
    {
      name: 'Egypt',
      alpha2code: 'EG',
      alpha3code: 'EGY',
      latitude: 26.820553,
      longitude: 30.802498,
    },
    {
      name: 'El Salvador',
      alpha2code: 'SV',
      alpha3code: 'SLV',
      latitude: 13.794185,
      longitude: -88.89653,
    },
    {
      name: 'Equatorial Guinea',
      alpha2code: 'GQ',
      alpha3code: 'GNQ',
      latitude: 1.650801,
      longitude: 10.267895,
    },
    {
      name: 'Eritrea',
      alpha2code: 'ER',
      alpha3code: 'ERI',
      latitude: 15.179384,
      longitude: 39.782334,
    },
    {
      name: 'Estonia',
      alpha2code: 'EE',
      alpha3code: 'EST',
      latitude: 58.595272,
      longitude: 25.013607,
    },
    {
      name: 'Eswatini',
      alpha2code: 'SZ',
      alpha3code: 'SWZ',
      latitude: -26.522503,
      longitude: 31.465866,
    },
    {
      name: 'Ethiopia',
      alpha2code: 'ET',
      alpha3code: 'ETH',
      latitude: 9.145,
      longitude: 40.489673,
    },
    {
      name: 'Faeroe Islands',
      alpha2code: 'FO',
      alpha3code: 'FRO',
      latitude: 61.892635,
      longitude: -6.911806,
    },
    {
      name: 'Falkland Islands',
      alpha2code: 'FK',
      alpha3code: 'FLK',
      latitude: -51.796253,
      longitude: -59.523613,
    },
    {
      name: 'Fiji',
      alpha2code: 'FJ',
      alpha3code: 'FJI',
      latitude: -16.578193,
      longitude: 179.414413,
    },
    {
      name: 'Finland',
      alpha2code: 'FI',
      alpha3code: 'FIN',
      latitude: 61.92411,
      longitude: 25.748151,
    },
    {
      name: 'France',
      alpha2code: 'FR',
      alpha3code: 'FRA',
      latitude: 46.227638,
      longitude: 2.213749,
    },
    {
      name: 'French Guiana',
      alpha2code: 'GF',
      alpha3code: 'GUF',
      latitude: 3.933889,
      longitude: -53.125782,
    },
    {
      name: 'French Polynesia',
      alpha2code: 'PF',
      alpha3code: 'PYF',
      latitude: -17.679742,
      longitude: -149.406843,
    },
    {
      name: 'French Southern Territories',
      alpha2code: 'TF',
      alpha3code: 'ATF',
      latitude: -49.280366,
      longitude: 69.348557,
    },
    {
      name: 'Gabon',
      alpha2code: 'GA',
      alpha3code: 'GAB',
      latitude: -0.803689,
      longitude: 11.609444,
    },
    {
      name: 'Gambia',
      alpha2code: 'GM',
      alpha3code: 'GMB',
      latitude: 13.443182,
      longitude: -15.310139,
    },
    {
      name: 'Georgia',
      alpha2code: 'GE',
      alpha3code: 'GEO',
      latitude: 42.315407,
      longitude: 43.356892,
    },
    {
      name: 'Germany',
      alpha2code: 'DE',
      alpha3code: 'DEU',
      latitude: 51.165691,
      longitude: 10.451526,
    },
    {
      name: 'Ghana',
      alpha2code: 'GH',
      alpha3code: 'GHA',
      latitude: 7.946527,
      longitude: -1.023194,
    },
    {
      name: 'Gibraltar',
      alpha2code: 'GI',
      alpha3code: 'GIB',
      latitude: 36.137741,
      longitude: -5.345374,
    },
    {
      name: 'Greece',
      alpha2code: 'GR',
      alpha3code: 'GRC',
      latitude: 39.074208,
      longitude: 21.824312,
    },
    {
      name: 'Greenland',
      alpha2code: 'GL',
      alpha3code: 'GRL',
      latitude: 71.706936,
      longitude: -42.604303,
    },
    {
      name: 'Grenada',
      alpha2code: 'GD',
      alpha3code: 'GRD',
      latitude: 12.262776,
      longitude: -61.604171,
    },
    {
      name: 'Guadeloupe',
      alpha2code: 'GP',
      alpha3code: 'GLP',
      latitude: 16.995971,
      longitude: -62.067641,
    },
    {
      name: 'Guam',
      alpha2code: 'GU',
      alpha3code: 'GUM',
      latitude: 13.444304,
      longitude: 144.793731,
    },
    {
      name: 'Guatemala',
      alpha2code: 'GT',
      alpha3code: 'GTM',
      latitude: 15.783471,
      longitude: -90.230759,
    },
    {
      name: 'Guernsey',
      alpha2code: 'GG',
      alpha3code: 'GGY',
      latitude: 49.465691,
      longitude: -2.585278,
    },
    {
      name: 'Guinea',
      alpha2code: 'GN',
      alpha3code: 'GIN',
      latitude: 9.945587,
      longitude: -9.696645,
    },
    {
      name: 'Guinea-Bissau',
      alpha2code: 'GW',
      alpha3code: 'GNB',
      latitude: 11.803749,
      longitude: -15.180413,
    },
    {
      name: 'Guyana',
      alpha2code: 'GY',
      alpha3code: 'GUY',
      latitude: 4.860416,
      longitude: -58.93018,
    },
    {
      name: 'Haiti',
      alpha2code: 'HT',
      alpha3code: 'HTI',
      latitude: 18.971187,
      longitude: -72.285215,
    },
    {
      name: 'Heard Island and Mcdonald Islands',
      alpha2code: 'HM',
      alpha3code: 'HMD',
      latitude: -53.08181,
      longitude: 73.504158,
    },
    {
      name: 'Honduras',
      alpha2code: 'HN',
      alpha3code: 'HND',
      latitude: 15.199999,
      longitude: -86.241905,
    },
    {
      name: 'Hong Kong',
      alpha2code: 'HK',
      alpha3code: 'HKG',
      latitude: 22.396428,
      longitude: 114.109497,
    },
    {
      name: 'Hungary',
      alpha2code: 'HU',
      alpha3code: 'HUN',
      latitude: 47.162494,
      longitude: 19.503304,
    },
    {
      name: 'Iceland',
      alpha2code: 'IS',
      alpha3code: 'ISL',
      latitude: 64.963051,
      longitude: -19.020835,
    },
    {
      name: 'India',
      alpha2code: 'IN',
      alpha3code: 'IND',
      latitude: 20.593684,
      longitude: 78.96288,
    },
    {
      name: 'Indonesia',
      alpha2code: 'ID',
      alpha3code: 'IDN',
      latitude: -0.789275,
      longitude: 113.921327,
    },
    {
      name: 'Iran',
      alpha2code: 'IR',
      alpha3code: 'IRN',
      latitude: 32.427908,
      longitude: 53.688046,
    },
    {
      name: 'Iraq',
      alpha2code: 'IQ',
      alpha3code: 'IRQ',
      latitude: 33.223191,
      longitude: 43.679291,
    },
    {
      name: 'Ireland',
      alpha2code: 'IE',
      alpha3code: 'IRL',
      latitude: 53.41291,
      longitude: -8.24389,
    },
    {
      name: 'Isle of Man',
      alpha2code: 'IM',
      alpha3code: 'IMN',
      latitude: 54.236107,
      longitude: -4.548056,
    },
    {
      name: 'Israel',
      alpha2code: 'IL',
      alpha3code: 'ISR',
      latitude: 31.046051,
      longitude: 34.851612,
    },
    {
      name: 'Italy',
      alpha2code: 'IT',
      alpha3code: 'ITA',
      latitude: 41.87194,
      longitude: 12.56738,
    },
    {
      name: 'Ivory Coast',
      alpha2code: 'CI',
      alpha3code: 'CIV',
      latitude: 7.539989,
      longitude: -5.54708,
    },
    {
      name: 'Jamaica',
      alpha2code: 'JM',
      alpha3code: 'JAM',
      latitude: 18.109581,
      longitude: -77.297508,
    },
    {
      name: 'Japan',
      alpha2code: 'JP',
      alpha3code: 'JPN',
      latitude: 36.204824,
      longitude: 138.252924,
    },
    {
      name: 'Jersey',
      alpha2code: 'JE',
      alpha3code: 'JEY',
      latitude: 49.214439,
      longitude: -2.13125,
    },
    {
      name: 'Jordan',
      alpha2code: 'JO',
      alpha3code: 'JOR',
      latitude: 30.585164,
      longitude: 36.238414,
    },
    {
      name: 'Kazakhstan',
      alpha2code: 'KZ',
      alpha3code: 'KAZ',
      latitude: 48.019573,
      longitude: 66.923684,
    },
    {
      name: 'Kenya',
      alpha2code: 'KE',
      alpha3code: 'KEN',
      latitude: -0.023559,
      longitude: 37.906193,
    },
    {
      name: 'Kiribati',
      alpha2code: 'KI',
      alpha3code: 'KIR',
      latitude: -3.370417,
      longitude: -168.734039,
    },
    {
      name: 'Kosovo* (disputed teritory)',
      alpha2code: null,
      alpha3code: 'XK',
      latitude: 42.66394,
      longitude: 21.09611,
    },
    {
      name: 'Kuwait',
      alpha2code: 'KW',
      alpha3code: 'KWT',
      latitude: 29.31166,
      longitude: 47.481766,
    },
    {
      name: 'Kyrgyzstan',
      alpha2code: 'KG',
      alpha3code: 'KGZ',
      latitude: 41.20438,
      longitude: 74.766098,
    },
    {
      name: 'Laos',
      alpha2code: 'LA',
      alpha3code: 'LAO',
      latitude: 19.85627,
      longitude: 102.495496,
    },
    {
      name: 'Latvia',
      alpha2code: 'LV',
      alpha3code: 'LVA',
      latitude: 56.879635,
      longitude: 24.603189,
    },
    {
      name: 'Lebanon',
      alpha2code: 'LB',
      alpha3code: 'LBN',
      latitude: 33.854721,
      longitude: 35.862285,
    },
    {
      name: 'Lesotho',
      alpha2code: 'LS',
      alpha3code: 'LSO',
      latitude: -29.609988,
      longitude: 28.233608,
    },
    {
      name: 'Liberia',
      alpha2code: 'LR',
      alpha3code: 'LBR',
      latitude: 6.428055,
      longitude: -9.429499,
    },
    {
      name: 'Libya',
      alpha2code: 'LY',
      alpha3code: 'LBY',
      latitude: 26.3351,
      longitude: 17.228331,
    },
    {
      name: 'Liechtenstein',
      alpha2code: 'LI',
      alpha3code: 'LIE',
      latitude: 47.166,
      longitude: 9.555373,
    },
    {
      name: 'Lithuania',
      alpha2code: 'LT',
      alpha3code: 'LTU',
      latitude: 55.169438,
      longitude: 23.881275,
    },
    {
      name: 'Luxembourg',
      alpha2code: 'LU',
      alpha3code: 'LUX',
      latitude: 49.815273,
      longitude: 6.129583,
    },
    {
      name: 'Macao',
      alpha2code: 'MO',
      alpha3code: 'MAC',
      latitude: 22.198745,
      longitude: 113.543873,
    },
    {
      name: 'Madagascar',
      alpha2code: 'MG',
      alpha3code: 'MDG',
      latitude: -18.766947,
      longitude: 46.869107,
    },
    {
      name: 'Malawi',
      alpha2code: 'MW',
      alpha3code: 'MWI',
      latitude: -13.254308,
      longitude: 34.301525,
    },
    {
      name: 'Malaysia',
      alpha2code: 'MY',
      alpha3code: 'MYS',
      latitude: 4.210484,
      longitude: 101.975766,
    },
    {
      name: 'Maldives',
      alpha2code: 'MV',
      alpha3code: 'MDV',
      latitude: 3.202778,
      longitude: 73.22068,
    },
    {
      name: 'Mali',
      alpha2code: 'ML',
      alpha3code: 'MLI',
      latitude: 17.570692,
      longitude: -3.996166,
    },
    {
      name: 'Malta',
      alpha2code: 'MT',
      alpha3code: 'MLT',
      latitude: 35.937496,
      longitude: 14.375416,
    },
    {
      name: 'Marshall Islands',
      alpha2code: 'MH',
      alpha3code: 'MHL',
      latitude: 7.131474,
      longitude: 171.184478,
    },
    {
      name: 'Martinique',
      alpha2code: 'MQ',
      alpha3code: 'MTQ',
      latitude: 14.641528,
      longitude: -61.024174,
    },
    {
      name: 'Mauritania',
      alpha2code: 'MR',
      alpha3code: 'MRT',
      latitude: 21.00789,
      longitude: -10.940835,
    },
    {
      name: 'Mauritius',
      alpha2code: 'MU',
      alpha3code: 'MUS',
      latitude: -20.348404,
      longitude: 57.552152,
    },
    {
      name: 'Mayotte',
      alpha2code: 'YT',
      alpha3code: 'MYT',
      latitude: -12.8275,
      longitude: 45.166244,
    },
    {
      name: 'Mexico',
      alpha2code: 'MX',
      alpha3code: 'MEX',
      latitude: 23.634501,
      longitude: -102.552784,
    },
    {
      name: 'Micronesia',
      alpha2code: 'FM',
      alpha3code: 'FSM',
      latitude: 7.425554,
      longitude: 150.550812,
    },
    {
      name: 'Moldova',
      alpha2code: 'MD',
      alpha3code: 'MDA',
      latitude: 47.411631,
      longitude: 28.369885,
    },
    {
      name: 'Monaco',
      alpha2code: 'MC',
      alpha3code: 'MCO',
      latitude: 43.750298,
      longitude: 7.412841,
    },
    {
      name: 'Mongolia',
      alpha2code: 'MN',
      alpha3code: 'MNG',
      latitude: 46.862496,
      longitude: 103.846656,
    },
    {
      name: 'Montenegro',
      alpha2code: 'ME',
      alpha3code: 'MNE',
      latitude: 42.708678,
      longitude: 19.37439,
    },
    {
      name: 'Montserrat',
      alpha2code: 'MS',
      alpha3code: 'MSR',
      latitude: 16.742498,
      longitude: -62.187366,
    },
    {
      name: 'Morocco',
      alpha2code: 'MA',
      alpha3code: 'MAR',
      latitude: 31.791702,
      longitude: -7.09262,
    },
    {
      name: 'Mozambique',
      alpha2code: 'MZ',
      alpha3code: 'MOZ',
      latitude: -18.665695,
      longitude: 35.529562,
    },
    {
      name: 'MS Zaandam',
      alpha2code: null,
      alpha3code: null,
      latitude: null,
      longitude: null,
    },
    {
      name: 'Myanmar',
      alpha2code: 'MM',
      alpha3code: 'MMR',
      latitude: 21.913965,
      longitude: 95.956223,
    },
    {
      name: 'Namibia',
      alpha2code: 'NA',
      alpha3code: 'NAM',
      latitude: -22.95764,
      longitude: 18.49041,
    },
    {
      name: 'Nauru',
      alpha2code: 'NR',
      alpha3code: 'NRU',
      latitude: -0.522778,
      longitude: 166.931503,
    },
    {
      name: 'Nepal',
      alpha2code: 'NP',
      alpha3code: 'NPL',
      latitude: 28.394857,
      longitude: 84.124008,
    },
    {
      name: 'Netherlands',
      alpha2code: 'NL',
      alpha3code: 'NLD',
      latitude: 52.132633,
      longitude: 5.291266,
    },
    {
      name: 'New Caledonia',
      alpha2code: 'NC',
      alpha3code: 'NCL',
      latitude: -20.904305,
      longitude: 165.618042,
    },
    {
      name: 'New Zealand',
      alpha2code: 'NZ',
      alpha3code: 'NZL',
      latitude: -40.900557,
      longitude: 174.885971,
    },
    {
      name: 'Nicaragua',
      alpha2code: 'NI',
      alpha3code: 'NIC',
      latitude: 12.865416,
      longitude: -85.207229,
    },
    {
      name: 'Niger',
      alpha2code: 'NE',
      alpha3code: 'NER',
      latitude: 17.607789,
      longitude: 8.081666,
    },
    {
      name: 'Nigeria',
      alpha2code: 'NG',
      alpha3code: 'NGA',
      latitude: 9.081999,
      longitude: 8.675277,
    },
    {
      name: 'Niue',
      alpha2code: 'NU',
      alpha3code: 'NIU',
      latitude: -19.054445,
      longitude: -169.867233,
    },
    {
      name: 'Norfolk Island',
      alpha2code: 'NF',
      alpha3code: 'NFK',
      latitude: -29.040835,
      longitude: 167.954712,
    },
    {
      name: 'North Korea',
      alpha2code: 'KP',
      alpha3code: 'PRK',
      latitude: 40.339852,
      longitude: 127.510093,
    },
    {
      name: 'North Macedonia',
      alpha2code: 'MK',
      alpha3code: 'MKD',
      latitude: 41.608635,
      longitude: 21.745275,
    },
    {
      name: 'Northern Mariana Islands',
      alpha2code: 'MP',
      alpha3code: 'MNP',
      latitude: 17.33083,
      longitude: 145.38469,
    },
    {
      name: 'Norway',
      alpha2code: 'NO',
      alpha3code: 'NOR',
      latitude: 60.472024,
      longitude: 8.468946,
    },
    {
      name: 'Oman',
      alpha2code: 'OM',
      alpha3code: 'OMN',
      latitude: 21.512583,
      longitude: 55.923255,
    },
    {
      name: 'Pakistan',
      alpha2code: 'PK',
      alpha3code: 'PAK',
      latitude: 30.375321,
      longitude: 69.345116,
    },
    {
      name: 'Palau',
      alpha2code: 'PW',
      alpha3code: 'PLW',
      latitude: 7.51498,
      longitude: 134.58252,
    },
    {
      name: 'Palestine',
      alpha2code: 'PS',
      alpha3code: 'PSE',
      latitude: 31.952162,
      longitude: 35.233154,
    },
    {
      name: 'Panama',
      alpha2code: 'PA',
      alpha3code: 'PAN',
      latitude: 8.537981,
      longitude: -80.782127,
    },
    {
      name: 'Papua New Guinea',
      alpha2code: 'PG',
      alpha3code: 'PNG',
      latitude: -6.314993,
      longitude: 143.95555,
    },
    {
      name: 'Paraguay',
      alpha2code: 'PY',
      alpha3code: 'PRY',
      latitude: -23.442503,
      longitude: -58.443832,
    },
    {
      name: 'Peru',
      alpha2code: 'PE',
      alpha3code: 'PER',
      latitude: -9.189967,
      longitude: -75.015152,
    },
    {
      name: 'Philippines',
      alpha2code: 'PH',
      alpha3code: 'PHL',
      latitude: 12.879721,
      longitude: 121.774017,
    },
    {
      name: 'Pitcairn',
      alpha2code: 'PN',
      alpha3code: 'PCN',
      latitude: -24.703615,
      longitude: -127.439308,
    },
    {
      name: 'Poland',
      alpha2code: 'PL',
      alpha3code: 'POL',
      latitude: 51.919438,
      longitude: 19.145136,
    },
    {
      name: 'Portugal',
      alpha2code: 'PT',
      alpha3code: 'PRT',
      latitude: 39.399872,
      longitude: -8.224454,
    },
    {
      name: 'Puerto Rico',
      alpha2code: 'PR',
      alpha3code: 'PRI',
      latitude: 18.220833,
      longitude: -66.590149,
    },
    {
      name: 'Qatar',
      alpha2code: 'QA',
      alpha3code: 'QAT',
      latitude: 25.354826,
      longitude: 51.183884,
    },
    {
      name: 'Reunion',
      alpha2code: 'RE',
      alpha3code: 'REU',
      latitude: -21.115141,
      longitude: 55.536384,
    },
    {
      name: 'Romania',
      alpha2code: 'RO',
      alpha3code: 'ROU',
      latitude: 45.943161,
      longitude: 24.96676,
    },
    {
      name: 'Russia',
      alpha2code: 'RU',
      alpha3code: 'RUS',
      latitude: 61.52401,
      longitude: 105.318756,
    },
    {
      name: 'Rwanda',
      alpha2code: 'RW',
      alpha3code: 'RWA',
      latitude: -1.940278,
      longitude: 29.873888,
    },
    {
      name: 'Saint Barthélemy',
      alpha2code: 'BL',
      alpha3code: 'BLM',
      latitude: 17.9139222,
      longitude: -62.8338521,
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      alpha2code: 'SH',
      alpha3code: 'SHN',
      latitude: -24.143474,
      longitude: -10.030696,
    },
    {
      name: 'Saint Kitts and Nevis',
      alpha2code: 'KN',
      alpha3code: 'KNA',
      latitude: 17.357822,
      longitude: -62.782998,
    },
    {
      name: 'Saint Lucia',
      alpha2code: 'LC',
      alpha3code: 'LCA',
      latitude: 13.909444,
      longitude: -60.978893,
    },
    {
      name: 'Saint Martin',
      alpha2code: 'MF',
      alpha3code: 'MAF',
      latitude: 18.075277,
      longitude: -63.060001,
    },
    {
      name: 'Saint Pierre and Miquelon',
      alpha2code: 'PM',
      alpha3code: 'SPM',
      latitude: 46.941936,
      longitude: -56.27111,
    },
    {
      name: 'Samoa',
      alpha2code: 'WS',
      alpha3code: 'WSM',
      latitude: -13.759029,
      longitude: -172.104629,
    },
    {
      name: 'San Marino',
      alpha2code: 'SM',
      alpha3code: 'SMR',
      latitude: 43.94236,
      longitude: 12.457777,
    },
    {
      name: 'Sao Tome and Principe',
      alpha2code: 'ST',
      alpha3code: 'STP',
      latitude: 0.18636,
      longitude: 6.613081,
    },
    {
      name: 'Saudi Arabia',
      alpha2code: 'SA',
      alpha3code: 'SAU',
      latitude: 23.885942,
      longitude: 45.079162,
    },
    {
      name: 'Senegal',
      alpha2code: 'SN',
      alpha3code: 'SEN',
      latitude: 14.497401,
      longitude: -14.452362,
    },
    {
      name: 'Serbia',
      alpha2code: 'RS',
      alpha3code: 'SRB',
      latitude: 44.016521,
      longitude: 21.005859,
    },
    {
      name: 'Seychelles',
      alpha2code: 'SC',
      alpha3code: 'SYC',
      latitude: -4.679574,
      longitude: 55.491977,
    },
    {
      name: 'Sierra Leone',
      alpha2code: 'SL',
      alpha3code: 'SLE',
      latitude: 8.460555,
      longitude: -11.779889,
    },
    {
      name: 'Singapore',
      alpha2code: 'SG',
      alpha3code: 'SGP',
      latitude: 1.352083,
      longitude: 103.819836,
    },
    {
      name: 'Sint Maarten',
      alpha2code: 'SX',
      alpha3code: 'SXM',
      latitude: 18.0347188,
      longitude: -63.0681114,
    },
    {
      name: 'Slovakia',
      alpha2code: 'SK',
      alpha3code: 'SVK',
      latitude: 48.669026,
      longitude: 19.699024,
    },
    {
      name: 'Slovenia',
      alpha2code: 'SI',
      alpha3code: 'SVN',
      latitude: 46.151241,
      longitude: 14.995463,
    },
    {
      name: 'Solomon Islands',
      alpha2code: 'SB',
      alpha3code: 'SLB',
      latitude: -9.64571,
      longitude: 160.156194,
    },
    {
      name: 'Somalia',
      alpha2code: 'SO',
      alpha3code: 'SOM',
      latitude: 5.152149,
      longitude: 46.199616,
    },
    {
      name: 'South Africa',
      alpha2code: 'ZA',
      alpha3code: 'ZAF',
      latitude: -30.559482,
      longitude: 22.937506,
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      alpha2code: 'GS',
      alpha3code: 'SGS',
      latitude: -54.429579,
      longitude: -36.587909,
    },
    {
      name: 'South Korea',
      alpha2code: 'KR',
      alpha3code: 'KOR',
      latitude: 35.907757,
      longitude: 127.766922,
    },
    {
      name: 'South Sudan',
      alpha2code: 'SS',
      alpha3code: 'SSD',
      latitude: 7.8626845,
      longitude: 29.6949232,
    },
    {
      name: 'Spain',
      alpha2code: 'ES',
      alpha3code: 'ESP',
      latitude: 40.463667,
      longitude: -3.74922,
    },
    {
      name: 'Sri Lanka',
      alpha2code: 'LK',
      alpha3code: 'LKA',
      latitude: 7.873054,
      longitude: 80.771797,
    },
    {
      name: 'St. Vincent Grenadines',
      alpha2code: 'VC',
      alpha3code: 'VCT',
      latitude: 12.984305,
      longitude: -61.287228,
    },
    {
      name: 'Sudan',
      alpha2code: 'SD',
      alpha3code: 'SDN',
      latitude: 12.862807,
      longitude: 30.217636,
    },
    {
      name: 'Suriname',
      alpha2code: 'SR',
      alpha3code: 'SUR',
      latitude: 3.919305,
      longitude: -56.027783,
    },
    {
      name: 'Svalbard and Jan Mayen',
      alpha2code: 'SJ',
      alpha3code: 'SJM',
      latitude: 77.553604,
      longitude: 23.670272,
    },
    {
      name: 'Sweden',
      alpha2code: 'SE',
      alpha3code: 'SWE',
      latitude: 60.128161,
      longitude: 18.643501,
    },
    {
      name: 'Switzerland',
      alpha2code: 'CH',
      alpha3code: 'CHE',
      latitude: 46.818188,
      longitude: 8.227512,
    },
    {
      name: 'Syria',
      alpha2code: 'SY',
      alpha3code: 'SYR',
      latitude: 34.802075,
      longitude: 38.996815,
    },
    {
      name: 'Taiwan',
      alpha2code: 'TW',
      alpha3code: 'TWN',
      latitude: 23.69781,
      longitude: 120.960515,
    },
    {
      name: 'Tajikistan',
      alpha2code: 'TJ',
      alpha3code: 'TJK',
      latitude: 38.861034,
      longitude: 71.276093,
    },
    {
      name: 'Tanzania',
      alpha2code: 'TZ',
      alpha3code: 'TZA',
      latitude: -6.369028,
      longitude: 34.888822,
    },
    {
      name: 'Thailand',
      alpha2code: 'TH',
      alpha3code: 'THA',
      latitude: 15.870032,
      longitude: 100.992541,
    },
    {
      name: 'Timor-Leste',
      alpha2code: 'TL',
      alpha3code: 'TLS',
      latitude: -8.874217,
      longitude: 125.727539,
    },
    {
      name: 'Togo',
      alpha2code: 'TG',
      alpha3code: 'TGO',
      latitude: 8.619543,
      longitude: 0.824782,
    },
    {
      name: 'Tokelau',
      alpha2code: 'TK',
      alpha3code: 'TKL',
      latitude: -8.967363,
      longitude: -171.855881,
    },
    {
      name: 'Tonga',
      alpha2code: 'TO',
      alpha3code: 'TON',
      latitude: -21.178986,
      longitude: -175.198242,
    },
    {
      name: 'Trinidad and Tobago',
      alpha2code: 'TT',
      alpha3code: 'TTO',
      latitude: 10.691803,
      longitude: -61.222503,
    },
    {
      name: 'Tunisia',
      alpha2code: 'TN',
      alpha3code: 'TUN',
      latitude: 33.886917,
      longitude: 9.537499,
    },
    {
      name: 'Turkey',
      alpha2code: 'TR',
      alpha3code: 'TUR',
      latitude: 38.963745,
      longitude: 35.243322,
    },
    {
      name: 'Turkmenistan',
      alpha2code: 'TM',
      alpha3code: 'TKM',
      latitude: 38.969719,
      longitude: 59.556278,
    },
    {
      name: 'Turks and Caicos',
      alpha2code: 'TC',
      alpha3code: 'TCA',
      latitude: 21.694025,
      longitude: -71.797928,
    },
    {
      name: 'Tuvalu',
      alpha2code: 'TV',
      alpha3code: 'TUV',
      latitude: -7.109535,
      longitude: 177.64933,
    },
    {
      name: 'U.S. Virgin Islands',
      alpha2code: 'VI',
      alpha3code: 'VIR',
      latitude: 18.335765,
      longitude: -64.896335,
    },
    {
      name: 'UAE',
      alpha2code: 'AE',
      alpha3code: 'ARE',
      latitude: 23.424076,
      longitude: 53.847818,
    },
    {
      name: 'Uganda',
      alpha2code: 'UG',
      alpha3code: 'UGA',
      latitude: 1.373333,
      longitude: 32.290275,
    },
    {
      name: 'UK',
      alpha2code: 'GB',
      alpha3code: 'GBR',
      latitude: 55.378051,
      longitude: -3.435973,
    },
    {
      name: 'Ukraine',
      alpha2code: 'UA',
      alpha3code: 'UKR',
      latitude: 48.379433,
      longitude: 31.16558,
    },
    {
      name: 'United States Minor Outlying Islands',
      alpha2code: 'UM',
      alpha3code: 'UMI',
      latitude: 19.295374,
      longitude: 166.6280441,
    },
    {
      name: 'Uruguay',
      alpha2code: 'UY',
      alpha3code: 'URY',
      latitude: -32.522779,
      longitude: -55.765835,
    },
    {
      name: 'USA',
      alpha2code: 'US',
      alpha3code: 'USA',
      latitude: 37.09024,
      longitude: -95.712891,
    },
    {
      name: 'Uzbekistan',
      alpha2code: 'UZ',
      alpha3code: 'UZB',
      latitude: 41.377491,
      longitude: 64.585262,
    },
    {
      name: 'Vanuatu',
      alpha2code: 'VU',
      alpha3code: 'VUT',
      latitude: -15.376706,
      longitude: 166.959158,
    },
    {
      name: 'Vatican City',
      alpha2code: 'VA',
      alpha3code: 'VAT',
      latitude: 41.902916,
      longitude: 12.453389,
    },
    {
      name: 'Venezuela',
      alpha2code: 'VE',
      alpha3code: 'VEN',
      latitude: 6.42375,
      longitude: -66.58973,
    },
    {
      name: 'Vietnam',
      alpha2code: 'VN',
      alpha3code: 'VNM',
      latitude: 14.058324,
      longitude: 108.277199,
    },
    {
      name: 'Wallis and Futuna',
      alpha2code: 'WF',
      alpha3code: 'WLF',
      latitude: -13.768752,
      longitude: -177.156097,
    },
    {
      name: 'Western Sahara',
      alpha2code: 'EH',
      alpha3code: 'ESH',
      latitude: 24.215527,
      longitude: -12.885834,
    },
    {
      name: 'Yemen',
      alpha2code: 'YE',
      alpha3code: 'YEM',
      latitude: 15.552727,
      longitude: 48.516388,
    },
    {
      name: 'Zambia',
      alpha2code: 'ZM',
      alpha3code: 'ZMB',
      latitude: -13.133897,
      longitude: 27.849332,
    },
    {
      name: 'Zimbabwe',
      alpha2code: 'ZW',
      alpha3code: 'ZWE',
      latitude: -19.015438,
      longitude: 29.154857,
    },
  ],
  history: {
    cases_new: [
      {
        USA: '+4835',
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
      {
        USA: '+8373',
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: '+9883',
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: '+9624',
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: '+10688',
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: '+15460',
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: '+16903',
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: '+19185',
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: '+18154',
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: '+19692',
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: '+23533',
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: '+25952',
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: '+29227',
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: '+31441',
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: '+32972',
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: '+24774',
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: '+28043',
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: '+27632',
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: '+26744',
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: '+31621',
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: '+33212',
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: '+28381',
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: '+27176',
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: '+25985',
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: '+24804',
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: '+29625',
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: '+28336',
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: '+31623',
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: '+27500',
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: '+24802',
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: '+27440',
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: '+24428',
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: '+27948',
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: '+30257',
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: '+37101',
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: '+32273',
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: '+26167',
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: '+20789',
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: '+24884',
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: '+24227',
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: '+30536',
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: '+34859',
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: '+28374',
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: '+26459',
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: '+23717',
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: '+24152',
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: '+20418',
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: '+29022',
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: '+28060',
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: '+25218',
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: '+19451',
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: '+17624',
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: '+22239',
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: '+21073',
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: '+25626',
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: '+25368',
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: '+21942',
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: '+19408',
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: '+21693',
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: '+19999',
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: '+20669',
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: '+27213',
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: '+23742',
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: '+21757',
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: '+18490',
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: '+19718',
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: '+19039',
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: '+20390',
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: '+22297',
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: '+9396',
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: '+20705',
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: '+21645',
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: '+20974',
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: '+20288',
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: '+21854',
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: '+29152',
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: '+21975',
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: '+18676',
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: '+18469',
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: '+18924',
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: '+19944',
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: '+22630',
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: '+26510',
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: '+24858',
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: '+19223',
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: '+20489',
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: '+24846',
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: '+24923',
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: '+26345',
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: '+31838',
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: '+32627',
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: '+25219',
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: '+30579',
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: '+35381',
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: '+38193',
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: '+36573',
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: '+45252',
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: '+42177',
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: '+39007',
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: '+43558',
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: '+44670',
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: '+49246',
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: '+52944',
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: '+54644',
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: '+44498',
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: '+42431',
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: '+48864',
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: '+53732',
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: '+59836',
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: '+59906',
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: '+67901',
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: '+60777',
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: '+56856',
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: '+63689',
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: '+64283',
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: '+69468',
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: '+66482',
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: '+69189',
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: '+61655',
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: '+60678',
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: '+61854',
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: '+63927',
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: '+69898',
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: '+67129',
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: '+76365',
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: '+63959',
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: '+50595',
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: '+58638',
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: '+61332',
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: '+59113',
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: '+61221',
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: '+69779',
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: '+57065',
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: '+48447',
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: '+48053',
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: '+53579',
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: '+53464',
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: '+54459',
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: '+61555',
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: '+52848',
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: '+46612',
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: '+45363',
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: '+52216',
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: '+51706',
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: '+53374',
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: '+59893',
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: '+50461',
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: '+35327',
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: '+38930',
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: '+41112',
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: '+40844',
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: '+43623',
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: '+48530',
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: '+41970',
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: '+31739',
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: '+39912',
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: '+38353',
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: '+42199',
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: '+44094',
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: '+47117',
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: '+40550',
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: '+33261',
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: '+37120',
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: '+40836',
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: '+40053',
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: '+41140',
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: '+51200',
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: '+39936',
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: '+29847',
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: '+25149',
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: '+25925',
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: '+33977',
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: '+35936',
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: '+44584',
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: '+36901',
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: '+30427',
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: '+37036',
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: '+35453',
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: '+37617',
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: '+42008',
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: '+46442',
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: '+41896',
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: '+32233',
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: '+32956',
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: '+34193',
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: '+38621',
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: '+42259',
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: '+48314',
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: '+41176',
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: '+32695',
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: '+34427',
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: '+38953',
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: '+38451',
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: '+45970',
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: '+47856',
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: '+48254',
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: '+31645',
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: '+38566',
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: '+37936',
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: '+45002',
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: '+52819',
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: '+58953',
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: '+48895',
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: '+41737',
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: '+44896',
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: '+48962',
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: '+57056',
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: '+64312',
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: '+69451',
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: '+53155',
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: '+44188',
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: '+49141',
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: '+59753',
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: '+58424',
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: '+68882',
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: '+77590',
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: '+76616',
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: '+59766',
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: '+66104',
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: '+71141',
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: '+78242',
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: '+83688',
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: '+98472',
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: '+84390',
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: '+66175',
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: '+75755',
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: '+89482',
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: '+101390',
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: '+108855',
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: '+123692',
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: '+114405',
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: '+96873',
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: '+114836',
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: '+130032',
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: '+129704',
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: '+150325',
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: '+176959',
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: '+153294',
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: '+127776',
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: '+148332',
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: '+141652',
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: '+156881',
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: '+172965',
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: '+188207',
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: '+158489',
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: '+128683',
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: '+149711',
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: '+164482',
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: '+161583',
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: '+104653',
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: '+152466',
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: '+138064',
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: '+131273',
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: '+147046',
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: '+159424',
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: '+185580',
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: '+197348',
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: '+213597',
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: '+200132',
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: '+165350',
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: '+175315',
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: '+181533',
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: '+199110',
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: '+195117',
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: '+199705',
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: '+199481',
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: '+164366',
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: '+165120',
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: '+172756',
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: '+208108',
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: '+191047',
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: '+208870',
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: '+170296',
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: '+174216',
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: '+171912',
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: '+153373',
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: '+203296',
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: '+171202',
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: '+97800',
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: '+125228',
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: '+121033',
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: '+147656',
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: '+167712',
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: '+193118',
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: '+199499',
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: '+156497',
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: '+195680',
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: '+169496',
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: '+153974',
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: '+200932',
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: '+225558',
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
    ],
    cases_1M_pop: [
      {
        USA: '64995',
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: '64922',
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: '64202',
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: '63523',
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: '62858',
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: '62076',
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: '61502',
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: '60776',
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: '60096',
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: '59475',
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: '58934',
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: '58391',
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: '57869',
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: '57510',
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: '56904',
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: '56154',
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: '55573',
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: '55010',
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: '54409',
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: '53758',
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: '52992',
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: '52287',
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: '51574',
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: '50950',
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: '50361',
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: '49804',
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: '48962',
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: '48264',
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: '47588',
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: '46904',
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: '46243',
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: '45658',
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: '45127',
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: '44453',
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: '43740',
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: '43084',
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: '42451',
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: '41908',
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: '41421',
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: '41004',
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: '40503',
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: '39921',
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: '39540',
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: '39016',
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: '38432',
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: '37921',
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: '37486',
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: '36959',
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: '36322',
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: '35741',
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: '35209',
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: '34740',
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: '34233',
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: '33830',
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: '33334',
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: '32741',
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: '32244',
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: '31817',
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: '31388',
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: '31000',
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: '30671',
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: '30300',
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: '29879',
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: '29530',
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: '29206',
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: '28808',
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: '28550',
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: '28345',
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: '28082',
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: '27756',
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: '27490',
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: '27242',
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: '27016',
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: '26802',
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: '26609',
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: '26367',
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: '26098',
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: '25874',
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: '25684',
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: '25475',
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: '25294',
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: '25158',
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: '24990',
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: '24775',
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: '24573',
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: '24393',
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: '24241',
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: '24103',
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: '23960',
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: '23807',
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: '23618',
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: '23445',
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: '23278',
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: '23156',
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: '23029',
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: '22927',
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: '22763',
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: '22605',
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: '22459',
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: '22327',
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: '22200',
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: '22084',
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: '21979',
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: '21823',
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: '21669',
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: '21532',
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: '21411',
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: '21251',
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: '21122',
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: '21021',
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: '20883',
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: '20730',
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: '20596',
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: '20480',
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: '20362',
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: '20239',
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: '20140',
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: '20020',
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: '19872',
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: '19761',
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: '19651',
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: '19572',
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: '19493',
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: '19402',
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: '19274',
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: '19110',
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: '19007',
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: '18883',
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: '18744',
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: '18630',
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: '18523',
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: '18393',
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: '18244',
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: '18104',
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: '17972',
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: '17852',
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: '17729',
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: '17625',
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: '17493',
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: '17341',
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: '17197',
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: '17066',
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: '16937',
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: '16801',
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: '16685',
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: '16531',
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: '16344',
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: '16175',
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: '16013',
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: '15842',
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: '15695',
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: '15544',
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: '15380',
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: '15182',
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: '15012',
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: '14848',
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: '14678',
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: '14533',
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: '14382',
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: '14207',
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: '13979',
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: '13764',
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: '13573',
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: '13379',
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: '13186',
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: '13023',
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: '12825',
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: '12588',
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: '12378',
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: '12157',
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: '11961',
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: '11761',
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: '11573',
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: '11369',
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: '11125',
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: '10918',
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: '10704',
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: '10505',
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: '10308',
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: '10133',
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: '9932',
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: '9723',
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: '9536',
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: '9351',
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: '9181',
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: '9005',
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: '8867',
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: '8732',
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: '8558',
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: '8390',
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: '8237',
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: '8099',
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: '7963',
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: '7841',
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: '7704',
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: '7551',
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: '7440',
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: '7323',
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: '7213',
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: '7118',
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: '7040',
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: '6936',
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: '6835',
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: '6749',
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: '6672',
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: '6596',
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: '6532',
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: '6472',
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: '6395',
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: '6313',
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: '6242',
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: '6182',
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: '6123',
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: '6066',
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: '6007',
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: '5903',
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: '5814',
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: '5747',
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: '5683',
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: '5618',
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: '5554',
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: '5374',
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: '5345',
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: '5277',
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: '5215',
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: '5158',
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: '5095',
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: '5039',
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: '4972',
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: '4897',
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: '4811',
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: '4747',
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: '4684',
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: '4617',
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: '4554',
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: '4484',
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: '4399',
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: '4319',
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: '4254',
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: '4185',
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: '4129',
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: '4069',
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: '3990',
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: '3904',
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: '3801',
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: '3737',
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: '3661',
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: '3587',
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: '3503',
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: '3414',
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: '3307',
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: '3202',
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: '3128',
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: '3045',
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: '2981',
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: '2893',
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: '2790',
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: '2655',
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: '2558',
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: '2469',
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: '2393',
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: '2307',
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: '2227',
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: '2143',
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: '2043',
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: '1944',
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: '1848',
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: '1771',
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: '1692',
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: '1605',
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: '1516',
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: '1410',
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: '1290',
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: '1192',
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: '1102',
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: '1015',
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: '937',
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: '835',
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: '738',
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: '648',
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: '566',
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: '493',
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: '428',
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: '373',
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: '309',
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: '253',
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: '198',
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: '161',
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: '131',
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: '98',
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: '73',
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    cases_active: [
      {
        USA: 8350770,
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: 8347314,
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: 8287613,
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: 8298781,
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: 8194010,
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: 8086093,
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: 7966624,
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: 7871298,
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: 7788736,
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: 7770639,
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: 7735762,
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: 7670836,
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: 7619580,
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: 7560918,
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: 7460164,
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: 7402830,
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: 7369084,
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: 7320652,
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: 7208326,
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: 7157509,
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: 7019692,
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: 6899059,
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: 6819476,
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: 6773032,
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: 6693089,
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: 6594253,
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: 6539050,
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: 6399300,
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: 6298116,
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: 6203827,
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: 6091271,
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: 6010350,
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: 5911437,
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: 5822110,
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: 5685445,
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: 5572580,
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: 5492989,
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: 5434253,
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: 5369531,
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: 5300421,
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: 5247042,
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: 5134728,
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: 5131859,
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: 5064169,
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: 4961306,
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: 4870574,
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: 4777603,
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: 4701088,
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: 4570986,
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: 4447538,
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: 4355061,
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: 4259662,
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: 4173211,
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: 4113600,
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: 4026576,
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: 3912217,
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: 3806044,
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: 3713915,
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: 3630154,
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: 3557627,
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: 3494063,
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: 3423195,
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: 3334506,
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: 3272181,
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: 3216421,
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: 3164877,
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: 3138620,
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: 3103146,
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: 3060334,
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: 2999966,
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: 2967503,
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: 2929168,
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: 2906094,
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: 2887334,
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: 2858123,
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: 2820176,
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: 2778691,
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: 2766878,
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: 2748945,
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: 2727553,
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: 2708965,
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: 2689884,
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: 2676021,
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: 2680263,
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: 2656214,
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: 2645933,
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: 2642056,
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: 2644469,
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: 2638864,
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: 2615977,
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: 2596669,
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: 2581264,
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: 2574611,
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: 2572909,
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: 2575075,
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: 2571358,
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: 2562014,
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: 2549783,
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: 2544825,
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: 2545703,
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: 2543654,
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: 2564624,
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: 2557229,
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: 2549868,
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: 2548430,
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: 2543417,
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: 2547553,
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: 2543330,
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: 2548528,
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: 2545018,
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: 2531703,
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: 2521651,
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: 2521450,
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: 2525523,
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: 2525843,
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: 2537114,
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: 2531365,
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: 2522890,
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: 2513490,
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: 2513236,
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: 2528119,
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: 2534406,
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: 2540935,
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: 2530242,
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: 2564081,
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: 2569687,
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: 2570424,
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: 2583101,
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: 2570296,
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: 2562168,
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: 2548998,
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: 2537320,
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: 2516156,
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: 2518988,
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: 2520402,
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: 2521755,
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: 2529777,
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: 2514401,
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: 2496538,
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: 2481910,
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: 2465912,
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: 2475762,
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: 2467084,
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: 2470950,
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: 2456387,
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: 2435793,
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: 2410447,
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: 2384514,
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: 2386416,
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: 2375062,
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: 2368677,
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: 2348191,
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: 2313986,
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: 2290921,
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: 2283109,
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: 2276772,
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: 2257056,
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: 2276858,
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: 2249251,
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: 2220973,
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: 2196804,
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: 2172815,
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: 2168255,
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: 2147518,
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: 2131022,
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: 2107215,
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: 2070983,
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: 2042536,
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: 2013874,
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: 1995558,
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: 1970510,
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: 1948653,
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: 1914876,
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: 1911274,
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: 1869290,
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: 1836759,
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: 1814036,
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: 1790390,
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: 1758689,
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: 1729098,
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: 1697711,
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: 1656948,
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: 1636118,
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: 1610481,
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: 1596105,
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: 1562827,
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: 1546539,
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: 1549009,
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: 1516289,
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: 1486728,
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: 1459873,
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: 1447439,
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: 1416358,
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: 1388200,
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: 1361361,
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: 1326368,
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: 1304058,
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: 1282101,
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: 1273611,
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: 1255152,
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: 1236860,
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: 1218286,
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: 1211684,
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: 1200893,
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: 1189187,
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: 1183418,
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: 1175891,
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: 1174422,
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: 1159616,
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: 1158966,
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: 1149013,
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: 1146773,
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: 1140933,
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: 1136247,
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: 1127577,
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: 1122461,
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: 1106312,
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: 1106911,
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: 1146955,
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: 1136593,
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: 1189958,
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: 1171919,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: 1166108,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: 1155130,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: 1146497,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: 1143586,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: 1134448,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: 1121197,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: 1149710,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: 1141561,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: 1126277,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: 1113625,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: 1103062,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: 1091257,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: 1077817,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: 1068445,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: 1052071,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: 1036373,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: 1031220,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: 1042879,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: 1030349,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: 1029778,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: 1019191,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: 998379,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: 977648,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: 965062,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: 955109,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: 940404,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: 931346,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: 902607,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: 878565,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: 852580,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: 834108,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: 813582,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: 812645,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: 787144,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: 778087,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: 743596,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: 715177,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: 689098,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: 677646,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: 652129,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: 630864,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: 612097,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: 584516,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: 566323,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: 547095,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: 526471,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: 505979,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: 480884,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: 455845,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: 424725,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: 390100,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: 360210,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: 334334,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: 309284,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: 286950,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: 256659,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: 227944,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: 200510,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: 177010,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: 154779,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: 134826,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: 117869,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: 98263,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: 80598,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: 64222,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: 52293,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: 42609,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: 31987,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: 23745,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    cases_critical: [
      {
        USA: 29733,
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: 29606,
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: 29477,
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: 29265,
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: 29308,
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: 29179,
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: 29181,
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: 29085,
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: 28920,
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: 28702,
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: 28637,
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: 28569,
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: 28753,
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: 28747,
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: 28693,
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: 28111,
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: 27968,
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: 27933,
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: 28002,
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: 28098,
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: 28247,
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: 28328,
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: 27868,
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: 27667,
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: 27558,
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: 27314,
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: 27335,
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: 27218,
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: 27008,
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: 26546,
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: 26521,
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: 26260,
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: 26157,
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: 26152,
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: 26139,
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: 25864,
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: 25382,
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: 24901,
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: 24655,
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: 24501,
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: 24427,
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: 24194,
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: 23753,
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: 23711,
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: 23176,
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: 22959,
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: 22761,
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: 22498,
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: 22195,
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: 21944,
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: 21252,
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: 21236,
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: 20569,
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: 20417,
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: 32076,
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: 18769,
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: 19063,
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: 19063,
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: 18628,
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: 18440,
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: 18335,
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: 18214,
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: 18074,
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: 17895,
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: 17645,
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: 17309,
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: 17221,
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: 17195,
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: 16983,
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: 16874,
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: 16827,
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: 16636,
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: 16471,
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: 16359,
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: 16309,
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: 16205,
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: 15740,
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: 15665,
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: 15537,
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: 15374,
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: 15415,
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: 15321,
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: 15267,
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: 15237,
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: 15143,
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: 14936,
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: 14790,
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: 14716,
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: 14777,
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: 14667,
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: 14574,
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: 14531,
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: 14314,
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: 14193,
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: 14197,
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: 14164,
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: 14201,
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: 14194,
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: 14209,
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: 14144,
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: 14057,
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: 14100,
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: 14086,
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: 14164,
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: 14089,
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: 14103,
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: 14076,
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: 14017,
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: 14064,
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: 14120,
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: 14255,
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: 14283,
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: 14170,
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: 14118,
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: 14084,
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: 14336,
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: 14375,
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: 14467,
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: 14586,
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: 14538,
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: 14541,
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: 14588,
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: 14684,
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: 15010,
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: 15069,
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: 15116,
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: 15134,
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: 15844,
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: 15936,
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: 16009,
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: 16183,
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: 16225,
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: 16371,
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: 16526,
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: 16463,
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: 16728,
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: 16729,
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: 16770,
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: 16833,
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: 16882,
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: 16969,
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: 17354,
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: 17202,
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: 17237,
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: 17186,
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: 17216,
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: 17335,
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: 17316,
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: 17473,
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: 17795,
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: 17897,
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: 18033,
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: 18056,
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: 18296,
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: 18426,
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: 18407,
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: 18725,
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: 18623,
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: 18656,
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: 18722,
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: 18864,
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: 19025,
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: 19097,
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: 19146,
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: 18993,
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: 19023,
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: 19100,
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: 19226,
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: 16793,
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: 16714,
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: 16628,
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: 16616,
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: 16683,
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: 16449,
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: 16432,
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: 16396,
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: 16155,
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: 15934,
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: 15829,
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: 15747,
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: 15700,
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: 15647,
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: 15421,
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: 15290,
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: 15987,
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: 16011,
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: 15959,
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: 15928,
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: 15897,
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: 15888,
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: 15933,
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: 15839,
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: 15811,
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: 15777,
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: 15767,
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: 15735,
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: 16535,
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: 16514,
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: 16498,
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: 16495,
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: 16533,
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: 16533,
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: 16493,
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: 16653,
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: 16674,
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: 16715,
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: 16702,
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: 258496,
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: 16606,
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: 16808,
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: 16852,
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: 16914,
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: 16904,
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: 16915,
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: 17021,
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: 17055,
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: 16991,
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: 16925,
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: 17098,
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: 16947,
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: 17105,
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: 17161,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: 17202,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: 17162,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: 17152,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: 17114,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: 17135,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: 17125,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: 17100,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: 17902,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: 17810,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: 17246,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: 16857,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: 16352,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: 16247,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: 16134,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: 16240,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: 16349,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: 16473,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: 16481,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: 16493,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: 16817,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: 16931,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: 16995,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: 15808,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: 16175,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: 16043,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: 16139,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: 16475,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: 16481,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: 15226,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: 18819,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: 15635,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: 14175,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: 15143,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: 15110,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: 14954,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: 14997,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: 14016,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: 14016,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: 13946,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: 13566,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: 13536,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: 13476,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: 13369,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: 13487,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: 13473,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: 12772,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: 11766,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: 11320,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: 10916,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: 10011,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: 9234,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: 9169,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: 8878,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: 8702,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: 8206,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: 5787,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: 5421,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: 5005,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: 3988,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: 3512,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: 2970,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: 2666,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: 2463,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: 2122,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: 1411,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: 1175,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: 1040,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: 795,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: 64,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    cases_recovered: [
      {
        USA: 12862216,
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: 12841479,
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: 12665110,
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: 12429847,
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: 12315219,
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: 12165362,
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: 12096442,
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: 11954264,
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: 11814436,
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: 11629450,
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: 11486121,
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: 11371966,
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: 11251283,
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: 11191771,
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: 11093846,
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: 10905944,
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: 10749946,
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: 10613079,
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: 10526984,
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: 10364654,
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: 10250795,
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: 10140332,
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: 9986605,
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: 9828410,
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: 9714254,
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: 9629366,
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: 9407253,
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: 9317928,
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: 9197977,
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: 9068239,
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: 8963968,
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: 8851788,
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: 8775503,
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: 8643361,
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: 8545929,
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: 8443817,
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: 8316025,
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: 8196936,
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: 8100905,
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: 8032380,
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: 7920694,
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: 7841027,
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: 7718744,
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: 7614453,
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: 7525788,
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: 7447461,
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: 7396964,
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: 7300182,
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: 7220552,
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: 7153333,
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: 7070956,
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: 7011969,
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: 6930880,
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: 6857189,
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: 6780978,
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: 6699766,
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: 6642268,
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: 6594066,
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: 6536557,
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: 6480929,
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: 6435702,
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: 6384382,
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: 6334773,
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: 6282399,
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: 6231602,
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: 6152523,
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: 6093708,
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: 6061478,
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: 6017837,
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: 5970854,
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: 5915936,
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: 5872917,
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: 5821846,
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: 5770225,
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: 5735731,
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: 5693888,
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: 5647254,
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: 5585387,
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: 5541612,
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: 5494518,
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: 5453353,
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: 5427427,
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: 5386402,
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: 5311547,
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: 5269379,
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: 5220819,
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: 5174828,
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: 5126893,
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: 5085408,
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: 5058282,
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: 5015623,
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: 4974560,
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: 4926696,
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: 4888499,
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: 4844433,
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: 4814547,
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: 4770311,
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: 4730851,
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: 4688293,
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: 4644479,
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: 4605215,
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: 4546185,
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: 4518974,
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: 4475387,
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: 4426485,
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: 4386636,
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: 4343477,
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: 4295571,
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: 4247969,
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: 4217926,
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: 4186229,
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: 4146524,
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: 4103048,
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: 4061472,
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: 4023312,
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: 3971438,
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: 3944738,
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: 3913980,
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: 3875666,
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: 3839797,
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: 3789506,
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: 3757482,
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: 3724838,
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: 3705937,
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: 3630331,
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: 3571231,
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: 3537272,
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: 3484458,
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: 3452351,
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: 3423248,
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: 3400962,
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: 3370567,
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: 3343563,
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: 3295346,
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: 3251250,
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: 3211214,
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: 3162814,
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: 3144164,
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: 3119123,
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: 3085313,
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: 3054701,
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: 3002384,
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: 2969600,
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: 2921070,
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: 2897794,
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: 2868271,
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: 2832892,
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: 2804100,
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: 2749691,
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: 2705838,
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: 2663954,
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: 2635140,
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: 2615799,
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: 2574398,
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: 2527220,
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: 2480467,
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: 2445121,
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: 2377861,
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: 2355848,
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: 2327052,
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: 2277439,
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: 2231475,
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: 2174342,
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: 2132557,
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: 2085507,
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: 2055731,
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: 2027265,
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: 1978188,
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: 1938486,
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: 1884895,
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: 1846106,
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: 1802033,
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: 1773931,
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: 1711004,
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: 1673009,
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: 1637731,
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: 1590612,
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: 1549072,
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: 1516031,
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: 1488213,
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: 1453604,
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: 1426170,
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: 1385973,
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: 1350967,
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: 1310074,
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: 1285450,
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: 1256234,
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: 1209232,
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: 1185201,
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: 1159601,
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: 1136507,
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: 1104425,
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: 1090754,
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: 1078788,
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: 1061099,
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: 1047894,
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: 1034049,
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: 1017949,
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: 991023,
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: 978399,
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: 970978,
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: 955811,
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: 929756,
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: 912495,
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: 899482,
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: 881020,
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: 867703,
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: 849834,
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: 839781,
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: 814038,
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: 801354,
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: 784493,
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: 771935,
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: 758504,
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: 748029,
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: 719368,
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: 707154,
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: 685442,
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: 625292,
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: 615301,
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: 541377,
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: 502116,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: 498672,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: 488423,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: 478225,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: 462764,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: 451584,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: 446866,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: 397294,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: 382095,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: 370057,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: 363149,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: 354340,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: 344951,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: 338872,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: 326042,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: 317015,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: 308165,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: 293485,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: 260624,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: 255654,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: 237193,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: 222935,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: 216830,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: 206203,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: 199684,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: 187025,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: 178263,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: 160668,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: 161551,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: 152324,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: 145904,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: 141907,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: 137720,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: 118777,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: 116201,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: 93369,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: 85624,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: 83978,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: 82860,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: 71947,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: 70938,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: 67438,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: 59979,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: 57271,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: 48679,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: 38675,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: 36205,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: 31986,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: 29818,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: 27239,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: 25316,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: 22314,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: 21674,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: 19536,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: 17245,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: 14741,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: 12268,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: 10403,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: 8878,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: 6461,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: 5258,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: 4435,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: 3231,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: 2471,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: 1864,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: 393,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: 370,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: 295,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: 178,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: 171,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    cases_total: [
      {
        USA: 21578606,
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: 21553983,
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: 21314451,
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: 21088687,
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: 20867714,
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: 20607799,
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: 20416740,
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: 20175559,
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: 19949430,
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: 19742984,
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: 19562997,
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: 19382507,
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: 19209126,
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: 19089498,
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: 18887924,
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: 18638921,
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: 18445519,
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: 18258572,
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: 18058649,
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: 17842543,
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: 17587944,
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: 17353567,
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: 17116660,
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: 16909295,
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: 16713732,
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: 16528549,
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: 16248633,
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: 16016731,
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: 15792456,
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: 15565121,
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: 15345569,
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: 15151018,
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: 14974723,
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: 14750860,
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: 14513968,
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: 14296094,
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: 14085742,
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: 13905348,
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: 13743489,
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: 13605048,
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: 13438675,
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: 13245266,
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: 13118642,
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: 12944442,
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: 12750641,
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: 12580717,
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: 12436316,
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: 12261457,
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: 12049640,
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: 11856976,
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: 11680102,
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: 11524240,
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: 11355906,
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: 11222027,
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: 11057495,
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: 10860469,
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: 10695526,
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: 10553727,
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: 10411103,
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: 10282293,
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: 10172991,
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: 10049738,
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: 9910173,
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: 9794356,
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: 9686593,
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: 9554318,
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: 9468765,
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: 9400687,
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: 9313308,
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: 9204925,
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: 9116535,
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: 9034099,
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: 8958965,
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: 8888056,
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: 8823899,
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: 8743333,
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: 8654232,
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: 8579580,
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: 8516610,
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: 8447242,
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: 8387046,
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: 8341588,
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: 8286042,
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: 8214498,
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: 8147406,
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: 8087538,
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: 8036894,
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: 7991057,
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: 7943524,
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: 7892873,
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: 7829930,
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: 7772511,
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: 7717022,
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: 7676378,
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: 7634110,
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: 7600175,
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: 7545776,
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: 7493252,
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: 7444804,
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: 7400872,
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: 7358620,
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: 7320256,
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: 7285360,
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: 7233536,
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: 7182375,
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: 7136558,
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: 7096434,
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: 7043346,
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: 7000612,
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: 6966766,
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: 6921038,
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: 6870309,
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: 6825764,
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: 6787153,
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: 6748106,
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: 6707028,
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: 6674220,
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: 6634231,
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: 6585288,
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: 6548208,
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: 6511595,
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: 6485399,
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: 6458987,
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: 6428993,
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: 6386444,
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: 6331877,
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: 6297624,
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: 6256428,
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: 6210356,
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: 6172631,
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: 6136785,
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: 6093751,
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: 6044459,
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: 5997927,
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: 5953983,
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: 5914058,
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: 5873167,
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: 5838707,
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: 5794802,
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: 5744554,
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: 5696818,
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: 5653087,
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: 5610345,
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: 5565116,
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: 5526727,
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: 5475559,
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: 5413676,
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: 5357663,
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: 5303654,
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: 5247009,
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: 5198207,
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: 5148372,
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: 5093833,
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: 5028027,
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: 4971884,
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: 4917495,
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: 4861081,
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: 4813056,
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: 4762954,
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: 4704764,
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: 4629258,
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: 4557840,
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: 4494742,
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: 4430477,
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: 4366304,
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: 4312286,
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: 4246683,
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: 4168004,
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: 4098467,
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: 4025356,
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: 3960404,
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: 3893949,
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: 3831667,
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: 3764214,
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: 3683309,
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: 3614545,
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: 3543766,
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: 3477684,
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: 3412502,
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: 3354704,
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: 3287900,
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: 3218838,
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: 3156920,
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: 3095374,
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: 3039111,
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: 2980829,
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: 2935086,
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: 2890328,
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: 2832897,
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: 2777099,
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: 2726481,
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: 2680635,
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: 2635544,
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: 2595133,
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: 2549840,
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: 2499127,
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: 2462361,
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: 2423534,
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: 2387236,
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: 2355797,
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: 2329817,
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: 2295489,
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: 2262072,
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: 2233323,
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: 2207796,
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: 2182717,
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: 2161447,
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: 2141780,
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: 2116211,
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: 2089031,
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: 2065493,
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: 2045417,
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: 2025918,
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: 2007220,
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: 1987683,
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: 1953203,
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: 1923637,
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: 1901493,
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: 1880297,
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: 1858815,
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: 1837525,
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: 1777857,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: 1768100,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: 1745647,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: 1725265,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: 1706154,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: 1685318,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: 1666736,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: 1644639,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: 1619936,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: 1591252,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: 1570293,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: 1549357,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: 1527181,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: 1506227,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: 1482961,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: 1455973,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: 1429709,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: 1408073,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: 1385262,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: 1366760,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: 1347003,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: 1320683,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: 1292114,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: 1258051,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: 1236987,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: 1211839,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: 1187233,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: 1159404,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: 1129882,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: 1094730,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: 1059992,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: 1035240,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: 1007949,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: 986818,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: 957505,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: 923543,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: 878974,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: 846692,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: 817187,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: 792076,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: 763594,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: 737235,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: 709193,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: 676339,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: 643508,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: 611745,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: 586285,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: 560055,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: 531257,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: 501778,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: 466651,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: 427079,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: 394636,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: 364716,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: 336131,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: 310133,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: 276318,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: 244230,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: 214482,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: 187321,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: 163183,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: 141732,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: 123311,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: 102338,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: 83671,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: 65544,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: 53358,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: 43449,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: 32580,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: 24218,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    deaths_new: [
      {
        USA: '+3499',
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: '+3067',
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: '+1590',
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: '+1368',
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: '+1910',
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: '+2028',
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: '+2897',
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: '+3099',
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: '+3077',
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: '+1679',
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: '+1191',
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: '+1325',
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: '+1197',
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: '+2578',
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: '+3096',
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: '+2785',
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: '+1558',
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: '+1386',
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: '+2494',
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: '+2329',
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: '+2806',
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: '+3085',
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: '+2482',
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: '+1368',
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: '+1307',
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: '+2157',
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: '+2592',
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: '+2784',
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: '+2940',
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: '+2554',
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: '+1392',
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: '+1049',
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: '+2210',
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: '+2555',
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: '+2681',
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: '+2660',
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: '+2363',
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: '+1065',
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: '+800',
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: '+1209',
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: '+1312',
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: '+1262',
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: '+2120',
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: '+2115',
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: '+833',
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: '+852',
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: '+1419',
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: '+1854',
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: '+1833',
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: '+1807',
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: '+1406',
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: '+697',
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: '+562',
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: '+1242',
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: '+1361',
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: '+1088',
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: '+1295',
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: '+1291',
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: '+585',
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: '+480',
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: '+1000',
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: '+1179',
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: '+1065',
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: '+1148',
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: '+1130',
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: '+443',
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: '+365',
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: '+905',
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: '+966',
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: '+975',
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: '+1013',
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: '+969',
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: '+509',
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: '+429',
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: '+761',
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: '+888',
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: '+879',
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: '+1131',
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: '+831',
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: '+397',
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: '+446',
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: '+633',
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: '+903',
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: '+845',
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: '+940',
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: '+768',
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: '+315',
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: '+325',
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: '+605',
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: '+876',
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: '+857',
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: '+835',
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: '+683',
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: '+359',
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: '+323',
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: '+748',
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: '+791',
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: '+878',
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: '+901',
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: '+882',
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: '+298',
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: '+270',
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: '+717',
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: '+761',
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: '+864',
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: '+1024',
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: '+902',
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: '+327',
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: '+291',
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: '+655',
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: '+893',
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: '+800',
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: '+1069',
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: '+1158',
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: '+431',
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: '+348',
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: '+696',
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: '+1034',
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: '+894',
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: '+1145',
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: '+436',
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: '+263',
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: '+396',
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: '+703',
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: '+974',
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: '+995',
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: '+1028',
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: '+1133',
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: '+485',
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: '+360',
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: '+924',
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: '+1068',
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: '+1087',
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: '+1229',
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: '+1217',
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: '+485',
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: '+402',
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: '+942',
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: '+1111',
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: '+997',
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: '+1131',
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: '+1225',
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: '+534',
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: '+490',
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: '+1011',
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: '+1080',
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: '+1206',
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: '+1304',
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: '+1355',
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: '+486',
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: '+493',
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: '+947',
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: '+1244',
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: '+1107',
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: '+1265',
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: '+1328',
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: '+539',
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: '+439',
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: '+1080',
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: '+1454',
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: '+1175',
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: '+1259',
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: '+1070',
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: '+554',
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: '+377',
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: '+850',
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: '+1086',
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: '+1097',
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: '+1154',
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: '+1069',
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: '+499',
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: '+386',
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: '+796',
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: '+818',
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: '+866',
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: '+912',
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: '+871',
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: '+440',
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: '+380',
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: '+722',
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: '+763',
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: '+858',
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: '+857',
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: '+947',
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: '+331',
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: '+234',
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: '+249',
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: '+602',
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: '+609',
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: '+648',
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: '+743',
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: '+334',
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: '+280',
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: '+505',
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: '+600',
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: '+584',
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: '+781',
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: '+874',
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: '+355',
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: '+266',
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: '+572',
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: '+704',
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: '+691',
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: '+803',
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: '+844',
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: '+421',
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: '+326',
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: '+699',
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: '+780',
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: '+897',
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: '+978',
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: '+1096',
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: '+581',
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: '+373',
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: '+687',
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: '+1201',
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: '+1029',
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: '+1081',
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: '+1125',
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: '+726',
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: '+633',
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: '+492',
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: '+1213',
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: '+1522',
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: '+738',
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: '+504',
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: '+603',
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: '+1026',
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: '+1281',
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: '+1344',
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: '+1385',
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: '+1538',
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: '+977',
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: '+860',
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: '+1031',
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: '+1562',
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: '+1690',
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: '+1746',
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: '+1573',
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: '+972',
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: '+720',
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: '+1417',
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: '+1629',
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: '+2106',
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: '+1929',
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: '+2320',
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: '+1108',
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: '+1122',
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: '+1637',
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: '+1868',
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: '+2186',
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: '+2242',
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: '+2429',
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: '+1234',
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: '+1140',
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: '+1967',
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: '+1853',
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: '+2095',
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: '+2219',
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: '+2715',
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: '+1908',
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: '+1513',
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: '+1779',
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: '+2498',
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: '+2109',
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: '+2459',
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: '+2335',
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: '+1504',
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: '+1513',
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: '+1808',
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: '+2012',
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: '+1819',
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: '+1824',
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: '+1881',
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: '+1230',
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: '+1151',
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: '+1038',
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: '+1320',
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: '+781',
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: '+1041',
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: '+709',
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: '+563',
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: '+251',
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: '+515',
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: '+309',
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: '+182',
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: '+149',
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: '+142',
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: '+132',
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: '+113',
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: '+46',
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    deaths_1M_pop: [
      {
        USA: '1101',
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: '1100',
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: '1090',
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: '1085',
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: '1080',
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: '1073',
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: '1065',
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: '1054',
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: '1043',
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: '1033',
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: '1028',
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: '1023',
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: '1019',
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: '1015',
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: '1006',
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: '995',
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: '984',
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: '979',
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: '974',
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: '965',
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: '956',
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: '947',
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: '936',
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: '928',
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: '923',
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: '919',
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: '911',
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: '902',
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: '893',
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: '883',
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: '875',
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: '871',
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: '867',
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: '860',
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: '852',
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: '843',
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: '834',
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: '826',
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: '823',
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: '821',
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: '817',
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: '812',
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: '808',
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: '801',
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: '794',
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: '792',
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: '789',
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: '784',
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: '778',
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: '772',
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: '766',
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: '761',
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: '759',
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: '757',
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: '753',
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: '749',
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: '745',
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: '741',
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: '737',
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: '735',
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: '733',
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: '730',
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: '726',
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: '723',
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: '719',
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: '714',
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: '713',
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: '712',
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: '709',
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: '706',
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: '703',
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: '700',
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: '697',
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: '695',
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: '694',
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: '691',
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: '688',
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: '686',
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: '682',
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: '679',
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: '678',
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: '676',
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: '674',
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: '672',
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: '669',
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: '666',
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: '664',
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: '663',
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: '661',
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: '659',
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: '656',
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: '654',
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: '651',
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: '648',
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: '647',
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: '646',
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: '644',
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: '641',
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: '639',
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: '636',
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: '633',
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: '632',
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: '631',
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: '628',
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: '626',
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: '623',
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: '620',
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: '617',
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: '616',
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: '615',
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: '613',
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: '610',
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: '607',
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: '604',
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: '600',
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: '599',
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: '598',
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: '596',
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: '592',
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: '589',
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: '585',
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: '584',
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: '583',
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: '582',
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: '580',
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: '576',
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: '573',
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: '570',
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: '567',
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: '565',
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: '564',
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: '561',
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: '558',
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: '554',
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: '550',
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: '547',
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: '545',
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: '544',
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: '541',
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: '535',
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: '532',
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: '528',
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: '524',
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: '523',
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: '521',
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: '518',
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: '514',
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: '510',
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: '506',
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: '502',
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: '500',
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: '498',
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: '495',
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: '491',
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: '488',
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: '484',
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: '480',
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: '478',
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: '477',
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: '473',
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: '468',
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: '464',
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: '459',
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: '454',
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: '452',
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: '451',
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: '448',
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: '445',
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: '441',
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: '438',
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: '434',
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: '433',
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: '431',
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: '429',
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: '426',
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: '423',
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: '420',
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: '418',
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: '416',
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: '415',
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: '413',
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: '410',
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: '407',
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: '405',
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: '402',
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: '400',
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: '400',
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: '399',
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: '397',
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: '395',
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: '393',
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: '389',
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: '388',
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: '387',
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: '385',
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: '377',
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: '375',
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: '373',
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: '370',
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: '369',
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: '369',
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: '367',
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: '365',
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: '362',
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: '360',
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: '357',
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: '356',
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: '355',
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: '353',
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: '351',
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: '348',
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: '345',
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: '342',
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: '340',
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: '339',
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: '337',
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: '333',
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: '330',
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: '327',
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: '323',
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: '321',
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: null,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: null,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: null,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: null,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: null,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: null,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: null,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    deaths_total: [
      {
        USA: 365620,
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: 365190,
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: 361728,
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: 360059,
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: 358485,
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: 356344,
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: 353674,
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: 349997,
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: 346258,
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: 342895,
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: 341114,
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: 339705,
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: 338263,
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: 336809,
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: 333914,
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: 330147,
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: 326489,
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: 324841,
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: 323339,
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: 320380,
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: 317457,
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: 314176,
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: 310579,
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: 307853,
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: 306389,
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: 304930,
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: 302330,
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: 299503,
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: 296363,
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: 293055,
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: 290330,
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: 288880,
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: 287783,
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: 285389,
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: 282594,
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: 279697,
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: 276728,
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: 274159,
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: 273053,
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: 272247,
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: 270939,
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: 269511,
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: 268039,
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: 265820,
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: 263547,
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: 262682,
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: 261749,
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: 260187,
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: 258102,
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: 256105,
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: 254085,
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: 252609,
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: 251815,
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: 251238,
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: 249941,
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: 248486,
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: 247214,
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: 245746,
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: 244392,
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: 243737,
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: 243226,
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: 242161,
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: 240894,
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: 239776,
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: 238570,
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: 236918,
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: 236437,
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: 236063,
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: 235137,
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: 234105,
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: 233096,
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: 232014,
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: 231025,
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: 230497,
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: 230045,
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: 229269,
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: 228287,
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: 227315,
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: 226053,
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: 225171,
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: 224728,
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: 224277,
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: 223619,
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: 222688,
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: 221813,
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: 220786,
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: 220010,
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: 219695,
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: 219252,
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: 218614,
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: 217638,
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: 216687,
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: 215715,
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: 214970,
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: 214602,
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: 214270,
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: 213451,
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: 212618,
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: 211686,
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: 210690,
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: 209751,
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: 209447,
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: 209157,
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: 208281,
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: 207460,
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: 206505,
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: 205404,
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: 204445,
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: 204115,
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: 203822,
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: 203106,
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: 202134,
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: 201266,
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: 200158,
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: 198951,
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: 198476,
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: 198117,
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: 197361,
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: 196132,
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: 195175,
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: 193970,
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: 193511,
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: 193214,
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: 192814,
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: 192032,
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: 190959,
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: 189928,
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: 188869,
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: 187709,
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: 187215,
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: 186825,
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: 185864,
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: 184740,
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: 183593,
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: 182331,
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: 181089,
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: 180576,
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: 180142,
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: 179141,
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: 177331,
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: 176205,
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: 174941,
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: 173661,
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: 173096,
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: 172546,
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: 171495,
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: 170337,
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: 169049,
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: 167547,
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: 166109,
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: 165576,
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: 165041,
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: 164048,
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: 162708,
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: 161555,
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: 160256,
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: 158904,
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: 158337,
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: 157855,
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: 156739,
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: 155015,
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: 153550,
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: 152145,
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: 150402,
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: 149775,
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: 149340,
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: 148435,
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: 147280,
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: 146107,
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: 144903,
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: 143788,
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: 143263,
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: 142860,
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: 141936,
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: 141010,
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: 140055,
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: 139118,
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: 138222,
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: 137782,
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: 137393,
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: 136585,
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: 135720,
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: 134829,
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: 133926,
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: 132932,
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: 132552,
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: 132313,
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: 132087,
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: 131407,
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: 130770,
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: 130101,
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: 128771,
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: 128432,
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: 128145,
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: 127380,
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: 124865,
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: 124254,
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: 123484,
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: 122602,
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: 122246,
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: 121979,
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: 121392,
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: 120632,
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: 119935,
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: 119127,
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: 118279,
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: 117853,
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: 117524,
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: 116814,
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: 116027,
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: 115126,
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: 114151,
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: 113050,
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: 112469,
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: 112077,
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: 111374,
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: 110171,
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: 109140,
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: 108050,
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: 106921,
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: 106190,
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: 103822,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: 103320,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: 102094,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: 100543,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: 99804,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: 99286,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: 98673,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: 97635,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: 96280,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: 94918,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: 93519,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: 91955,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: 90973,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: 89538,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: 88474,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: 86887,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: 85171,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: 83368,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: 81759,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: 80757,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: 80032,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: 78557,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: 76905,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: 74200,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: 72241,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: 69705,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: 68566,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: 67390,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: 65724,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: 63841,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: 61508,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: 59225,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: 56647,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: 55396,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: 54160,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: 52087,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: 49754,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: 47537,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: 45229,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: 42483,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: 40527,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: 38933,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: 37117,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: 34552,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: 28506,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: 25975,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: 23609,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: 22090,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: 20555,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: 18694,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: 16610,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: 14665,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: 12752,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: 10846,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: 9602,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: 8442,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: 7391,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: 5883,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: 5094,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: 3850,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: 3146,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: 2471,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: 2211,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: 1604,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: 1209,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: 929,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: 695,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: 545,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: 415,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: 302,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    tests_1M_pop: [
      {
        USA: '788717',
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: '788049',
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: '782248',
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: '777191',
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: '772614',
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: '767150',
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: '763186',
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: '757529',
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: '754899',
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: '749604',
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: '742995',
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: '738406',
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: '732555',
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: '728275',
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: '723268',
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: '716807',
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: '711998',
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: '705321',
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: '700271',
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: '694018',
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: '687706',
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: '682438',
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: '677122',
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: '671227',
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: '665671',
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: '660753',
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: '653677',
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: '647409',
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: '641466',
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: '635546',
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: '630432',
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: '624719',
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: '619667',
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: '613007',
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: '607041',
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: '601967',
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: '590664',
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: '583863',
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: '579065',
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: '575239',
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: '569502',
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: '564019',
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: '559836',
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: '555008',
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: '549772',
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: '544252',
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: '538563',
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: '532894',
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: '526801',
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: '521447',
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: '516959',
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: '512407',
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: '506516',
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: '502617',
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: '497403',
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: '492049',
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: '487754',
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: '483783',
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: '479918',
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: '474732',
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: '472088',
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: '467194',
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: '462456',
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: '457986',
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: '454183',
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: '440599',
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: '437242',
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: '434682',
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: '430558',
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: '426528',
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: '411169',
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: '408221',
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: '405049',
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: '401691',
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: '398575',
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: '395004',
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: '391210',
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: '387811',
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: '385650',
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: '382862',
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: '378950',
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: '376128',
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: '373185',
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: '369727',
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: '366055',
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: '363158',
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: '360307',
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: '357315',
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: '354453',
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: '351222',
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: '347451',
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: '344156',
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: '341614',
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: '338976',
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: '336143',
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: '333350',
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: '330250',
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: '326899',
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: '324129',
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: '320062',
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: '317687',
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: '314726',
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: '312435',
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: '309068',
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: '306256',
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: '303334',
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: '300635',
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: '298326',
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: '296083',
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: '293246',
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: '290235',
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: '287099',
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: '284659',
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: '282461',
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: '281258',
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: '278773',
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: '276464',
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: '274079',
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: '271501',
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: '269355',
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: '267350',
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: '265762',
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: '263929',
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: '261518',
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: '258965',
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: '255835',
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: '253629',
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: '251389',
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: '249028',
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: '246876',
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: '244684',
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: '242097',
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: '239648',
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: '237219',
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: '234093',
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: '232024',
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: '229781',
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: '227716',
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: '225422',
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: '222926',
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: '220615',
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: '218324',
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: '215992',
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: '214167',
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: '211793',
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: '209337',
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: '206762',
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: '203751',
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: '202097',
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: '199607',
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: '197542',
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: '194768',
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: '192686',
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: '190485',
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: '188093',
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: '185845',
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: '183996',
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: '180854',
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: '178754',
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: '176890',
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: '173428',
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: '170833',
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: '168090',
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: '165865',
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: '163090',
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: '160801',
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: '158613',
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: '155710',
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: '153124',
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: '150634',
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: '148212',
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: '145951',
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: '143675',
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: '140542',
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: '137590',
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: '134859',
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: '132289',
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: '130637',
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: '128331',
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: '126004',
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: '123762',
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: '121270',
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: '119151',
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: '117160',
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: '115141',
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: '113493',
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: '111474',
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: '109582',
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: '107313',
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: '105074',
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: '103225',
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: '100042',
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: '98239',
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: '96657',
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: '94511',
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: '92638',
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: '90523',
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: '89291',
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: '87543',
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: '85971',
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: '84525',
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: '82410',
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: '80750',
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: '79205',
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: '77676',
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: '76276',
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: '74916',
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: '73357',
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: '71434',
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: '69515',
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: '68168',
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: '66791',
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: '65545',
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: '64029',
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: '62667',
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: '60966',
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: '59117',
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: '57666',
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: '56180',
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: '54856',
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: '53204',
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: null,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: null,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: null,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: null,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: null,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: null,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: null,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    tests_total: [
      {
        USA: 261856043,
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: 261630183,
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: 259700261,
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: 258017096,
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: 256493696,
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: 254675596,
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: 253355523,
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: 251473643,
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: 250596390,
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: 248834918,
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: 246636894,
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: 245109808,
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: 243163621,
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: 241739062,
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: 240073465,
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: 237925098,
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: 236324975,
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: 234104893,
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: 232425098,
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: 230346045,
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: 228247406,
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: 226495313,
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: 224727423,
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: 222767574,
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: 220920000,
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: 219284347,
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: 216932614,
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: 214849055,
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: 212873369,
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: 210905431,
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: 209205111,
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: 207305857,
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: 205626288,
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: 203412821,
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: 201429924,
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: 199743047,
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: 195989656,
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: 193729888,
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: 192134591,
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: 190862116,
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: 188955672,
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: 187133582,
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: 185742620,
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: 184137767,
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: 182397671,
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: 180563354,
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: 178673311,
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: 176789609,
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: 174765648,
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: 172986617,
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: 171495127,
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: 169982151,
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: 168025335,
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: 166729213,
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: 164996939,
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: 163218339,
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: 161791180,
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: 160471196,
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: 159186720,
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: 157464101,
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: 156584539,
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: 154958981,
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: 153384972,
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: 151899836,
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: 150636194,
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: 146128432,
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: 145012853,
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: 144161464,
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: 142791356,
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: 141452757,
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: 136356870,
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: 135377192,
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: 134323037,
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: 133207369,
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: 132171908,
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: 130985666,
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: 129725492,
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: 128596075,
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: 127877783,
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: 126951084,
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: 125652001,
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: 124714315,
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: 123736446,
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: 122587914,
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: 121368442,
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: 120406086,
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: 119458869,
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: 118465016,
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: 117514234,
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: 116441158,
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: 115189100,
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: 114094872,
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: 113250459,
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: 112373952,
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: 111433215,
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: 110505345,
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: 109476060,
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: 108363538,
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: 107443445,
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: 106093835,
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: 105304772,
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: 104321749,
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: 103560514,
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: 102442826,
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: 101509242,
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: 100538980,
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: 99642818,
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: 98876045,
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: 98131181,
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: 97189265,
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: 96189787,
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: 95148949,
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: 94338922,
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: 93608892,
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: 93208856,
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: 92383832,
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: 91617093,
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: 90825185,
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: 89969363,
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: 89256999,
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: 88591007,
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: 88063479,
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: 87454601,
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: 86654304,
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: 85806956,
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: 84768709,
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: 84036348,
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: 83292959,
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: 82509167,
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: 81795039,
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: 81067254,
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: 80209037,
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: 79396433,
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: 78590265,
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: 77553492,
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: 76866880,
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: 76122604,
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: 75437048,
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: 74676048,
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: 73847961,
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: 73081228,
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: 72321339,
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: 71547540,
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: 70942037,
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: 70154346,
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: 69339765,
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: 68485707,
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: 67487271,
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: 66938492,
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: 66112618,
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: 65427767,
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: 64507803,
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: 63817243,
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: 63087379,
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: 62294165,
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: 61548614,
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: 60935176,
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: 59893695,
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: 59197170,
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: 58579205,
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: 57431831,
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: 56571343,
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: 55662058,
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: 54924443,
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: 54004731,
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: 53245766,
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: 52520426,
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: 51558397,
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: 50701544,
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: 49876031,
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: 49073416,
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: 48324182,
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: 47569521,
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: 46531580,
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: 45553679,
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: 44648640,
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: 43797165,
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: 43249448,
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: 42485301,
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: 41714370,
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: 40971321,
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: 40145692,
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: 39443558,
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: 38784016,
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: 38114943,
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: 37568807,
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: 36899902,
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: 36272932,
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: 35521273,
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: 34779654,
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: 34167157,
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: 33112913,
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: 32515876,
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: 31991559,
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: 31280987,
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: 30660452,
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: 29959888,
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: 29551640,
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: 28972791,
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: 28451892,
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: 27973000,
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: 27272807,
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: 26723015,
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: 26211238,
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: 25704904,
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: 25241128,
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: 24790698,
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: 24274209,
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: 23637713,
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: 23002389,
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: 22556124,
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: 22100332,
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: 21687691,
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: 21185550,
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: 20734588,
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: 20171434,
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: 19559410,
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: 19079144,
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: 18587037,
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: 18148683,
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: 17601999,
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: 16488420,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: 16296242,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: 15840782,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: 15513687,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: 15167976,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: 14726190,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: 14363279,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: 13882648,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: 13418235,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: 14103510,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: 12629116,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: 12255697,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: 11827907,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: 11831043,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: 11079459,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: 10592913,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: 10251452,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: 9933508,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: 9711947,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: 9417810,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: 8917762,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: 8611547,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: 8484397,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: 7971010,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: 7697091,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: 7457986,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: 7188538,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: 6925119,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: 6691932,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: 6370285,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: 6122727,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: 5854824,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: 5667482,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: 5465499,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: 5273145,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: 5012706,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: 4510731,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: 4320329,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: 4187392,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: 4017978,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: 3861382,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: 3711141,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: 3572257,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: 3394173,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: 3258424,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: 3065019,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: 2936860,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: 2832258,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: 2671008,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: 2535519,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: 2352533,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: 2200230,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: 2064037,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: 1913049,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: 1758694,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: null,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: null,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: null,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    population: [
      {
        USA: 332002717,
        day: '2021-01-06',
        time: '2021-01-06T05:00:07+00:00',
      },
      {
        USA: 331997424,
        day: '2021-01-05',
        time: '2021-01-05T23:45:06+00:00',
      },
      {
        USA: 331992130,
        day: '2021-01-04',
        time: '2021-01-04T23:45:06+00:00',
      },
      {
        USA: 331986837,
        day: '2021-01-03',
        time: '2021-01-03T23:45:06+00:00',
      },
      {
        USA: 331981544,
        day: '2021-01-02',
        time: '2021-01-02T23:45:06+00:00',
      },
      {
        USA: 331976250,
        day: '2021-01-01',
        time: '2021-01-01T23:45:06+00:00',
      },
      {
        USA: 331970957,
        day: '2020-12-31',
        time: '2020-12-31T23:45:06+00:00',
      },
      {
        USA: 331965664,
        day: '2020-12-30',
        time: '2020-12-30T23:45:06+00:00',
      },
      {
        USA: 331960370,
        day: '2020-12-29',
        time: '2020-12-29T23:45:07+00:00',
      },
      {
        USA: 331955077,
        day: '2020-12-28',
        time: '2020-12-28T23:45:05+00:00',
      },
      {
        USA: 331949784,
        day: '2020-12-27',
        time: '2020-12-27T23:45:06+00:00',
      },
      {
        USA: 331944490,
        day: '2020-12-26',
        time: '2020-12-26T23:30:06+00:00',
      },
      {
        USA: 331939197,
        day: '2020-12-25',
        time: '2020-12-25T23:45:06+00:00',
      },
      {
        USA: 331933904,
        day: '2020-12-24',
        time: '2020-12-24T23:45:09+00:00',
      },
      {
        USA: 331928610,
        day: '2020-12-23',
        time: '2020-12-23T23:45:08+00:00',
      },
      {
        USA: 331923317,
        day: '2020-12-22',
        time: '2020-12-22T23:45:09+00:00',
      },
      {
        USA: 331918024,
        day: '2020-12-21',
        time: '2020-12-21T23:45:06+00:00',
      },
      {
        USA: 331912730,
        day: '2020-12-20',
        time: '2020-12-20T23:45:10+00:00',
      },
      {
        USA: 331907437,
        day: '2020-12-19',
        time: '2020-12-19T23:45:10+00:00',
      },
      {
        USA: 331902144,
        day: '2020-12-18',
        time: '2020-12-18T23:45:14+00:00',
      },
      {
        USA: 331896850,
        day: '2020-12-17',
        time: '2020-12-17T23:45:06+00:00',
      },
      {
        USA: 331891557,
        day: '2020-12-16',
        time: '2020-12-16T23:30:06+00:00',
      },
      {
        USA: 331886264,
        day: '2020-12-15',
        time: '2020-12-15T23:45:05+00:00',
      },
      {
        USA: 331880970,
        day: '2020-12-14',
        time: '2020-12-14T23:45:07+00:00',
      },
      {
        USA: 331875677,
        day: '2020-12-13',
        time: '2020-12-13T23:45:07+00:00',
      },
      {
        USA: 331870384,
        day: '2020-12-12',
        time: '2020-12-12T23:45:10+00:00',
      },
      {
        USA: 331865090,
        day: '2020-12-11',
        time: '2020-12-11T23:45:10+00:00',
      },
      {
        USA: 331859797,
        day: '2020-12-10',
        time: '2020-12-10T23:45:35+00:00',
      },
      {
        USA: 331854504,
        day: '2020-12-09',
        time: '2020-12-09T23:45:07+00:00',
      },
      {
        USA: 331849210,
        day: '2020-12-08',
        time: '2020-12-08T23:45:06+00:00',
      },
      {
        USA: 331843917,
        day: '2020-12-07',
        time: '2020-12-07T23:45:06+00:00',
      },
      {
        USA: 331838624,
        day: '2020-12-06',
        time: '2020-12-06T23:45:09+00:00',
      },
      {
        USA: 331833330,
        day: '2020-12-05',
        time: '2020-12-05T23:45:18+00:00',
      },
      {
        USA: 331828037,
        day: '2020-12-04',
        time: '2020-12-04T23:45:11+00:00',
      },
      {
        USA: 331822744,
        day: '2020-12-03',
        time: '2020-12-03T23:45:09+00:00',
      },
      {
        USA: 331817450,
        day: '2020-12-02',
        time: '2020-12-02T23:45:12+00:00',
      },
      {
        USA: 331812157,
        day: '2020-12-01',
        time: '2020-12-01T23:45:09+00:00',
      },
      {
        USA: 331806864,
        day: '2020-11-30',
        time: '2020-11-30T23:45:09+00:00',
      },
      {
        USA: 331801570,
        day: '2020-11-29',
        time: '2020-11-29T23:45:08+00:00',
      },
      {
        USA: 331796277,
        day: '2020-11-28',
        time: '2020-11-28T23:45:10+00:00',
      },
      {
        USA: 331790984,
        day: '2020-11-27',
        time: '2020-11-27T23:45:10+00:00',
      },
      {
        USA: 331785690,
        day: '2020-11-26',
        time: '2020-11-26T23:45:09+00:00',
      },
      {
        USA: 331780397,
        day: '2020-11-25',
        time: '2020-11-25T23:45:09+00:00',
      },
      {
        USA: 331775104,
        day: '2020-11-24',
        time: '2020-11-24T23:45:08+00:00',
      },
      {
        USA: 331769810,
        day: '2020-11-23',
        time: '2020-11-23T23:45:08+00:00',
      },
      {
        USA: 331764517,
        day: '2020-11-22',
        time: '2020-11-22T23:45:08+00:00',
      },
      {
        USA: 331759224,
        day: '2020-11-21',
        time: '2020-11-21T23:45:07+00:00',
      },
      {
        USA: 331753930,
        day: '2020-11-20',
        time: '2020-11-20T23:45:07+00:00',
      },
      {
        USA: 331748637,
        day: '2020-11-19',
        time: '2020-11-19T23:30:06+00:00',
      },
      {
        USA: 331743344,
        day: '2020-11-18',
        time: '2020-11-18T23:45:07+00:00',
      },
      {
        USA: 331738050,
        day: '2020-11-17',
        time: '2020-11-17T23:45:08+00:00',
      },
      {
        USA: 331732757,
        day: '2020-11-16',
        time: '2020-11-16T23:45:08+00:00',
      },
      {
        USA: 331727464,
        day: '2020-11-15',
        time: '2020-11-15T23:45:06+00:00',
      },
      {
        USA: 331722170,
        day: '2020-11-14',
        time: '2020-11-14T23:45:06+00:00',
      },
      {
        USA: 331716877,
        day: '2020-11-13',
        time: '2020-11-13T23:45:05+00:00',
      },
      {
        USA: 331711584,
        day: '2020-11-12',
        time: '2020-11-12T23:45:08+00:00',
      },
      {
        USA: 331706290,
        day: '2020-11-11',
        time: '2020-11-11T23:45:10+00:00',
      },
      {
        USA: 331700997,
        day: '2020-11-10',
        time: '2020-11-10T23:45:08+00:00',
      },
      {
        USA: 331695704,
        day: '2020-11-09',
        time: '2020-11-09T23:45:07+00:00',
      },
      {
        USA: 331690410,
        day: '2020-11-08',
        time: '2020-11-08T23:45:06+00:00',
      },
      {
        USA: 331685117,
        day: '2020-11-07',
        time: '2020-11-07T23:45:07+00:00',
      },
      {
        USA: 331679824,
        day: '2020-11-06',
        time: '2020-11-06T23:45:06+00:00',
      },
      {
        USA: 331674530,
        day: '2020-11-05',
        time: '2020-11-05T23:45:06+00:00',
      },
      {
        USA: 331669237,
        day: '2020-11-04',
        time: '2020-11-04T23:45:06+00:00',
      },
      {
        USA: 331663944,
        day: '2020-11-03',
        time: '2020-11-03T23:45:06+00:00',
      },
      {
        USA: 331658650,
        day: '2020-11-02',
        time: '2020-11-02T23:45:07+00:00',
      },
      {
        USA: 331653357,
        day: '2020-11-01',
        time: '2020-11-01T23:30:07+00:00',
      },
      {
        USA: 331648064,
        day: '2020-10-31',
        time: '2020-10-31T23:45:05+00:00',
      },
      {
        USA: 331642770,
        day: '2020-10-30',
        time: '2020-10-30T23:45:06+00:00',
      },
      {
        USA: 331637477,
        day: '2020-10-29',
        time: '2020-10-29T23:46:07+00:00',
      },
      {
        USA: 331632184,
        day: '2020-10-28',
        time: '2020-10-28T23:45:06+00:00',
      },
      {
        USA: 331626890,
        day: '2020-10-27',
        time: '2020-10-27T23:45:06+00:00',
      },
      {
        USA: 331621597,
        day: '2020-10-26',
        time: '2020-10-26T23:45:06+00:00',
      },
      {
        USA: 331616304,
        day: '2020-10-25',
        time: '2020-10-25T23:45:06+00:00',
      },
      {
        USA: 331611010,
        day: '2020-10-24',
        time: '2020-10-24T23:45:06+00:00',
      },
      {
        USA: 331605717,
        day: '2020-10-23',
        time: '2020-10-23T23:45:06+00:00',
      },
      {
        USA: 331600424,
        day: '2020-10-22',
        time: '2020-10-22T23:45:11+00:00',
      },
      {
        USA: 331595130,
        day: '2020-10-21',
        time: '2020-10-21T23:45:06+00:00',
      },
      {
        USA: 331589837,
        day: '2020-10-20',
        time: '2020-10-20T23:45:06+00:00',
      },
      {
        USA: 331584544,
        day: '2020-10-19',
        time: '2020-10-19T23:45:06+00:00',
      },
      {
        USA: 331579250,
        day: '2020-10-18',
        time: '2020-10-18T23:45:06+00:00',
      },
      {
        USA: 331573957,
        day: '2020-10-17',
        time: '2020-10-17T23:45:04+00:00',
      },
      {
        USA: 331568664,
        day: '2020-10-16',
        time: '2020-10-16T23:45:06+00:00',
      },
      {
        USA: 331563370,
        day: '2020-10-15',
        time: '2020-10-15T23:45:06+00:00',
      },
      {
        USA: 331558077,
        day: '2020-10-14',
        time: '2020-10-14T23:45:06+00:00',
      },
      {
        USA: 331552784,
        day: '2020-10-13',
        time: '2020-10-13T23:45:06+00:00',
      },
      {
        USA: 331547490,
        day: '2020-10-12',
        time: '2020-10-12T23:45:06+00:00',
      },
      {
        USA: 331542197,
        day: '2020-10-11',
        time: '2020-10-11T23:45:06+00:00',
      },
      {
        USA: 331536904,
        day: '2020-10-10',
        time: '2020-10-10T23:45:06+00:00',
      },
      {
        USA: 331531610,
        day: '2020-10-09',
        time: '2020-10-09T23:45:06+00:00',
      },
      {
        USA: 331526317,
        day: '2020-10-08',
        time: '2020-10-08T23:45:12+00:00',
      },
      {
        USA: 331521024,
        day: '2020-10-07',
        time: '2020-10-07T23:45:06+00:00',
      },
      {
        USA: 331515730,
        day: '2020-10-06',
        time: '2020-10-06T23:45:04+00:00',
      },
      {
        USA: 331510437,
        day: '2020-10-05',
        time: '2020-10-05T23:45:07+00:00',
      },
      {
        USA: 331505144,
        day: '2020-10-04',
        time: '2020-10-04T23:45:05+00:00',
      },
      {
        USA: 331499850,
        day: '2020-10-03',
        time: '2020-10-03T23:45:06+00:00',
      },
      {
        USA: 331494557,
        day: '2020-10-02',
        time: '2020-10-02T23:45:06+00:00',
      },
      {
        USA: 331489264,
        day: '2020-10-01',
        time: '2020-10-01T23:45:06+00:00',
      },
      {
        USA: 331483970,
        day: '2020-09-30',
        time: '2020-09-30T23:45:06+00:00',
      },
      {
        USA: 331478677,
        day: '2020-09-29',
        time: '2020-09-29T23:45:06+00:00',
      },
      {
        USA: 331473384,
        day: '2020-09-28',
        time: '2020-09-28T23:45:06+00:00',
      },
      {
        USA: 331468090,
        day: '2020-09-27',
        time: '2020-09-27T23:45:08+00:00',
      },
      {
        USA: 331462797,
        day: '2020-09-26',
        time: '2020-09-26T23:45:06+00:00',
      },
      {
        USA: 331457504,
        day: '2020-09-25',
        time: '2020-09-25T23:45:06+00:00',
      },
      {
        USA: 331452210,
        day: '2020-09-24',
        time: '2020-09-24T23:45:06+00:00',
      },
      {
        USA: 331446917,
        day: '2020-09-23',
        time: '2020-09-23T23:45:06+00:00',
      },
      {
        USA: 331441624,
        day: '2020-09-22',
        time: '2020-09-22T23:45:06+00:00',
      },
      {
        USA: 331436330,
        day: '2020-09-21',
        time: '2020-09-21T23:45:06+00:00',
      },
      {
        USA: 331431037,
        day: '2020-09-20',
        time: '2020-09-20T23:30:07+00:00',
      },
      {
        USA: 331425744,
        day: '2020-09-19',
        time: '2020-09-19T23:45:07+00:00',
      },
      {
        USA: 331420450,
        day: '2020-09-18',
        time: '2020-09-18T23:45:07+00:00',
      },
      {
        USA: 331415157,
        day: '2020-09-17',
        time: '2020-09-17T23:45:07+00:00',
      },
      {
        USA: 331409864,
        day: '2020-09-16',
        time: '2020-09-16T23:45:06+00:00',
      },
      {
        USA: 331404570,
        day: '2020-09-15',
        time: '2020-09-15T23:45:07+00:00',
      },
      {
        USA: 331399277,
        day: '2020-09-14',
        time: '2020-09-14T23:45:06+00:00',
      },
      {
        USA: 331393984,
        day: '2020-09-13',
        time: '2020-09-13T23:45:06+00:00',
      },
      {
        USA: 331388690,
        day: '2020-09-12',
        time: '2020-09-12T23:45:06+00:00',
      },
      {
        USA: 331383397,
        day: '2020-09-11',
        time: '2020-09-11T23:45:07+00:00',
      },
      {
        USA: 331378104,
        day: '2020-09-10',
        time: '2020-09-10T23:45:06+00:00',
      },
      {
        USA: 331372810,
        day: '2020-09-09',
        time: '2020-09-09T23:45:07+00:00',
      },
      {
        USA: 331367517,
        day: '2020-09-08',
        time: '2020-09-08T23:45:06+00:00',
      },
      {
        USA: 331362224,
        day: '2020-09-07',
        time: '2020-09-07T23:45:06+00:00',
      },
      {
        USA: 331356930,
        day: '2020-09-06',
        time: '2020-09-06T23:45:06+00:00',
      },
      {
        USA: 331351637,
        day: '2020-09-05',
        time: '2020-09-05T23:45:07+00:00',
      },
      {
        USA: 331346344,
        day: '2020-09-04',
        time: '2020-09-04T23:45:07+00:00',
      },
      {
        USA: 331341050,
        day: '2020-09-03',
        time: '2020-09-03T23:45:07+00:00',
      },
      {
        USA: 331335757,
        day: '2020-09-02',
        time: '2020-09-02T23:45:06+00:00',
      },
      {
        USA: 331330464,
        day: '2020-09-01',
        time: '2020-09-01T23:45:07+00:00',
      },
      {
        USA: 331325170,
        day: '2020-08-31',
        time: '2020-08-31T23:45:07+00:00',
      },
      {
        USA: 331319877,
        day: '2020-08-30',
        time: '2020-08-30T23:45:06+00:00',
      },
      {
        USA: 331314584,
        day: '2020-08-29',
        time: '2020-08-29T23:45:06+00:00',
      },
      {
        USA: 331309290,
        day: '2020-08-28',
        time: '2020-08-28T23:45:07+00:00',
      },
      {
        USA: 331303997,
        day: '2020-08-27',
        time: '2020-08-27T23:45:07+00:00',
      },
      {
        USA: 331298704,
        day: '2020-08-26',
        time: '2020-08-26T23:45:37+00:00',
      },
      {
        USA: 331293410,
        day: '2020-08-25',
        time: '2020-08-25T23:45:07+00:00',
      },
      {
        USA: 331288117,
        day: '2020-08-24',
        time: '2020-08-24T23:45:07+00:00',
      },
      {
        USA: 331282824,
        day: '2020-08-23',
        time: '2020-08-23T23:45:06+00:00',
      },
      {
        USA: 331277530,
        day: '2020-08-22',
        time: '2020-08-22T23:45:06+00:00',
      },
      {
        USA: 331272237,
        day: '2020-08-21',
        time: '2020-08-21T23:45:07+00:00',
      },
      {
        USA: 331266944,
        day: '2020-08-20',
        time: '2020-08-20T23:45:06+00:00',
      },
      {
        USA: 331261650,
        day: '2020-08-19',
        time: '2020-08-19T23:45:06+00:00',
      },
      {
        USA: 331256357,
        day: '2020-08-18',
        time: '2020-08-18T23:45:06+00:00',
      },
      {
        USA: 331251064,
        day: '2020-08-17',
        time: '2020-08-17T23:45:06+00:00',
      },
      {
        USA: 331245770,
        day: '2020-08-16',
        time: '2020-08-16T23:45:06+00:00',
      },
      {
        USA: 331240477,
        day: '2020-08-15',
        time: '2020-08-15T23:45:07+00:00',
      },
      {
        USA: 331235184,
        day: '2020-08-14',
        time: '2020-08-14T23:45:07+00:00',
      },
      {
        USA: 331229890,
        day: '2020-08-13',
        time: '2020-08-13T23:45:05+00:00',
      },
      {
        USA: 331224597,
        day: '2020-08-12',
        time: '2020-08-12T23:45:05+00:00',
      },
      {
        USA: 331219304,
        day: '2020-08-11',
        time: '2020-08-11T23:45:06+00:00',
      },
      {
        USA: 331214010,
        day: '2020-08-10',
        time: '2020-08-10T23:45:05+00:00',
      },
      {
        USA: 331208717,
        day: '2020-08-09',
        time: '2020-08-09T23:45:04+00:00',
      },
      {
        USA: 331203424,
        day: '2020-08-08',
        time: '2020-08-08T23:45:06+00:00',
      },
      {
        USA: 331198130,
        day: '2020-08-07',
        time: '2020-08-07T23:45:06+00:00',
      },
      {
        USA: 331192837,
        day: '2020-08-06',
        time: '2020-08-06T23:45:07+00:00',
      },
      {
        USA: 331187544,
        day: '2020-08-05',
        time: '2020-08-05T23:45:06+00:00',
      },
      {
        USA: 331182250,
        day: '2020-08-04',
        time: '2020-08-04T23:45:06+00:00',
      },
      {
        USA: 331176957,
        day: '2020-08-03',
        time: '2020-08-03T23:45:06+00:00',
      },
      {
        USA: 331171664,
        day: '2020-08-02',
        time: '2020-08-02T23:45:07+00:00',
      },
      {
        USA: 331166370,
        day: '2020-08-01',
        time: '2020-08-01T23:45:06+00:00',
      },
      {
        USA: 331161077,
        day: '2020-07-31',
        time: '2020-07-31T23:45:06+00:00',
      },
      {
        USA: 331155784,
        day: '2020-07-30',
        time: '2020-07-30T23:45:07+00:00',
      },
      {
        USA: 331150490,
        day: '2020-07-29',
        time: '2020-07-29T23:45:06+00:00',
      },
      {
        USA: 331145197,
        day: '2020-07-28',
        time: '2020-07-28T23:45:06+00:00',
      },
      {
        USA: 331139904,
        day: '2020-07-27',
        time: '2020-07-27T23:45:06+00:00',
      },
      {
        USA: 331134610,
        day: '2020-07-26',
        time: '2020-07-26T23:45:07+00:00',
      },
      {
        USA: 331129317,
        day: '2020-07-25',
        time: '2020-07-25T23:45:07+00:00',
      },
      {
        USA: 331124024,
        day: '2020-07-24',
        time: '2020-07-24T23:45:06+00:00',
      },
      {
        USA: 331118730,
        day: '2020-07-23',
        time: '2020-07-23T23:45:07+00:00',
      },
      {
        USA: 331113437,
        day: '2020-07-22',
        time: '2020-07-22T23:45:06+00:00',
      },
      {
        USA: 331108144,
        day: '2020-07-21',
        time: '2020-07-21T23:45:07+00:00',
      },
      {
        USA: 331102850,
        day: '2020-07-20',
        time: '2020-07-20T23:45:07+00:00',
      },
      {
        USA: 331097557,
        day: '2020-07-19',
        time: '2020-07-19T23:45:04+00:00',
      },
      {
        USA: 331092264,
        day: '2020-07-18',
        time: '2020-07-18T23:45:06+00:00',
      },
      {
        USA: 331086970,
        day: '2020-07-17',
        time: '2020-07-17T23:45:06+00:00',
      },
      {
        USA: 331081677,
        day: '2020-07-16',
        time: '2020-07-16T23:45:10+00:00',
      },
      {
        USA: 331076384,
        day: '2020-07-15',
        time: '2020-07-15T23:45:06+00:00',
      },
      {
        USA: 331071090,
        day: '2020-07-14',
        time: '2020-07-14T23:45:07+00:00',
      },
      {
        USA: 331065797,
        day: '2020-07-13',
        time: '2020-07-13T23:45:05+00:00',
      },
      {
        USA: 331060504,
        day: '2020-07-12',
        time: '2020-07-12T23:45:06+00:00',
      },
      {
        USA: 331055210,
        day: '2020-07-11',
        time: '2020-07-11T23:45:06+00:00',
      },
      {
        USA: 331049917,
        day: '2020-07-10',
        time: '2020-07-10T23:45:06+00:00',
      },
      {
        USA: 331044624,
        day: '2020-07-09',
        time: '2020-07-09T23:45:06+00:00',
      },
      {
        USA: 331039330,
        day: '2020-07-08',
        time: '2020-07-08T23:45:06+00:00',
      },
      {
        USA: 331034037,
        day: '2020-07-07',
        time: '2020-07-07T23:45:06+00:00',
      },
      {
        USA: 331028744,
        day: '2020-07-06',
        time: '2020-07-06T23:45:06+00:00',
      },
      {
        USA: 331023450,
        day: '2020-07-05',
        time: '2020-07-05T23:45:06+00:00',
      },
      {
        USA: 331018157,
        day: '2020-07-04',
        time: '2020-07-04T23:45:06+00:00',
      },
      {
        USA: 331012864,
        day: '2020-07-03',
        time: '2020-07-03T23:45:06+00:00',
      },
      {
        USA: 331007570,
        day: '2020-07-02',
        time: '2020-07-02T23:45:07+00:00',
      },
      {
        USA: 331002277,
        day: '2020-07-01',
        time: '2020-07-01T23:45:06+00:00',
      },
      {
        USA: 330996984,
        day: '2020-06-30',
        time: '2020-06-30T23:45:07+00:00',
      },
      {
        USA: 330991690,
        day: '2020-06-29',
        time: '2020-06-29T23:45:06+00:00',
      },
      {
        USA: 330986397,
        day: '2020-06-28',
        time: '2020-06-28T23:45:05+00:00',
      },
      {
        USA: 330981104,
        day: '2020-06-27',
        time: '2020-06-27T23:45:06+00:00',
      },
      {
        USA: 330975810,
        day: '2020-06-26',
        time: '2020-06-26T23:45:06+00:00',
      },
      {
        USA: 330970517,
        day: '2020-06-25',
        time: '2020-06-25T23:45:05+00:00',
      },
      {
        USA: 330965224,
        day: '2020-06-24',
        time: '2020-06-24T23:45:06+00:00',
      },
      {
        USA: 330959930,
        day: '2020-06-23',
        time: '2020-06-23T23:45:06+00:00',
      },
      {
        USA: 330954637,
        day: '2020-06-22',
        time: '2020-06-22T23:45:07+00:00',
      },
      {
        USA: 330949344,
        day: '2020-06-21',
        time: '2020-06-21T23:45:07+00:00',
      },
      {
        USA: 330944050,
        day: '2020-06-20',
        time: '2020-06-20T23:45:07+00:00',
      },
      {
        USA: 330938757,
        day: '2020-06-19',
        time: '2020-06-19T23:45:07+00:00',
      },
      {
        USA: 330933464,
        day: '2020-06-18',
        time: '2020-06-18T23:45:06+00:00',
      },
      {
        USA: 330928170,
        day: '2020-06-17',
        time: '2020-06-17T23:45:06+00:00',
      },
      {
        USA: 330922877,
        day: '2020-06-16',
        time: '2020-06-16T23:45:06+00:00',
      },
      {
        USA: 330917584,
        day: '2020-06-15',
        time: '2020-06-15T23:45:06+00:00',
      },
      {
        USA: 330912290,
        day: '2020-06-14',
        time: '2020-06-14T23:45:06+00:00',
      },
      {
        USA: 330906997,
        day: '2020-06-13',
        time: '2020-06-13T23:45:06+00:00',
      },
      {
        USA: 330901704,
        day: '2020-06-12',
        time: '2020-06-12T23:45:06+00:00',
      },
      {
        USA: 330896410,
        day: '2020-06-11',
        time: '2020-06-11T23:45:06+00:00',
      },
      {
        USA: 330891117,
        day: '2020-06-10',
        time: '2020-06-10T23:45:06+00:00',
      },
      {
        USA: 330885824,
        day: '2020-06-09',
        time: '2020-06-09T23:45:06+00:00',
      },
      {
        USA: 330880530,
        day: '2020-06-08',
        time: '2020-06-08T23:45:06+00:00',
      },
      {
        USA: 330875237,
        day: '2020-06-07',
        time: '2020-06-07T23:45:06+00:00',
      },
      {
        USA: 330869944,
        day: '2020-06-06',
        time: '2020-06-06T23:45:06+00:00',
      },
      {
        USA: 330864650,
        day: '2020-06-05',
        time: '2020-06-05T23:45:07+00:00',
      },
      {
        USA: 330859357,
        day: '2020-06-04',
        time: '2020-06-04T23:45:06+00:00',
      },
      {
        USA: 330854064,
        day: '2020-06-03',
        time: '2020-06-03T23:45:06+00:00',
      },
      {
        USA: 330848770,
        day: '2020-06-02',
        time: '2020-06-02T23:45:06+00:00',
      },
      {
        USA: 330843477,
        day: '2020-06-01',
        time: '2020-06-01T23:45:05+00:00',
      },
      {
        USA: 330838184,
        day: '2020-05-31',
        time: '2020-05-31T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-29',
        time: '2020-05-29T17:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-05-28',
        time: '2020-05-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-27',
        time: '2020-05-27T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-26',
        time: '2020-05-26T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-25',
        time: '2020-05-25T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-24',
        time: '2020-05-24T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-23',
        time: '2020-05-23T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-22',
        time: '2020-05-22T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-21',
        time: '2020-05-21T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-20',
        time: '2020-05-20T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-19',
        time: '2020-05-19T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-18',
        time: '2020-05-18T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-17',
        time: '2020-05-17T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-16',
        time: '2020-05-16T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-15',
        time: '2020-05-15T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-14',
        time: '2020-05-14T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-13',
        time: '2020-05-13T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-12',
        time: '2020-05-12T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-11',
        time: '2020-05-11T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-10',
        time: '2020-05-10T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-09',
        time: '2020-05-09T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-08',
        time: '2020-05-08T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-07',
        time: '2020-05-07T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-06',
        time: '2020-05-06T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-05',
        time: '2020-05-05T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-04',
        time: '2020-05-04T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-03',
        time: '2020-05-03T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-05-02',
        time: '2020-05-02T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-05-01',
        time: '2020-05-01T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-30',
        time: '2020-04-30T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-29',
        time: '2020-04-29T23:30:07+00:00',
      },
      {
        USA: null,
        day: '2020-04-28',
        time: '2020-04-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-27',
        time: '2020-04-27T23:15:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-26',
        time: '2020-04-26T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-25',
        time: '2020-04-25T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-24',
        time: '2020-04-24T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-23',
        time: '2020-04-23T23:45:35+00:00',
      },
      {
        USA: null,
        day: '2020-04-22',
        time: '2020-04-22T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-21',
        time: '2020-04-21T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-20',
        time: '2020-04-20T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-19',
        time: '2020-04-19T23:45:20+00:00',
      },
      {
        USA: null,
        day: '2020-04-18',
        time: '2020-04-18T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-17',
        time: '2020-04-17T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-16',
        time: '2020-04-16T23:15:08+00:00',
      },
      {
        USA: null,
        day: '2020-04-15',
        time: '2020-04-15T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-14',
        time: '2020-04-14T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-13',
        time: '2020-04-13T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-12',
        time: '2020-04-12T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-11',
        time: '2020-04-11T23:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-04-10',
        time: '2020-04-10T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-09',
        time: '2020-04-09T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-08',
        time: '2020-04-08T23:30:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-07',
        time: '2020-04-07T23:15:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-06',
        time: '2020-04-06T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-05',
        time: '2020-04-05T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-04',
        time: '2020-04-04T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-04-03',
        time: '2020-04-03T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-04-02',
        time: '2020-04-02T23:45:03+00:00',
      },
      {
        USA: null,
        day: '2020-04-01',
        time: '2020-04-01T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-31',
        time: '2020-03-31T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-03-30',
        time: '2020-03-30T23:45:04+00:00',
      },
      {
        USA: null,
        day: '2020-03-29',
        time: '2020-03-29T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-28',
        time: '2020-03-28T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-27',
        time: '2020-03-27T23:45:03+00:00',
      },
      {
        USA: null,
        day: '2020-03-26',
        time: '2020-03-26T23:30:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-25',
        time: '2020-03-25T23:00:11+00:00',
      },
      {
        USA: null,
        day: '2020-03-24',
        time: '2020-03-24T23:45:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-23',
        time: '2020-03-23T23:30:06+00:00',
      },
      {
        USA: null,
        day: '2020-03-22',
        time: '2020-03-22T23:45:05+00:00',
      },
      {
        USA: null,
        day: '2020-03-21',
        time: '2020-03-21T23:58:16+00:00',
      },
    ],
    _retrievedCountries: [],
  },
  latestTotals: [
    {
      confirmed: 164606088,
      recovered: 139994845,
      critical: 108589,
      deaths: 3998609,
      lastChange: '2021-05-08T01:58:45+02:00',
      lastUpdate: '2021-05-08T02:00:04+02:00',
    },
  ],
  statistics: [
    {
      continent: 'North-America',
      country: 'Greenland',
      population: 56842,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 31,
        '1M_pop': '545',
        total: 31,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '363622',
        total: 20669,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Fiji',
      population: 902039,
      cases: {
        new: '+4',
        active: 52,
        critical: null,
        recovered: 109,
        '1M_pop': '183',
        total: 165,
      },
      deaths: {
        new: '+1',
        '1M_pop': '4',
        total: 4,
      },
      tests: {
        '1M_pop': '78480',
        total: 70792,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Cayman-Islands',
      population: 66386,
      cases: {
        new: '+1',
        active: 13,
        critical: null,
        recovered: 546,
        '1M_pop': '8451',
        total: 561,
      },
      deaths: {
        new: null,
        '1M_pop': '30',
        total: 2,
      },
      tests: {
        '1M_pop': '1406200',
        total: 93352,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Micronesia',
      population: 115715,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 1,
        '1M_pop': '9',
        total: 1,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Asia',
      country: 'Macao',
      population: 656531,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 49,
        '1M_pop': '75',
        total: 49,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '6828',
        total: 4483,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Asia',
      country: 'Laos',
      population: 7366263,
      cases: {
        new: '+65',
        active: 1050,
        critical: null,
        recovered: 431,
        '1M_pop': '201',
        total: 1482,
      },
      deaths: {
        new: null,
        '1M_pop': '0.1',
        total: 1,
      },
      tests: {
        '1M_pop': '28803',
        total: 212173,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Oceania',
      country: 'New-Caledonia',
      population: 287800,
      cases: {
        new: null,
        active: 66,
        critical: null,
        recovered: 58,
        '1M_pop': '431',
        total: 124,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '108290',
        total: 31166,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Asia',
      country: 'Bhutan',
      population: 778916,
      cases: {
        new: '+6',
        active: 141,
        critical: null,
        recovered: 1121,
        '1M_pop': '1621',
        total: 1263,
      },
      deaths: {
        new: null,
        '1M_pop': '1',
        total: 1,
      },
      tests: {
        '1M_pop': '945252',
        total: 736272,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Europe',
      country: 'Vatican-City',
      population: 803,
      cases: {
        new: null,
        active: 12,
        critical: null,
        recovered: 15,
        '1M_pop': '33624',
        total: 27,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Saint-Kitts-and-Nevis',
      population: 53511,
      cases: {
        new: '+1',
        active: 1,
        critical: null,
        recovered: 44,
        '1M_pop': '841',
        total: 45,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '255854',
        total: 13691,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Saint-Pierre-Miquelon',
      population: 5770,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 25,
        '1M_pop': '4333',
        total: 25,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '921664',
        total: 5318,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Montserrat',
      population: 4995,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 19,
        '1M_pop': '4004',
        total: 20,
      },
      deaths: {
        new: null,
        '1M_pop': '200',
        total: 1,
      },
      tests: {
        '1M_pop': '262863',
        total: 1313,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Grenada',
      population: 112971,
      cases: {
        new: '+1',
        active: 0,
        critical: null,
        recovered: 160,
        '1M_pop': '1425',
        total: 161,
      },
      deaths: {
        new: null,
        '1M_pop': '9',
        total: 1,
      },
      tests: {
        '1M_pop': '253906',
        total: 28684,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'British-Virgin-Islands',
      population: 30403,
      cases: {
        new: null,
        active: 25,
        critical: null,
        recovered: 193,
        '1M_pop': '7203',
        total: 219,
      },
      deaths: {
        new: null,
        '1M_pop': '33',
        total: 1,
      },
      tests: {
        '1M_pop': '1308325',
        total: 39777,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Dominica',
      population: 72140,
      cases: {
        new: '+3',
        active: 2,
        critical: null,
        recovered: 176,
        '1M_pop': '2467',
        total: 178,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '219629',
        total: 15844,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Saint-Martin',
      population: 39232,
      cases: {
        new: null,
        active: 362,
        critical: 7,
        recovered: 1399,
        '1M_pop': '45193',
        total: 1773,
      },
      deaths: {
        new: null,
        '1M_pop': '306',
        total: 12,
      },
      tests: {
        '1M_pop': '703201',
        total: 27588,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Samoa',
      population: 199230,
      cases: {
        new: '+1',
        active: 1,
        critical: null,
        recovered: 2,
        '1M_pop': '15',
        total: 3,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Marshall-Islands',
      population: 59355,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 4,
        '1M_pop': '67',
        total: 4,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Wallis-and-Futuna',
      population: 11068,
      cases: {
        new: null,
        active: 394,
        critical: 1,
        recovered: 44,
        '1M_pop': '40206',
        total: 445,
      },
      deaths: {
        new: null,
        '1M_pop': '632',
        total: 7,
      },
      tests: {
        '1M_pop': '1559812',
        total: 17264,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'South-America',
      country: 'Falkland-Islands',
      population: 3567,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 63,
        '1M_pop': '17662',
        total: 63,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '2034763',
        total: 7258,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Caribbean-Netherlands',
      population: 26433,
      cases: {
        new: '+7',
        active: 15,
        critical: null,
        recovered: 1558,
        '1M_pop': '60152',
        total: 1590,
      },
      deaths: {
        new: null,
        '1M_pop': '643',
        total: 17,
      },
      tests: {
        '1M_pop': '379828',
        total: 10040,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Africa',
      country: 'Tanzania',
      population: 59886383,
      cases: {
        new: null,
        active: 305,
        critical: 7,
        recovered: 183,
        '1M_pop': '8',
        total: 509,
      },
      deaths: {
        new: null,
        '1M_pop': '0.4',
        total: 21,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'St-Vincent-Grenadines',
      population: 111244,
      cases: {
        new: '+2',
        active: 152,
        critical: 2,
        recovered: 1764,
        '1M_pop': '17331',
        total: 1928,
      },
      deaths: {
        new: null,
        '1M_pop': '108',
        total: 12,
      },
      tests: {
        '1M_pop': '420958',
        total: 46829,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Anguilla',
      population: 15118,
      cases: {
        new: '+10',
        active: 20,
        critical: null,
        recovered: 89,
        '1M_pop': '7210',
        total: 109,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '1430745',
        total: 21630,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Sint-Maarten',
      population: 43295,
      cases: {
        new: '+4',
        active: 32,
        critical: 2,
        recovered: 2213,
        '1M_pop': '52477',
        total: 2272,
      },
      deaths: {
        new: null,
        '1M_pop': '624',
        total: 27,
      },
      tests: {
        '1M_pop': '620857',
        total: 26880,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Asia',
      country: 'Brunei',
      population: 441084,
      cases: {
        new: '+1',
        active: 8,
        critical: null,
        recovered: 220,
        '1M_pop': '524',
        total: 231,
      },
      deaths: {
        new: null,
        '1M_pop': '7',
        total: 3,
      },
      tests: {
        '1M_pop': '278595',
        total: 122884,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Asia',
      country: 'China',
      population: 1439323776,
      cases: {
        new: '+9',
        active: 291,
        critical: 1,
        recovered: 85881,
        '1M_pop': '63',
        total: 90808,
      },
      deaths: {
        new: null,
        '1M_pop': '3',
        total: 4636,
      },
      tests: {
        '1M_pop': '111163',
        total: 160000000,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Antigua-and-Barbuda',
      population: 98625,
      cases: {
        new: '+2',
        active: 29,
        critical: 4,
        recovered: 1179,
        '1M_pop': '12573',
        total: 1240,
      },
      deaths: {
        new: null,
        '1M_pop': '324',
        total: 32,
      },
      tests: {
        '1M_pop': '169328',
        total: 16700,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: null,
      country: 'Diamond-Princess',
      population: null,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 699,
        '1M_pop': null,
        total: 712,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: 13,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: null,
      country: 'MS-Zaandam',
      population: null,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 7,
        '1M_pop': null,
        total: 9,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: 2,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Africa',
      country: 'Liberia',
      population: 5157525,
      cases: {
        new: '+1',
        active: 67,
        critical: 2,
        recovered: 1962,
        '1M_pop': '410',
        total: 2114,
      },
      deaths: {
        new: null,
        '1M_pop': '16',
        total: 85,
      },
      tests: {
        '1M_pop': '18977',
        total: 97875,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Africa',
      country: 'Western-Sahara',
      population: 606087,
      cases: {
        new: null,
        active: 1,
        critical: null,
        recovered: 8,
        '1M_pop': '16',
        total: 10,
      },
      deaths: {
        new: null,
        '1M_pop': '2',
        total: 1,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Africa',
      country: 'Saint-Helena',
      population: 6092,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 2,
        '1M_pop': '328',
        total: 2,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Vanuatu',
      population: 313075,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 3,
        '1M_pop': '13',
        total: 4,
      },
      deaths: {
        new: null,
        '1M_pop': '3',
        total: 1,
      },
      tests: {
        '1M_pop': '73465',
        total: 23000,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Asia',
      country: 'Taiwan',
      population: 23853960,
      cases: {
        new: '+25',
        active: 142,
        critical: null,
        recovered: 1102,
        '1M_pop': '53',
        total: 1256,
      },
      deaths: {
        new: null,
        '1M_pop': '0.5',
        total: 12,
      },
      tests: {
        '1M_pop': '23948',
        total: 571259,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'St-Barth',
      population: 9903,
      cases: {
        new: null,
        active: 511,
        critical: null,
        recovered: 462,
        '1M_pop': '98354',
        total: 974,
      },
      deaths: {
        new: null,
        '1M_pop': '101',
        total: 1,
      },
      tests: {
        '1M_pop': '2664344',
        total: 26385,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Solomon-Islands',
      population: 700611,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 20,
        '1M_pop': '29',
        total: 20,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': '6423',
        total: 4500,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Europe',
      country: 'Faeroe-Islands',
      population: 49023,
      cases: {
        new: null,
        active: 5,
        critical: null,
        recovered: 663,
        '1M_pop': '13647',
        total: 669,
      },
      deaths: {
        new: null,
        '1M_pop': '20',
        total: 1,
      },
      tests: {
        '1M_pop': '5264794',
        total: 258096,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Europe',
      country: 'Isle-of-Man',
      population: 85421,
      cases: {
        new: null,
        active: 6,
        critical: 1,
        recovered: 1555,
        '1M_pop': '18614',
        total: 1590,
      },
      deaths: {
        new: null,
        '1M_pop': '339',
        total: 29,
      },
      tests: {
        '1M_pop': '524005',
        total: 44761,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'Africa',
      country: 'Mauritius',
      population: 1273589,
      cases: {
        new: '+4',
        active: 127,
        critical: null,
        recovered: 1130,
        '1M_pop': '1000',
        total: 1274,
      },
      deaths: {
        new: null,
        '1M_pop': '13',
        total: 17,
      },
      tests: {
        '1M_pop': '281625',
        total: 358675,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:05+00:00',
    },
    {
      continent: 'North-America',
      country: 'Cura&ccedil;ao',
      population: 164671,
      cases: {
        new: '+8',
        active: 74,
        critical: 23,
        recovered: 12052,
        '1M_pop': '74354',
        total: 12244,
      },
      deaths: {
        new: '+1',
        '1M_pop': '717',
        total: 118,
      },
      tests: {
        '1M_pop': '622295',
        total: 102474,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Chad',
      population: 16828720,
      cases: {
        new: '+4',
        active: 51,
        critical: null,
        recovered: 4672,
        '1M_pop': '291',
        total: 4895,
      },
      deaths: {
        new: null,
        '1M_pop': '10',
        total: 172,
      },
      tests: {
        '1M_pop': '6903',
        total: 116167,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Sudan',
      population: 44720441,
      cases: {
        new: '+63',
        active: 3877,
        critical: null,
        recovered: 27949,
        '1M_pop': '766',
        total: 34272,
      },
      deaths: {
        new: '+1',
        '1M_pop': '55',
        total: 2446,
      },
      tests: {
        '1M_pop': '5209',
        total: 232965,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Oceania',
      country: 'French-Polynesia',
      population: 282303,
      cases: {
        new: null,
        active: 39,
        critical: 1,
        recovered: 18617,
        '1M_pop': '66584',
        total: 18797,
      },
      deaths: {
        new: null,
        '1M_pop': '499',
        total: 141,
      },
      tests: {
        '1M_pop': '93357',
        total: 26355,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Latvia',
      population: 1868147,
      cases: {
        new: '+689',
        active: 9157,
        critical: 81,
        recovered: 114981,
        '1M_pop': '67649',
        total: 126378,
      },
      deaths: {
        new: '+17',
        '1M_pop': '1199',
        total: 2240,
      },
      tests: {
        '1M_pop': '1314245',
        total: 2455203,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'El-Salvador',
      population: 6514410,
      cases: {
        new: null,
        active: 2324,
        critical: 28,
        recovered: 66418,
        '1M_pop': '10886',
        total: 70915,
      },
      deaths: {
        new: '+5',
        '1M_pop': '334',
        total: 2173,
      },
      tests: {
        '1M_pop': '145313',
        total: 946627,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Saint-Lucia',
      population: 184350,
      cases: {
        new: null,
        active: 148,
        critical: 2,
        recovered: 4502,
        '1M_pop': '25631',
        total: 4725,
      },
      deaths: {
        new: null,
        '1M_pop': '407',
        total: 75,
      },
      tests: {
        '1M_pop': '231315',
        total: 42643,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Mauritania',
      population: 4753876,
      cases: {
        new: '+21',
        active: 315,
        critical: 14,
        recovered: 17973,
        '1M_pop': '3943',
        total: 18745,
      },
      deaths: {
        new: null,
        '1M_pop': '96',
        total: 457,
      },
      tests: {
        '1M_pop': '51855',
        total: 246513,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Channel-Islands',
      population: 175238,
      cases: {
        new: null,
        active: 15,
        critical: null,
        recovered: 3956,
        '1M_pop': '23151',
        total: 4057,
      },
      deaths: {
        new: null,
        '1M_pop': '491',
        total: 86,
      },
      tests: {
        '1M_pop': '2593119',
        total: 454413,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Comoros',
      population: 885426,
      cases: {
        new: null,
        active: 26,
        critical: null,
        recovered: 3688,
        '1M_pop': '4359',
        total: 3860,
      },
      deaths: {
        new: null,
        '1M_pop': '165',
        total: 146,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'South-America',
      country: 'Suriname',
      population: 591142,
      cases: {
        new: '+121',
        active: 1222,
        critical: 26,
        recovered: 9985,
        '1M_pop': '19330',
        total: 11427,
      },
      deaths: {
        new: null,
        '1M_pop': '372',
        total: 220,
      },
      tests: {
        '1M_pop': '91739',
        total: 54231,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Afghanistan',
      population: 39679387,
      cases: {
        new: '+407',
        active: 6000,
        critical: 1124,
        recovered: 54619,
        '1M_pop': '1597',
        total: 63349,
      },
      deaths: {
        new: '+9',
        '1M_pop': '69',
        total: 2730,
      },
      tests: {
        '1M_pop': '10796',
        total: 428390,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Hong-Kong',
      population: 7549348,
      cases: {
        new: '+3',
        active: 97,
        critical: 3,
        recovered: 11511,
        '1M_pop': '1565',
        total: 11818,
      },
      deaths: {
        new: null,
        '1M_pop': '28',
        total: 210,
      },
      tests: {
        '1M_pop': '1965908',
        total: 14841321,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Liechtenstein',
      population: 38222,
      cases: {
        new: '+2',
        active: 35,
        critical: 3,
        recovered: 2887,
        '1M_pop': '77966',
        total: 2980,
      },
      deaths: {
        new: null,
        '1M_pop': '1517',
        total: 58,
      },
      tests: {
        '1M_pop': '1023207',
        total: 39109,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Guinea-Bissau',
      population: 2007576,
      cases: {
        new: null,
        active: 274,
        critical: 9,
        recovered: 3400,
        '1M_pop': '1863',
        total: 3741,
      },
      deaths: {
        new: null,
        '1M_pop': '33',
        total: 67,
      },
      tests: {
        '1M_pop': '32257',
        total: 64758,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Norway',
      population: 5457742,
      cases: {
        new: '+320',
        active: 28589,
        critical: 23,
        recovered: 88952,
        '1M_pop': '21678',
        total: 118315,
      },
      deaths: {
        new: null,
        '1M_pop': '142',
        total: 774,
      },
      tests: {
        '1M_pop': '993688',
        total: 5423294,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Madagascar',
      population: 28300139,
      cases: {
        new: '+249',
        active: 1864,
        critical: 302,
        recovered: 37150,
        '1M_pop': '1405',
        total: 39759,
      },
      deaths: {
        new: '+7',
        '1M_pop': '26',
        total: 745,
      },
      tests: {
        '1M_pop': '6863',
        total: 194234,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Guinea',
      population: 13436461,
      cases: {
        new: '+74',
        active: 2227,
        critical: 24,
        recovered: 20341,
        '1M_pop': '1691',
        total: 22719,
      },
      deaths: {
        new: '+1',
        '1M_pop': '11',
        total: 151,
      },
      tests: {
        '1M_pop': '33077',
        total: 444435,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Somalia',
      population: 16271101,
      cases: {
        new: '+71',
        active: 7408,
        critical: null,
        recovered: 6325,
        '1M_pop': '890',
        total: 14486,
      },
      deaths: {
        new: '+6',
        '1M_pop': '46',
        total: 753,
      },
      tests: {
        '1M_pop': '7383',
        total: 120132,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Malta',
      population: 442557,
      cases: {
        new: '+4',
        active: 189,
        critical: null,
        recovered: 29867,
        '1M_pop': '68857',
        total: 30473,
      },
      deaths: {
        new: null,
        '1M_pop': '942',
        total: 417,
      },
      tests: {
        '1M_pop': '2013469',
        total: 891075,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Zimbabwe',
      population: 15048804,
      cases: {
        new: '+25',
        active: 623,
        critical: 12,
        recovered: 36286,
        '1M_pop': '2558',
        total: 38491,
      },
      deaths: {
        new: '+3',
        '1M_pop': '105',
        total: 1582,
      },
      tests: {
        '1M_pop': '29324',
        total: 441294,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Gibraltar',
      population: 33682,
      cases: {
        new: null,
        active: 3,
        critical: null,
        recovered: 4189,
        '1M_pop': '127249',
        total: 4286,
      },
      deaths: {
        new: null,
        '1M_pop': '2791',
        total: 94,
      },
      tests: {
        '1M_pop': '7151297',
        total: 240870,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Guadeloupe',
      population: 400183,
      cases: {
        new: '+657',
        active: 13554,
        critical: 33,
        recovered: 2242,
        '1M_pop': '40024',
        total: 16017,
      },
      deaths: {
        new: '+11',
        '1M_pop': '552',
        total: 221,
      },
      tests: {
        '1M_pop': '488287',
        total: 195404,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Ghana',
      population: 31628917,
      cases: {
        new: '+34',
        active: 1384,
        critical: 7,
        recovered: 90992,
        '1M_pop': '2945',
        total: 93159,
      },
      deaths: {
        new: null,
        '1M_pop': '25',
        total: 783,
      },
      tests: {
        '1M_pop': '35207',
        total: 1113553,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'South-America',
      country: 'Guyana',
      population: 789823,
      cases: {
        new: '+186',
        active: 1819,
        critical: 12,
        recovered: 12693,
        '1M_pop': '18795',
        total: 14845,
      },
      deaths: {
        new: '+2',
        '1M_pop': '422',
        total: 333,
      },
      tests: {
        '1M_pop': '169272',
        total: 133695,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Vietnam',
      population: 98092415,
      cases: {
        new: '+87',
        active: 1018,
        critical: null,
        recovered: 2657,
        '1M_pop': '38',
        total: 3710,
      },
      deaths: {
        new: null,
        '1M_pop': '0.4',
        total: 35,
      },
      tests: {
        '1M_pop': '31517',
        total: 3091540,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Mozambique',
      population: 32004065,
      cases: {
        new: '+28',
        active: 1175,
        critical: 24,
        recovered: 68314,
        '1M_pop': '2197',
        total: 70315,
      },
      deaths: {
        new: null,
        '1M_pop': '26',
        total: 826,
      },
      tests: {
        '1M_pop': '16623',
        total: 532010,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'North-Macedonia',
      population: 2083300,
      cases: {
        new: '+123',
        active: 6257,
        critical: 124,
        recovered: 143069,
        '1M_pop': '74158',
        total: 154494,
      },
      deaths: {
        new: '+17',
        '1M_pop': '2481',
        total: 5168,
      },
      tests: {
        '1M_pop': '359079',
        total: 748069,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Martinique',
      population: 375015,
      cases: {
        new: '+178',
        active: 11548,
        critical: 24,
        recovered: 98,
        '1M_pop': '31295',
        total: 11736,
      },
      deaths: {
        new: '+7',
        '1M_pop': '240',
        total: 90,
      },
      tests: {
        '1M_pop': '454121',
        total: 170302,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Cyprus',
      population: 1214928,
      cases: {
        new: '+213',
        active: 31168,
        critical: 54,
        recovered: 39061,
        '1M_pop': '58087',
        total: 70571,
      },
      deaths: {
        new: '+1',
        '1M_pop': '281',
        total: 342,
      },
      tests: {
        '1M_pop': '4643153',
        total: 5641097,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Benin',
      population: 12393984,
      cases: {
        new: '+111',
        active: 242,
        critical: 35,
        recovered: 7652,
        '1M_pop': '645',
        total: 7995,
      },
      deaths: {
        new: '+1',
        '1M_pop': '8',
        total: 101,
      },
      tests: {
        '1M_pop': '48016',
        total: 595112,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Luxembourg',
      population: 634722,
      cases: {
        new: '+151',
        active: 2580,
        critical: 30,
        recovered: 65513,
        '1M_pop': '108550',
        total: 68899,
      },
      deaths: {
        new: null,
        '1M_pop': '1270',
        total: 806,
      },
      tests: {
        '1M_pop': '4347640',
        total: 2759543,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Ivory-Coast',
      population: 26937384,
      cases: {
        new: '+4',
        active: 249,
        critical: null,
        recovered: 45977,
        '1M_pop': '1727',
        total: 46520,
      },
      deaths: {
        new: '+2',
        '1M_pop': '11',
        total: 294,
      },
      tests: {
        '1M_pop': '22607',
        total: 608960,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Congo',
      population: 5633978,
      cases: {
        new: null,
        active: 2987,
        critical: null,
        recovered: 8208,
        '1M_pop': '2013',
        total: 11343,
      },
      deaths: {
        new: null,
        '1M_pop': '26',
        total: 148,
      },
      tests: {
        '1M_pop': '25195',
        total: 141948,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Sierra-Leone',
      population: 8115172,
      cases: {
        new: '+4',
        active: 917,
        critical: null,
        recovered: 3093,
        '1M_pop': '504',
        total: 4089,
      },
      deaths: {
        new: null,
        '1M_pop': '10',
        total: 79,
      },
      tests: {
        '1M_pop': '18885',
        total: 153257,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Nicaragua',
      population: 6692112,
      cases: {
        new: '+97',
        active: 2677,
        critical: null,
        recovered: 4225,
        '1M_pop': '1059',
        total: 7086,
      },
      deaths: {
        new: '+1',
        '1M_pop': '27',
        total: 184,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Gambia',
      population: 2474524,
      cases: {
        new: '+5',
        active: 111,
        critical: 3,
        recovered: 5648,
        '1M_pop': '2398',
        total: 5934,
      },
      deaths: {
        new: null,
        '1M_pop': '71',
        total: 175,
      },
      tests: {
        '1M_pop': '30866',
        total: 76379,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'DRC',
      population: 91887149,
      cases: {
        new: '+49',
        active: 3102,
        critical: null,
        recovered: 26601,
        '1M_pop': '332',
        total: 30478,
      },
      deaths: {
        new: null,
        '1M_pop': '8',
        total: 775,
      },
      tests: {
        '1M_pop': '1688',
        total: 155121,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Senegal',
      population: 17121385,
      cases: {
        new: '+28',
        active: 167,
        critical: 12,
        recovered: 39501,
        '1M_pop': '2382',
        total: 40790,
      },
      deaths: {
        new: '+1',
        '1M_pop': '66',
        total: 1122,
      },
      tests: {
        '1M_pop': '28879',
        total: 494447,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Angola',
      population: 33739304,
      cases: {
        new: '+290',
        active: 3417,
        critical: 21,
        recovered: 25629,
        '1M_pop': '880',
        total: 29695,
      },
      deaths: {
        new: '+4',
        '1M_pop': '19',
        total: 649,
      },
      tests: {
        '1M_pop': '15637',
        total: 527590,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Cabo-Verde',
      population: 561182,
      cases: {
        new: '+357',
        active: 2854,
        critical: 23,
        recovered: 24289,
        '1M_pop': '48801',
        total: 27386,
      },
      deaths: {
        new: '+5',
        '1M_pop': '433',
        total: 243,
      },
      tests: {
        '1M_pop': '282698',
        total: 158645,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Rwanda',
      population: 13227036,
      cases: {
        new: '+88',
        active: 1106,
        critical: 1,
        recovered: 24486,
        '1M_pop': '1961',
        total: 25934,
      },
      deaths: {
        new: '+2',
        '1M_pop': '26',
        total: 342,
      },
      tests: {
        '1M_pop': '102711',
        total: 1358566,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Togo',
      population: 8444886,
      cases: {
        new: '+28',
        active: 1279,
        critical: null,
        recovered: 11834,
        '1M_pop': '1568',
        total: 13238,
      },
      deaths: {
        new: null,
        '1M_pop': '15',
        total: 125,
      },
      tests: {
        '1M_pop': '38000',
        total: 320905,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Cameroon',
      population: 27111439,
      cases: {
        new: null,
        active: 3297,
        critical: 385,
        recovered: 70497,
        '1M_pop': '2764',
        total: 74946,
      },
      deaths: {
        new: null,
        '1M_pop': '42',
        total: 1152,
      },
      tests: {
        '1M_pop': '56006',
        total: 1518411,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Botswana',
      population: 2392337,
      cases: {
        new: null,
        active: 2000,
        critical: 1,
        recovered: 46290,
        '1M_pop': '20499',
        total: 49041,
      },
      deaths: {
        new: null,
        '1M_pop': '314',
        total: 751,
      },
      tests: {
        '1M_pop': '454061',
        total: 1086267,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'R&eacute;union',
      population: 900815,
      cases: {
        new: null,
        active: 872,
        critical: 36,
        recovered: 21603,
        '1M_pop': '25137',
        total: 22644,
      },
      deaths: {
        new: null,
        '1M_pop': '188',
        total: 169,
      },
      tests: {
        '1M_pop': '114030',
        total: 102720,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Eritrea',
      population: 3588465,
      cases: {
        new: '+4',
        active: 171,
        critical: null,
        recovered: 3639,
        '1M_pop': '1065',
        total: 3822,
      },
      deaths: {
        new: null,
        '1M_pop': '3',
        total: 12,
      },
      tests: {
        '1M_pop': '6603',
        total: 23693,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Mayotte',
      population: 278451,
      cases: {
        new: '+42',
        active: 17041,
        critical: 6,
        recovered: 2964,
        '1M_pop': '72458',
        total: 20176,
      },
      deaths: {
        new: '+1',
        '1M_pop': '614',
        total: 171,
      },
      tests: {
        '1M_pop': '635369',
        total: 176919,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Andorra',
      population: 77372,
      cases: {
        new: '+23',
        active: 239,
        critical: 12,
        recovered: 13104,
        '1M_pop': '174094',
        total: 13470,
      },
      deaths: {
        new: null,
        '1M_pop': '1641',
        total: 127,
      },
      tests: {
        '1M_pop': '2502133',
        total: 193595,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Burundi',
      population: 12192376,
      cases: {
        new: '+25',
        active: 3454,
        critical: null,
        recovered: 773,
        '1M_pop': '347',
        total: 4233,
      },
      deaths: {
        new: null,
        '1M_pop': '0.5',
        total: 6,
      },
      tests: {
        '1M_pop': '22894',
        total: 279132,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Djibouti',
      population: 1000343,
      cases: {
        new: '+20',
        active: 17,
        critical: null,
        recovered: 11221,
        '1M_pop': '11385',
        total: 11389,
      },
      deaths: {
        new: null,
        '1M_pop': '151',
        total: 151,
      },
      tests: {
        '1M_pop': '152914',
        total: 152966,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Seychelles',
      population: 98872,
      cases: {
        new: null,
        active: 2486,
        critical: null,
        recovered: 5658,
        '1M_pop': '82652',
        total: 8172,
      },
      deaths: {
        new: null,
        '1M_pop': '283',
        total: 28,
      },
      tests: {
        '1M_pop': '217493',
        total: 21504,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Equatorial-Guinea',
      population: 1442320,
      cases: {
        new: null,
        active: 303,
        critical: 7,
        recovered: 7279,
        '1M_pop': '5334',
        total: 7694,
      },
      deaths: {
        new: null,
        '1M_pop': '78',
        total: 112,
      },
      tests: {
        '1M_pop': '85396',
        total: 123169,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'CAR',
      population: 4901838,
      cases: {
        new: '+192',
        active: 1659,
        critical: 2,
        recovered: 5112,
        '1M_pop': '1401',
        total: 6866,
      },
      deaths: {
        new: '+2',
        '1M_pop': '19',
        total: 95,
      },
      tests: {
        '1M_pop': '8345',
        total: 40907,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'San-Marino',
      population: 33993,
      cases: {
        new: null,
        active: 25,
        critical: 4,
        recovered: 4968,
        '1M_pop': '149531',
        total: 5083,
      },
      deaths: {
        new: null,
        '1M_pop': '2648',
        total: 90,
      },
      tests: {
        '1M_pop': '1860412',
        total: 63241,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Turks-and-Caicos',
      population: 39167,
      cases: {
        new: null,
        active: 22,
        critical: 2,
        recovered: 2365,
        '1M_pop': '61378',
        total: 2404,
      },
      deaths: {
        new: null,
        '1M_pop': '434',
        total: 17,
      },
      tests: {
        '1M_pop': '1683203',
        total: 65926,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Sri-Lanka',
      population: 21490644,
      cases: {
        new: '+3269',
        active: 26126,
        critical: null,
        recovered: 108802,
        '1M_pop': '6319',
        total: 135796,
      },
      deaths: {
        new: null,
        '1M_pop': '40',
        total: 868,
      },
      tests: {
        '1M_pop': '141963',
        total: 3050870,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Thailand',
      population: 69951033,
      cases: {
        new: '+4887',
        active: 32661,
        critical: 1207,
        recovered: 60615,
        '1M_pop': '1341',
        total: 93794,
      },
      deaths: {
        new: '+32',
        '1M_pop': '7',
        total: 518,
      },
      tests: {
        '1M_pop': '116151',
        total: 8124896,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Gabon',
      population: 2270706,
      cases: {
        new: '+169',
        active: 3321,
        critical: 18,
        recovered: 20270,
        '1M_pop': '10452',
        total: 23734,
      },
      deaths: {
        new: null,
        '1M_pop': '63',
        total: 143,
      },
      tests: {
        '1M_pop': '336020',
        total: 763003,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Albania',
      population: 2875084,
      cases: {
        new: '+45',
        active: 8342,
        critical: 18,
        recovered: 121122,
        '1M_pop': '45873',
        total: 131890,
      },
      deaths: {
        new: '+3',
        '1M_pop': '844',
        total: 2426,
      },
      tests: {
        '1M_pop': '231707',
        total: 666176,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Bahamas',
      population: 396478,
      cases: {
        new: '+58',
        active: 841,
        critical: 5,
        recovered: 9967,
        '1M_pop': '27805',
        total: 11024,
      },
      deaths: {
        new: '+1',
        '1M_pop': '545',
        total: 216,
      },
      tests: {
        '1M_pop': '230908',
        total: 91550,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Mali',
      population: 20747749,
      cases: {
        new: '+16',
        active: 4529,
        critical: null,
        recovered: 9113,
        '1M_pop': '682',
        total: 14149,
      },
      deaths: {
        new: '+1',
        '1M_pop': '24',
        total: 507,
      },
      tests: {
        '1M_pop': '13509',
        total: 280272,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'South-America',
      country: 'French-Guiana',
      population: 305306,
      cases: {
        new: '+229',
        active: 11003,
        critical: 24,
        recovered: 9995,
        '1M_pop': '69131',
        total: 21106,
      },
      deaths: {
        new: '+2',
        '1M_pop': '354',
        total: 108,
      },
      tests: {
        '1M_pop': '759897',
        total: 232001,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Tajikistan',
      population: 9717644,
      cases: {
        new: null,
        active: 0,
        critical: null,
        recovered: 13218,
        '1M_pop': '1369',
        total: 13308,
      },
      deaths: {
        new: null,
        '1M_pop': '9',
        total: 90,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Singapore',
      population: 5889959,
      cases: {
        new: '+34',
        active: 393,
        critical: 3,
        recovered: 61029,
        '1M_pop': '10434',
        total: 61453,
      },
      deaths: {
        new: null,
        '1M_pop': '5',
        total: 31,
      },
      tests: {
        '1M_pop': '1772814',
        total: 10441802,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Burkina-Faso',
      population: 21393410,
      cases: {
        new: '+3',
        active: 39,
        critical: null,
        recovered: 13184,
        '1M_pop': '626',
        total: 13387,
      },
      deaths: {
        new: null,
        '1M_pop': '8',
        total: 164,
      },
      tests: {
        '1M_pop': '8546',
        total: 182822,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Haiti',
      population: 11522045,
      cases: {
        new: '+41',
        active: 602,
        critical: null,
        recovered: 12397,
        '1M_pop': '1152',
        total: 13268,
      },
      deaths: {
        new: '+3',
        '1M_pop': '23',
        total: 269,
      },
      tests: {
        '1M_pop': '5215',
        total: 60085,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Belize',
      population: 403822,
      cases: {
        new: '+3',
        active: 64,
        critical: 1,
        recovered: 12320,
        '1M_pop': '31467',
        total: 12707,
      },
      deaths: {
        new: null,
        '1M_pop': '800',
        total: 323,
      },
      tests: {
        '1M_pop': '305333',
        total: 123300,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Kenya',
      population: 54786136,
      cases: {
        new: '+334',
        active: 48504,
        critical: 117,
        recovered: 113248,
        '1M_pop': '3007',
        total: 164720,
      },
      deaths: {
        new: '+18',
        '1M_pop': '54',
        total: 2968,
      },
      tests: {
        '1M_pop': '31641',
        total: 1733500,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Cambodia',
      population: 16917166,
      cases: {
        new: '+472',
        active: 12020,
        critical: null,
        recovered: 8539,
        '1M_pop': '1223',
        total: 20695,
      },
      deaths: {
        new: '+5',
        '1M_pop': '8',
        total: 136,
      },
      tests: {
        '1M_pop': '60177',
        total: 1018022,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Cuba',
      population: 11320648,
      cases: {
        new: '+1186',
        active: 6290,
        critical: 138,
        recovered: 113493,
        '1M_pop': '10650',
        total: 120561,
      },
      deaths: {
        new: '+10',
        '1M_pop': '69',
        total: 778,
      },
      tests: {
        '1M_pop': '346571',
        total: 3923413,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Montenegro',
      population: 628135,
      cases: {
        new: '+73',
        active: 1524,
        critical: 64,
        recovered: 95544,
        '1M_pop': '157003',
        total: 98619,
      },
      deaths: {
        new: '+3',
        '1M_pop': '2469',
        total: 1551,
      },
      tests: {
        '1M_pop': '621971',
        total: 390682,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Niger',
      population: 24953468,
      cases: {
        new: '+1',
        active: 204,
        critical: 2,
        recovered: 4929,
        '1M_pop': '213',
        total: 5325,
      },
      deaths: {
        new: null,
        '1M_pop': '8',
        total: 192,
      },
      tests: {
        '1M_pop': '4074',
        total: 101658,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Algeria',
      population: 44530480,
      cases: {
        new: '+207',
        active: 34531,
        critical: 28,
        recovered: 87003,
        '1M_pop': '2805',
        total: 124889,
      },
      deaths: {
        new: '+5',
        '1M_pop': '75',
        total: 3355,
      },
      tests: {
        '1M_pop': '5184',
        total: 230861,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Jamaica',
      population: 2972308,
      cases: {
        new: '+98',
        active: 23425,
        critical: 14,
        recovered: 22761,
        '1M_pop': '15819',
        total: 47020,
      },
      deaths: {
        new: '+14',
        '1M_pop': '281',
        total: 834,
      },
      tests: {
        '1M_pop': '117768',
        total: 350044,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Malawi',
      population: 19552902,
      cases: {
        new: '+17',
        active: 871,
        critical: 11,
        recovered: 32176,
        '1M_pop': '1749',
        total: 34200,
      },
      deaths: {
        new: null,
        '1M_pop': '59',
        total: 1153,
      },
      tests: {
        '1M_pop': '12173',
        total: 238017,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Eswatini',
      population: 1170499,
      cases: {
        new: '+5',
        active: 40,
        critical: 3,
        recovered: 17786,
        '1M_pop': '15803',
        total: 18497,
      },
      deaths: {
        new: null,
        '1M_pop': '573',
        total: 671,
      },
      tests: {
        '1M_pop': '167508',
        total: 196068,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Uganda',
      population: 46974338,
      cases: {
        new: '+91',
        active: 261,
        critical: 24,
        recovered: 41971,
        '1M_pop': '906',
        total: 42578,
      },
      deaths: {
        new: null,
        '1M_pop': '7',
        total: 346,
      },
      tests: {
        '1M_pop': '22409',
        total: 1052657,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'S-Korea',
      population: 51307257,
      cases: {
        new: '+715',
        active: 7836,
        critical: 160,
        recovered: 119906,
        '1M_pop': '2527',
        total: 129633,
      },
      deaths: {
        new: '+7',
        '1M_pop': '37',
        total: 1891,
      },
      tests: {
        '1M_pop': '180114',
        total: 9241153,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Oceania',
      country: 'New-Zealand',
      population: 5002100,
      cases: {
        new: '+1',
        active: 18,
        critical: null,
        recovered: 2600,
        '1M_pop': '529',
        total: 2644,
      },
      deaths: {
        new: null,
        '1M_pop': '5',
        total: 26,
      },
      tests: {
        '1M_pop': '413789',
        total: 2069812,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Myanmar',
      population: 54723983,
      cases: {
        new: '+7',
        active: 7723,
        critical: null,
        recovered: 132069,
        '1M_pop': '2613',
        total: 143004,
      },
      deaths: {
        new: '+1',
        '1M_pop': '59',
        total: 3212,
      },
      tests: {
        '1M_pop': '47463',
        total: 2597378,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Australia',
      population: 25754325,
      cases: {
        new: '+3',
        active: 179,
        critical: 1,
        recovered: 28861,
        '1M_pop': '1163',
        total: 29950,
      },
      deaths: {
        new: null,
        '1M_pop': '35',
        total: 910,
      },
      tests: {
        '1M_pop': '675025',
        total: 17384804,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Mongolia',
      population: 3323621,
      cases: {
        new: '+585',
        active: 9559,
        critical: 110,
        recovered: 37281,
        '1M_pop': '14151',
        total: 47033,
      },
      deaths: {
        new: '+9',
        '1M_pop': '58',
        total: 193,
      },
      tests: {
        '1M_pop': '844924',
        total: 2808208,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Namibia',
      population: 2580388,
      cases: {
        new: '+218',
        active: 2253,
        critical: 60,
        recovered: 47776,
        '1M_pop': '19661',
        total: 50733,
      },
      deaths: {
        new: '+2',
        '1M_pop': '273',
        total: 704,
      },
      tests: {
        '1M_pop': '157377',
        total: 406093,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'South-Sudan',
      population: 11305506,
      cases: {
        new: '+3',
        active: 64,
        critical: null,
        recovered: 10462,
        '1M_pop': '941',
        total: 10641,
      },
      deaths: {
        new: null,
        '1M_pop': '10',
        total: 115,
      },
      tests: {
        '1M_pop': '13847',
        total: 156542,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Kyrgyzstan',
      population: 6616606,
      cases: {
        new: '+329',
        active: 5246,
        critical: 85,
        recovered: 92718,
        '1M_pop': '15060',
        total: 99645,
      },
      deaths: {
        new: '+6',
        '1M_pop': '254',
        total: 1681,
      },
      tests: {
        '1M_pop': '157666',
        total: 1043213,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Uzbekistan',
      population: 33885934,
      cases: {
        new: '+359',
        active: 3858,
        critical: 23,
        recovered: 91301,
        '1M_pop': '2828',
        total: 95826,
      },
      deaths: {
        new: '+1',
        '1M_pop': '20',
        total: 667,
      },
      tests: {
        '1M_pop': '40663',
        total: 1377915,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Timor-Leste',
      population: 1339916,
      cases: {
        new: '+133',
        active: 1727,
        critical: null,
        recovered: 1894,
        '1M_pop': '2706',
        total: 3626,
      },
      deaths: {
        new: null,
        '1M_pop': '4',
        total: 5,
      },
      tests: {
        '1M_pop': '62960',
        total: 84361,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Zambia',
      population: 18824120,
      cases: {
        new: '+51',
        active: 367,
        critical: 42,
        recovered: 90635,
        '1M_pop': '4901',
        total: 92262,
      },
      deaths: {
        new: '+1',
        '1M_pop': '67',
        total: 1260,
      },
      tests: {
        '1M_pop': '77415',
        total: 1457277,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Maldives',
      population: 548714,
      cases: {
        new: '+1216',
        active: 13793,
        critical: 90,
        recovered: 27428,
        '1M_pop': '75281',
        total: 41308,
      },
      deaths: {
        new: null,
        '1M_pop': '159',
        total: 87,
      },
      tests: {
        '1M_pop': '1427217',
        total: 783134,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Yemen',
      population: 30388802,
      cases: {
        new: '+9',
        active: 2226,
        critical: 23,
        recovered: 3003,
        '1M_pop': '214',
        total: 6507,
      },
      deaths: {
        new: '+1',
        '1M_pop': '42',
        total: 1278,
      },
      tests: {
        '1M_pop': '1147',
        total: 34842,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Barbados',
      population: 287679,
      cases: {
        new: '+2',
        active: 5,
        critical: null,
        recovered: 3908,
        '1M_pop': '13762',
        total: 3959,
      },
      deaths: {
        new: null,
        '1M_pop': '160',
        total: 46,
      },
      tests: {
        '1M_pop': '560222',
        total: 161164,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Monaco',
      population: 39482,
      cases: {
        new: '+3',
        active: 30,
        critical: 1,
        recovered: 2428,
        '1M_pop': '63067',
        total: 2490,
      },
      deaths: {
        new: null,
        '1M_pop': '810',
        total: 32,
      },
      tests: {
        '1M_pop': '1315865',
        total: 51953,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Estonia',
      population: 1327305,
      cases: {
        new: '+300',
        active: 7570,
        critical: 36,
        recovered: 117582,
        '1M_pop': '95203',
        total: 126364,
      },
      deaths: {
        new: '+2',
        '1M_pop': '913',
        total: 1212,
      },
      tests: {
        '1M_pop': '1014279',
        total: 1346257,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Bermuda',
      population: 62079,
      cases: {
        new: '+6',
        active: 208,
        critical: 11,
        recovered: 2222,
        '1M_pop': '39643',
        total: 2461,
      },
      deaths: {
        new: null,
        '1M_pop': '499',
        total: 31,
      },
      tests: {
        '1M_pop': '4050548',
        total: 251454,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Aruba',
      population: 107157,
      cases: {
        new: '+12',
        active: 94,
        critical: 14,
        recovered: 10628,
        '1M_pop': '101029',
        total: 10826,
      },
      deaths: {
        new: null,
        '1M_pop': '971',
        total: 104,
      },
      tests: {
        '1M_pop': '1456134',
        total: 156035,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Iceland',
      population: 343145,
      cases: {
        new: '+4',
        active: 75,
        critical: 3,
        recovered: 6422,
        '1M_pop': '19018',
        total: 6526,
      },
      deaths: {
        new: null,
        '1M_pop': '85',
        total: 29,
      },
      tests: {
        '1M_pop': '1813723',
        total: 622370,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Sao-Tome-and-Principe',
      population: 222639,
      cases: {
        new: null,
        active: 15,
        critical: null,
        recovered: 2270,
        '1M_pop': '10420',
        total: 2320,
      },
      deaths: {
        new: null,
        '1M_pop': '157',
        total: 35,
      },
      tests: {
        '1M_pop': '58386',
        total: 12999,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Asia',
      country: 'Syria',
      population: 17864615,
      cases: {
        new: '+52',
        active: 2005,
        critical: null,
        recovered: 19908,
        '1M_pop': '1321',
        total: 23595,
      },
      deaths: {
        new: '+6',
        '1M_pop': '94',
        total: 1682,
      },
      tests: {
        '1M_pop': '5797',
        total: 103566,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Finland',
      population: 5548145,
      cases: {
        new: '+262',
        active: 42602,
        critical: 24,
        recovered: 46000,
        '1M_pop': '16137',
        total: 89532,
      },
      deaths: {
        new: null,
        '1M_pop': '168',
        total: 930,
      },
      tests: {
        '1M_pop': '850131',
        total: 4716650,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Papua-New-Guinea',
      population: 9092401,
      cases: {
        new: '+245',
        active: 2114,
        critical: null,
        recovered: 10995,
        '1M_pop': '1456',
        total: 13242,
      },
      deaths: {
        new: '+3',
        '1M_pop': '15',
        total: 133,
      },
      tests: {
        '1M_pop': '11512',
        total: 104674,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'North-America',
      country: 'Trinidad-and-Tobago',
      population: 1403396,
      cases: {
        new: '+397',
        active: 4814,
        critical: 15,
        recovered: 9744,
        '1M_pop': '10556',
        total: 14814,
      },
      deaths: {
        new: '+21',
        '1M_pop': '182',
        total: 256,
      },
      tests: {
        '1M_pop': '106798',
        total: 149880,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Africa',
      country: 'Lesotho',
      population: 2156871,
      cases: {
        new: '+4',
        active: 4031,
        critical: null,
        recovered: 6427,
        '1M_pop': '4997',
        total: 10778,
      },
      deaths: {
        new: '+1',
        '1M_pop': '148',
        total: 320,
      },
      tests: {
        '1M_pop': '40071',
        total: 86427,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:04+00:00',
    },
    {
      continent: 'Europe',
      country: 'Croatia',
      population: 4083381,
      cases: {
        new: '+1064',
        active: 7866,
        critical: 202,
        recovered: 332669,
        '1M_pop': '85262',
        total: 348158,
      },
      deaths: {
        new: '+34',
        '1M_pop': '1867',
        total: 7623,
      },
      tests: {
        '1M_pop': '466870',
        total: 1906407,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Bahrain',
      population: 1752013,
      cases: {
        new: '+1816',
        active: 15432,
        critical: 166,
        recovered: 179958,
        '1M_pop': '111931',
        total: 196105,
      },
      deaths: {
        new: '+10',
        '1M_pop': '408',
        total: 715,
      },
      tests: {
        '1M_pop': '2458855',
        total: 4307946,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Belgium',
      population: 11633100,
      cases: {
        new: '+3251',
        active: 101049,
        critical: 657,
        recovered: 897904,
        '1M_pop': '87989',
        total: 1023583,
      },
      deaths: {
        new: '+21',
        '1M_pop': '2117',
        total: 24630,
      },
      tests: {
        '1M_pop': '1128289',
        total: 13125499,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Oceania',
      country: 'Oceania',
      population: null,
      cases: {
        new: '+253',
        active: 2863,
        critical: 3,
        recovered: 61314,
        '1M_pop': null,
        total: 65399,
      },
      deaths: {
        new: '+4',
        '1M_pop': null,
        total: 1222,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Argentina',
      population: 45552720,
      cases: {
        new: '+26531',
        active: 281414,
        critical: 5420,
        recovered: 2891435,
        '1M_pop': '71173',
        total: 3242103,
      },
      deaths: {
        new: '+447',
        '1M_pop': '1520',
        total: 69254,
      },
      tests: {
        '1M_pop': '266554',
        total: 12142248,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Colombia',
      population: 51349445,
      cases: {
        new: '+19160',
        active: 113463,
        critical: 6582,
        recovered: 2874656,
        '1M_pop': '59745',
        total: 3067879,
      },
      deaths: {
        new: '+499',
        '1M_pop': '1553',
        total: 79760,
      },
      tests: {
        '1M_pop': '305980',
        total: 15711926,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Romania',
      population: 19126731,
      cases: {
        new: '+953',
        active: 20268,
        critical: 829,
        recovered: 1020194,
        '1M_pop': '55931',
        total: 1069770,
      },
      deaths: {
        new: '+75',
        '1M_pop': '1532',
        total: 29308,
      },
      tests: {
        '1M_pop': '449222',
        total: 8592149,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Africa',
      country: 'South-Africa',
      population: 59951541,
      cases: {
        new: '+3221',
        active: 29362,
        critical: 546,
        recovered: 1520878,
        '1M_pop': '26776',
        total: 1605252,
      },
      deaths: {
        new: '+44',
        '1M_pop': '918',
        total: 55012,
      },
      tests: {
        '1M_pop': '183665',
        total: 11010999,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Belarus',
      population: 9446640,
      cases: {
        new: '+837',
        active: 6765,
        critical: null,
        recovered: 362806,
        '1M_pop': '39405',
        total: 372242,
      },
      deaths: {
        new: '+10',
        '1M_pop': '283',
        total: 2671,
      },
      tests: {
        '1M_pop': '638972',
        total: 6036143,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Indonesia',
      population: 276011839,
      cases: {
        new: '+3448',
        active: 94857,
        critical: null,
        recovered: 1589079,
        '1M_pop': '6274',
        total: 1731652,
      },
      deaths: {
        new: '+99',
        '1M_pop': '173',
        total: 47716,
      },
      tests: {
        '1M_pop': '55834',
        total: 15410887,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Bangladesh',
      population: 166101183,
      cases: {
        new: '+1290',
        active: 46992,
        critical: 1149,
        recovered: 719619,
        '1M_pop': '4688',
        total: 778687,
      },
      deaths: {
        new: '+31',
        '1M_pop': '73',
        total: 12076,
      },
      tests: {
        '1M_pop': '34260',
        total: 5690693,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Portugal',
      population: 10171028,
      cases: {
        new: '+436',
        active: 21969,
        critical: 70,
        recovered: 801961,
        '1M_pop': '82679',
        total: 840929,
      },
      deaths: {
        new: '+1',
        '1M_pop': '1671',
        total: 16999,
      },
      tests: {
        '1M_pop': '1086697',
        total: 11052822,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Azerbaijan',
      population: 10217838,
      cases: {
        new: '+509',
        active: 12728,
        critical: null,
        recovered: 311198,
        '1M_pop': '32166',
        total: 328668,
      },
      deaths: {
        new: '+16',
        '1M_pop': '464',
        total: 4742,
      },
      tests: {
        '1M_pop': '328925',
        total: 3360900,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Austria',
      population: 9050676,
      cases: {
        new: '+933',
        active: 13195,
        critical: 316,
        recovered: 611254,
        '1M_pop': '70149',
        total: 634893,
      },
      deaths: {
        new: '+16',
        '1M_pop': '1154',
        total: 10444,
      },
      tests: {
        '1M_pop': '3955363',
        total: 35798712,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Malaysia',
      population: 32722476,
      cases: {
        new: '+4855',
        active: 41582,
        critical: 481,
        recovered: 414707,
        '1M_pop': '13999',
        total: 458077,
      },
      deaths: {
        new: '+27',
        '1M_pop': '55',
        total: 1788,
      },
      tests: {
        '1M_pop': '316024',
        total: 10341089,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Africa',
      country: 'Nigeria',
      population: 210512785,
      cases: {
        new: '+53',
        active: 7159,
        critical: 11,
        recovered: 156387,
        '1M_pop': '787',
        total: 165612,
      },
      deaths: {
        new: null,
        '1M_pop': '10',
        total: 2066,
      },
      tests: {
        '1M_pop': '9394',
        total: 1977479,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Iran',
      population: 84917119,
      cases: {
        new: '+14246',
        active: 465886,
        critical: 5481,
        recovered: 2179890,
        '1M_pop': '32055',
        total: 2722007,
      },
      deaths: {
        new: '+297',
        '1M_pop': '898',
        total: 76231,
      },
      tests: {
        '1M_pop': '207455',
        total: 17616446,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Philippines',
      population: 110834638,
      cases: {
        new: '+6385',
        active: 55260,
        critical: 750,
        recovered: 1050643,
        '1M_pop': '10148',
        total: 1124724,
      },
      deaths: {
        new: '+107',
        '1M_pop': '170',
        total: 18821,
      },
      tests: {
        '1M_pop': '112487',
        total: 12467422,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'Costa-Rica',
      population: 5134155,
      cases: {
        new: '+3039',
        active: 57307,
        critical: 482,
        recovered: 219105,
        '1M_pop': '54522',
        total: 279926,
      },
      deaths: {
        new: '+32',
        '1M_pop': '684',
        total: 3514,
      },
      tests: {
        '1M_pop': '210947',
        total: 1083035,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Nepal',
      population: 29586485,
      cases: {
        new: '+8842',
        active: 105207,
        critical: null,
        recovered: 321518,
        '1M_pop': '14574',
        total: 431191,
      },
      deaths: {
        new: '+214',
        '1M_pop': '151',
        total: 4466,
      },
      tests: {
        '1M_pop': '91682',
        total: 2712562,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'Guatemala',
      population: 18199887,
      cases: {
        new: '+1105',
        active: 12634,
        critical: 5,
        recovered: 218321,
        '1M_pop': '13120',
        total: 238787,
      },
      deaths: {
        new: '+17',
        '1M_pop': '430',
        total: 7832,
      },
      tests: {
        '1M_pop': '72638',
        total: 1322009,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Bosnia-and-Herzegovina',
      population: 3263177,
      cases: {
        new: '+207',
        active: 24900,
        critical: null,
        recovered: 168145,
        '1M_pop': '61904',
        total: 202003,
      },
      deaths: {
        new: '+15',
        '1M_pop': '2745',
        total: 8958,
      },
      tests: {
        '1M_pop': '286916',
        total: 936259,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Africa',
      country: 'Libya',
      population: 6951592,
      cases: {
        new: '+234',
        active: 10465,
        critical: null,
        recovered: 167629,
        '1M_pop': '26063',
        total: 181179,
      },
      deaths: {
        new: '+3',
        '1M_pop': '444',
        total: 3085,
      },
      tests: {
        '1M_pop': '141195',
        total: 981532,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Asia',
      population: null,
      cases: {
        new: '+426395',
        active: 5310227,
        critical: 32320,
        recovered: 39789416,
        '1M_pop': null,
        total: 45691484,
      },
      deaths: {
        new: '+5376',
        '1M_pop': null,
        total: 591841,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Europe',
      population: null,
      cases: {
        new: '+98297',
        active: 3413847,
        critical: 24188,
        recovered: 41405843,
        '1M_pop': null,
        total: 45863910,
      },
      deaths: {
        new: '+2270',
        '1M_pop': null,
        total: 1044220,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'UAE',
      population: 9993152,
      cases: {
        new: '+1512',
        active: 18179,
        critical: null,
        recovered: 522356,
        '1M_pop': '54253',
        total: 542158,
      },
      deaths: {
        new: '+4',
        '1M_pop': '162',
        total: 1623,
      },
      tests: {
        '1M_pop': '4664538',
        total: 46613437,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Qatar',
      population: 2807805,
      cases: {
        new: '+299',
        active: 6741,
        critical: 225,
        recovered: 205160,
        '1M_pop': '75654',
        total: 212423,
      },
      deaths: {
        new: '+3',
        '1M_pop': '186',
        total: 522,
      },
      tests: {
        '1M_pop': '698655',
        total: 1961687,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Brazil',
      population: 213860730,
      cases: {
        new: '+75141',
        active: 1026902,
        critical: 8318,
        recovered: 13979329,
        '1M_pop': '72182',
        total: 15436827,
      },
      deaths: {
        new: '+2340',
        '1M_pop': '2013',
        total: 430596,
      },
      tests: {
        '1M_pop': '219630',
        total: 46970130,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'Canada',
      population: 38027004,
      cases: {
        new: '+6644',
        active: 75475,
        critical: 1327,
        recovered: 1212114,
        '1M_pop': '34513',
        total: 1312414,
      },
      deaths: {
        new: '+59',
        '1M_pop': '653',
        total: 24825,
      },
      tests: {
        '1M_pop': '867834',
        total: 33001128,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Serbia',
      population: 8706962,
      cases: {
        new: '+901',
        active: 26408,
        critical: 98,
        recovered: 671361,
        '1M_pop': '80901',
        total: 704398,
      },
      deaths: {
        new: '+18',
        '1M_pop': '761',
        total: 6629,
      },
      tests: {
        '1M_pop': '456767',
        total: 3977053,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Saudi-Arabia',
      population: 35279497,
      cases: {
        new: '+1116',
        active: 9244,
        critical: 1344,
        recovered: 414139,
        '1M_pop': '12203',
        total: 430505,
      },
      deaths: {
        new: '+11',
        '1M_pop': '202',
        total: 7122,
      },
      tests: {
        '1M_pop': '504893',
        total: 17812376,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Palestine',
      population: 5202977,
      cases: {
        new: '+493',
        active: 10978,
        critical: 78,
        recovered: 288891,
        '1M_pop': '58288',
        total: 303270,
      },
      deaths: {
        new: '+8',
        '1M_pop': '654',
        total: 3401,
      },
      tests: {
        '1M_pop': '343013',
        total: 1784690,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'Panama',
      population: 4373096,
      cases: {
        new: '+525',
        active: 4809,
        critical: 105,
        recovered: 358358,
        '1M_pop': '84484',
        total: 369455,
      },
      deaths: {
        new: '+3',
        '1M_pop': '1438',
        total: 6288,
      },
      tests: {
        '1M_pop': '571020',
        total: 2497125,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Paraguay',
      population: 7207867,
      cases: {
        new: '+2568',
        active: 45670,
        critical: 592,
        recovered: 254360,
        '1M_pop': '42656',
        total: 307457,
      },
      deaths: {
        new: '+69',
        '1M_pop': '1030',
        total: 7427,
      },
      tests: {
        '1M_pop': '163169',
        total: 1176104,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Netherlands',
      population: 17167573,
      cases: {
        new: '+6013',
        active: 219728,
        critical: 716,
        recovered: 1346626,
        '1M_pop': '92253',
        total: 1583767,
      },
      deaths: {
        new: '+14',
        '1M_pop': '1014',
        total: 17413,
      },
      tests: {
        '1M_pop': '767243',
        total: 13171698,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Africa',
      country: 'Egypt',
      population: 103990069,
      cases: {
        new: '+1193',
        active: 48709,
        critical: 90,
        recovered: 179261,
        '1M_pop': '2328',
        total: 242120,
      },
      deaths: {
        new: '+59',
        '1M_pop': '136',
        total: 14150,
      },
      tests: {
        '1M_pop': '26111',
        total: 2715258,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Iraq',
      population: 40995731,
      cases: {
        new: '+4512',
        active: 87582,
        critical: 471,
        recovered: 1028627,
        '1M_pop': '27615',
        total: 1132092,
      },
      deaths: {
        new: '+28',
        '1M_pop': '387',
        total: 15883,
      },
      tests: {
        '1M_pop': '239488',
        total: 9817969,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Uruguay',
      population: 3484110,
      cases: {
        new: '+3799',
        active: 27216,
        critical: 511,
        recovered: 201367,
        '1M_pop': '66560',
        total: 231901,
      },
      deaths: {
        new: '+66',
        '1M_pop': '952',
        total: 3318,
      },
      tests: {
        '1M_pop': '564225',
        total: 1965821,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Japan',
      population: 126142014,
      cases: {
        new: '+7058',
        active: 73551,
        critical: 1214,
        recovered: 573913,
        '1M_pop': '5221',
        total: 658629,
      },
      deaths: {
        new: '+101',
        '1M_pop': '89',
        total: 11165,
      },
      tests: {
        '1M_pop': '101391',
        total: 12789711,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Bolivia',
      population: 11809861,
      cases: {
        new: '+2290',
        active: 45011,
        critical: 158,
        recovered: 266512,
        '1M_pop': '27508',
        total: 324868,
      },
      deaths: {
        new: '+37',
        '1M_pop': '1130',
        total: 13345,
      },
      tests: {
        '1M_pop': '108038',
        total: 1275910,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'North-America',
      population: null,
      cases: {
        new: '+56161',
        active: 7009699,
        critical: 16445,
        recovered: 31181303,
        '1M_pop': null,
        total: 39066823,
      },
      deaths: {
        new: '+1240',
        '1M_pop': null,
        total: 875821,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Denmark',
      population: 5809788,
      cases: {
        new: '+1355',
        active: 12332,
        critical: 36,
        recovered: 248683,
        '1M_pop': '45357',
        total: 263514,
      },
      deaths: {
        new: null,
        '1M_pop': '430',
        total: 2499,
      },
      tests: {
        '1M_pop': '7774914',
        total: 45170604,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'South-America',
      population: null,
      cases: {
        new: '+144656',
        active: 1733868,
        critical: 28210,
        recovered: 23962280,
        '1M_pop': null,
        total: 26415124,
      },
      deaths: {
        new: '+4107',
        '1M_pop': null,
        total: 718976,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Africa',
      country: 'Africa',
      population: null,
      cases: {
        new: '+8367',
        active: 339205,
        critical: 3079,
        recovered: 4242427,
        '1M_pop': null,
        total: 4707565,
      },
      deaths: {
        new: '+229',
        '1M_pop': null,
        total: 125933,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Oman',
      population: 5217765,
      cases: {
        new: null,
        active: 14174,
        critical: 255,
        recovered: 186391,
        '1M_pop': '38851',
        total: 202713,
      },
      deaths: {
        new: null,
        '1M_pop': '412',
        total: 2148,
      },
      tests: {
        '1M_pop': '297062',
        total: 1550000,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'All',
      country: 'All',
      population: null,
      cases: {
        new: '+734129',
        active: 17809709,
        critical: 104245,
        recovered: 140643289,
        '1M_pop': '20759',
        total: 161811026,
      },
      deaths: {
        new: '+13226',
        '1M_pop': '430.8',
        total: 3358028,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'USA',
      population: 332674970,
      cases: {
        new: '+38282',
        active: 6359879,
        critical: 8626,
        recovered: 26666157,
        '1M_pop': '101073',
        total: 33624554,
      },
      deaths: {
        new: '+740',
        '1M_pop': '1799',
        total: 598518,
      },
      tests: {
        '1M_pop': '1390801',
        total: 462684783,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Peru',
      population: 33366534,
      cases: {
        new: '+7677',
        active: 87335,
        critical: 2681,
        recovered: 1720665,
        '1M_pop': '56144',
        total: 1873316,
      },
      deaths: {
        new: '+418',
        '1M_pop': '1958',
        total: 65316,
      },
      tests: {
        '1M_pop': '356690',
        total: 11901521,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Sweden',
      population: 10153515,
      cases: {
        new: null,
        active: 142595,
        critical: 346,
        recovered: 871072,
        '1M_pop': '101239',
        total: 1027934,
      },
      deaths: {
        new: null,
        '1M_pop': '1405',
        total: 14267,
      },
      tests: {
        '1M_pop': '866536',
        total: 8798383,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'UK',
      population: 68193314,
      cases: {
        new: '+2657',
        active: 58682,
        critical: 136,
        recovered: 4258298,
        '1M_pop': '65177',
        total: 4444631,
      },
      deaths: {
        new: '+11',
        '1M_pop': '1872',
        total: 127651,
      },
      tests: {
        '1M_pop': '2478354',
        total: 169007195,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Italy',
      population: 60385062,
      cases: {
        new: '+8085',
        active: 346008,
        critical: 1893,
        recovered: 3669407,
        '1M_pop': '68546',
        total: 4139160,
      },
      deaths: {
        new: '+201',
        '1M_pop': '2049',
        total: 123745,
      },
      tests: {
        '1M_pop': '1028520',
        total: 62107222,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Jordan',
      population: 10290291,
      cases: {
        new: '+418',
        active: 3141,
        critical: 729,
        recovered: 710410,
        '1M_pop': '70236',
        total: 722754,
      },
      deaths: {
        new: '+23',
        '1M_pop': '894',
        total: 9203,
      },
      tests: {
        '1M_pop': '670892',
        total: 6903678,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Greece',
      population: 10379054,
      cases: {
        new: '+2158',
        active: 26554,
        critical: 683,
        recovered: 333892,
        '1M_pop': '35814',
        total: 371712,
      },
      deaths: {
        new: '+55',
        '1M_pop': '1085',
        total: 11266,
      },
      tests: {
        '1M_pop': '838966',
        total: 8707677,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Lebanon',
      population: 6799033,
      cases: {
        new: '+580',
        active: 35129,
        critical: 333,
        recovered: 492270,
        '1M_pop': '78683',
        total: 534968,
      },
      deaths: {
        new: '+20',
        '1M_pop': '1113',
        total: 7569,
      },
      tests: {
        '1M_pop': '612331',
        total: 4163257,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'Dominican-Republic',
      population: 10941511,
      cases: {
        new: '+822',
        active: 38134,
        critical: 242,
        recovered: 232625,
        '1M_pop': '25071',
        total: 274319,
      },
      deaths: {
        new: '+6',
        '1M_pop': '325',
        total: 3560,
      },
      tests: {
        '1M_pop': '133590',
        total: 1461677,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Moldova',
      population: 4025866,
      cases: {
        new: '+213',
        active: 3093,
        critical: 171,
        recovered: 244298,
        '1M_pop': '62940',
        total: 253386,
      },
      deaths: {
        new: '+14',
        '1M_pop': '1489',
        total: 5995,
      },
      tests: {
        '1M_pop': '256812',
        total: 1033892,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Africa',
      country: 'Morocco',
      population: 37286768,
      cases: {
        new: '+238',
        active: 3374,
        critical: 96,
        recovered: 502205,
        '1M_pop': '13803',
        total: 514670,
      },
      deaths: {
        new: '+3',
        '1M_pop': '244',
        total: 9091,
      },
      tests: {
        '1M_pop': '170762',
        total: 6367171,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'Mexico',
      population: 130098135,
      cases: {
        new: '+3090',
        active: 258657,
        critical: 4798,
        recovered: 1893236,
        '1M_pop': '18228',
        total: 2371483,
      },
      deaths: {
        new: '+267',
        '1M_pop': '1688',
        total: 219590,
      },
      tests: {
        '1M_pop': '52206',
        total: 6791913,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Ireland',
      population: 4985227,
      cases: {
        new: '+437',
        active: 12766,
        critical: 36,
        recovered: 236747,
        '1M_pop': '51041',
        total: 254450,
      },
      deaths: {
        new: null,
        '1M_pop': '990',
        total: 4937,
      },
      tests: {
        '1M_pop': '948711',
        total: 4729538,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Turkey',
      population: 85119750,
      cases: {
        new: '+11534',
        active: 183174,
        critical: 2765,
        recovered: 4856763,
        '1M_pop': '59728',
        total: 5083996,
      },
      deaths: {
        new: '+238',
        '1M_pop': '518',
        total: 44059,
      },
      tests: {
        '1M_pop': '590462',
        total: 50259943,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Spain',
      population: 46770406,
      cases: {
        new: '+5701',
        active: 221831,
        critical: 1931,
        recovered: 3297340,
        '1M_pop': '76939',
        total: 3598452,
      },
      deaths: {
        new: '+73',
        '1M_pop': '1695',
        total: 79281,
      },
      tests: {
        '1M_pop': '1009465',
        total: 47213067,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Kazakhstan',
      population: 18969833,
      cases: {
        new: '+2283',
        active: 39143,
        critical: 221,
        recovered: 307464,
        '1M_pop': '18482',
        total: 350591,
      },
      deaths: {
        new: '+20',
        '1M_pop': '210',
        total: 3984,
      },
      tests: {
        '1M_pop': '563585',
        total: 10691113,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Venezuela',
      population: 28366348,
      cases: {
        new: null,
        active: 14445,
        critical: 316,
        recovered: 194166,
        '1M_pop': '7437',
        total: 210948,
      },
      deaths: {
        new: null,
        '1M_pop': '82',
        total: 2337,
      },
      tests: {
        '1M_pop': '118415',
        total: 3359014,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'France',
      population: 65398090,
      cases: {
        new: '+19461',
        active: 734669,
        critical: 4442,
        recovered: 4999210,
        '1M_pop': '89317',
        total: 5841129,
      },
      deaths: {
        new: '+131',
        '1M_pop': '1640',
        total: 107250,
      },
      tests: {
        '1M_pop': '1226562',
        total: 80214807,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Chile',
      population: 19257458,
      cases: {
        new: '+6153',
        active: 34905,
        critical: 2976,
        recovered: 1204176,
        '1M_pop': '65772',
        total: 1266601,
      },
      deaths: {
        new: '+136',
        '1M_pop': '1429',
        total: 27520,
      },
      tests: {
        '1M_pop': '714024',
        total: 13750280,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Israel',
      population: 9197590,
      cases: {
        new: '+29',
        active: 792,
        critical: 67,
        recovered: 831888,
        '1M_pop': '91226',
        total: 839059,
      },
      deaths: {
        new: null,
        '1M_pop': '694',
        total: 6379,
      },
      tests: {
        '1M_pop': '1572822',
        total: 14466175,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'South-America',
      country: 'Ecuador',
      population: 17873167,
      cases: {
        new: '+1151',
        active: 43463,
        critical: 594,
        recovered: 342878,
        '1M_pop': '22703',
        total: 405783,
      },
      deaths: {
        new: '+93',
        '1M_pop': '1088',
        total: 19442,
      },
      tests: {
        '1M_pop': '75823',
        total: 1355202,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'North-America',
      country: 'Honduras',
      population: 10039879,
      cases: {
        new: '+874',
        active: 134952,
        critical: 535,
        recovered: 82187,
        '1M_pop': '22211',
        total: 222992,
      },
      deaths: {
        new: '+64',
        '1M_pop': '583',
        total: 5853,
      },
      tests: {
        '1M_pop': '66149',
        total: 664126,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Kuwait',
      population: 4324826,
      cases: {
        new: '+1059',
        active: 13015,
        critical: 197,
        recovered: 274554,
        '1M_pop': '66880',
        total: 289243,
      },
      deaths: {
        new: '+5',
        '1M_pop': '387',
        total: 1674,
      },
      tests: {
        '1M_pop': '563937',
        total: 2438929,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Pakistan',
      population: 224570836,
      cases: {
        new: '+3265',
        active: 75052,
        critical: 4567,
        recovered: 776315,
        '1M_pop': '3877',
        total: 870703,
      },
      deaths: {
        new: '+126',
        '1M_pop': '86',
        total: 19336,
      },
      tests: {
        '1M_pop': '54994',
        total: 12349976,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Africa',
      country: 'Ethiopia',
      population: 117401048,
      cases: {
        new: '+593',
        active: 45275,
        critical: 653,
        recovered: 215734,
        '1M_pop': '2257',
        total: 264960,
      },
      deaths: {
        new: '+13',
        '1M_pop': '34',
        total: 3951,
      },
      tests: {
        '1M_pop': '22490',
        total: 2640295,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Ukraine',
      population: 43506626,
      cases: {
        new: '+6813',
        active: 271910,
        critical: 177,
        recovered: 1816643,
        '1M_pop': '49093',
        total: 2135886,
      },
      deaths: {
        new: '+346',
        '1M_pop': '1088',
        total: 47333,
      },
      tests: {
        '1M_pop': '223438',
        total: 9721036,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Africa',
      country: 'Tunisia',
      population: 11925011,
      cases: {
        new: '+720',
        active: 28376,
        critical: 445,
        recovered: 284754,
        '1M_pop': '27239',
        total: 324823,
      },
      deaths: {
        new: '+56',
        '1M_pop': '981',
        total: 11693,
      },
      tests: {
        '1M_pop': '115617',
        total: 1378729,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Czechia',
      population: 10726119,
      cases: {
        new: '+1261',
        active: 32665,
        critical: 337,
        recovered: 1587457,
        '1M_pop': '153825',
        total: 1649947,
      },
      deaths: {
        new: '+13',
        '1M_pop': '2781',
        total: 29825,
      },
      tests: {
        '1M_pop': '1936187',
        total: 20767772,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Hungary',
      population: 9639165,
      cases: {
        new: '+1416',
        active: 165769,
        critical: 354,
        recovered: 600461,
        '1M_pop': '82497',
        total: 795200,
      },
      deaths: {
        new: '+82',
        '1M_pop': '3005',
        total: 28970,
      },
      tests: {
        '1M_pop': '579763',
        total: 5588435,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Armenia',
      population: 2968021,
      cases: {
        new: '+267',
        active: 9174,
        critical: null,
        recovered: 206752,
        '1M_pop': '74197',
        total: 220217,
      },
      deaths: {
        new: '+19',
        '1M_pop': '1446',
        total: 4291,
      },
      tests: {
        '1M_pop': '346013',
        total: 1026974,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'Georgia',
      population: 3982555,
      cases: {
        new: '+776',
        active: 15798,
        critical: null,
        recovered: 306264,
        '1M_pop': '81968',
        total: 326441,
      },
      deaths: {
        new: '+16',
        '1M_pop': '1100',
        total: 4379,
      },
      tests: {
        '1M_pop': '1123189',
        total: 4473163,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Lithuania',
      population: 2689386,
      cases: {
        new: '+1136',
        active: 21822,
        critical: 158,
        recovered: 236442,
        '1M_pop': '97545',
        total: 262335,
      },
      deaths: {
        new: '+7',
        '1M_pop': '1514',
        total: 4071,
      },
      tests: {
        '1M_pop': '1133654',
        total: 3048832,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Asia',
      country: 'India',
      population: 1391679242,
      cases: {
        new: '+338019',
        active: 3712328,
        critical: 8944,
        recovered: 20066284,
        '1M_pop': '17275',
        total: 24040851,
      },
      deaths: {
        new: '+3888',
        '1M_pop': '188',
        total: 262239,
      },
      tests: {
        '1M_pop': '222356',
        total: 309448585,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Germany',
      population: 84014947,
      cases: {
        new: '+17496',
        active: 249068,
        critical: 5049,
        recovered: 3240300,
        '1M_pop': '42560',
        total: 3575644,
      },
      deaths: {
        new: '+267',
        '1M_pop': '1027',
        total: 86276,
      },
      tests: {
        '1M_pop': '691469',
        total: 58093759,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Russia',
      population: 145988463,
      cases: {
        new: '+8380',
        active: 270838,
        critical: 2300,
        recovered: 4527878,
        '1M_pop': '33656',
        total: 4913439,
      },
      deaths: {
        new: '+392',
        '1M_pop': '786',
        total: 114723,
      },
      tests: {
        '1M_pop': '906921',
        total: 132400000,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Switzerland',
      population: 8709128,
      cases: {
        new: null,
        active: 54011,
        critical: 190,
        recovered: 612471,
        '1M_pop': '77759',
        total: 677210,
      },
      deaths: {
        new: '+3',
        '1M_pop': '1232',
        total: 10728,
      },
      tests: {
        '1M_pop': '828616',
        total: 7216520,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Poland',
      population: 37810825,
      cases: {
        new: '+3730',
        active: 189990,
        critical: 1772,
        recovered: 2584751,
        '1M_pop': '75263',
        total: 2845762,
      },
      deaths: {
        new: '+342',
        '1M_pop': '1878',
        total: 71021,
      },
      tests: {
        '1M_pop': '397172',
        total: 15017415,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Slovenia',
      population: 2079185,
      cases: {
        new: '+597',
        active: 8632,
        critical: 124,
        recovered: 235106,
        '1M_pop': '119300',
        total: 248046,
      },
      deaths: {
        new: '+4',
        '1M_pop': '2072',
        total: 4308,
      },
      tests: {
        '1M_pop': '586832',
        total: 1220132,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Slovakia',
      population: 5461924,
      cases: {
        new: '+328',
        active: 5085,
        critical: 225,
        recovered: 369648,
        '1M_pop': '70830',
        total: 386868,
      },
      deaths: {
        new: '+39',
        '1M_pop': '2222',
        total: 12135,
      },
      tests: {
        '1M_pop': '502727',
        total: 2745856,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: 'Europe',
      country: 'Bulgaria',
      population: 6903209,
      cases: {
        new: '+506',
        active: 36124,
        critical: 516,
        recovered: 360002,
        '1M_pop': '59874',
        total: 413320,
      },
      deaths: {
        new: '+44',
        '1M_pop': '2491',
        total: 17194,
      },
      tests: {
        '1M_pop': '382793',
        total: 2642503,
      },
      day: '2021-05-14',
      time: '2021-05-14T00:00:03+00:00',
    },
    {
      continent: null,
      country: 'MS-Zaandam-',
      population: null,
      cases: {
        new: '+7',
        active: 7,
        critical: null,
        recovered: null,
        '1M_pop': null,
        total: 9,
      },
      deaths: {
        new: '+2',
        '1M_pop': null,
        total: 2,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2020-04-09',
      time: '2020-04-09T15:45:06+00:00',
    },
    {
      continent: null,
      country: 'Diamond-Princess-',
      population: null,
      cases: {
        new: null,
        active: 82,
        critical: 10,
        recovered: 619,
        '1M_pop': null,
        total: 712,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: 11,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2020-04-09',
      time: '2020-04-09T15:45:06+00:00',
    },
    {
      continent: null,
      country: 'US-Virgin-Islands',
      population: null,
      cases: {
        new: '+11',
        active: 17,
        critical: null,
        recovered: null,
        '1M_pop': '163',
        total: 17,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: null,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2020-03-25',
      time: '2020-03-25T06:00:07+00:00',
    },
    {
      continent: null,
      country: 'Puerto-Rico',
      population: null,
      cases: {
        new: '+8',
        active: 36,
        critical: null,
        recovered: 1,
        '1M_pop': '14',
        total: 39,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: 2,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2020-03-25',
      time: '2020-03-25T06:00:07+00:00',
    },
    {
      continent: null,
      country: 'Guam',
      population: null,
      cases: {
        new: '+3',
        active: 31,
        critical: null,
        recovered: null,
        '1M_pop': '190',
        total: 32,
      },
      deaths: {
        new: null,
        '1M_pop': null,
        total: 1,
      },
      tests: {
        '1M_pop': null,
        total: null,
      },
      day: '2020-03-25',
      time: '2020-03-25T06:00:07+00:00',
    },
  ],
};
