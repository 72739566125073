const STORE_KEYS = {
  ACCEPTED_COOKIES: 'acceptedCookies',
  ACCOUNT: 'account',
  ADDRESS: 'address',
  ANBU_BLOCKCHAIN: 'anbuBlockchain',
  ANBU_COIN: 'anbuCoin',
  ASSETS: 'assets',
  BALANCE: 'balance',
  BLOCK_TRANSACTION_LIMIT: 'blockTransactionLimit',
  BLOG: 'blog',
  BUSINESS_CARD: 'businessCard',
  BUTTON_TEXT: 'buttonText',
  CANDLE_MONKEYS: 'candleMonkeys',
  CHAIN: 'chain',
  COMPANY: 'company',
  CONSOLE_OUTPUTS: 'consoleOutputs',
  CONTENT: 'content',
  CONTROL_PANEL: 'controlPanel',
  CORONAVIRUS: 'coronavirus',
  COUNTRIES: 'countries',
  DELAY: 'delay',
  DIFFICULTY: 'difficulty',
  HISTORY: 'history',
  IS_DARK_MODE: 'isDarkMode',
  IS_INTERACTIVE: 'isInteractive',
  IS_LOADING: 'isLoading',
  IS_ON_MOBILE: 'isOnMobile',
  LANGUAGE: 'language',
  LAST_UPDATED: 'lastUpdated',
  LATEST_TOTALS: 'latestTotals',
  LAYOUT_DEFAULTS: 'layoutDefaults',
  IMAGES: 'images',
  MAP_COUNTRIES: 'mapCountries',
  MESSAGE: 'message',
  MINING_REWARD: 'miningReward',
  NOTIFICATION: 'notification',
  ON_CLOSE: 'onClose',
  PARTICLE_CANVAS: 'particleCanvas',
  POSTS: 'posts',
  RESET: 'reset',
  SELECTED_COUNTRY: 'selectedCountry',
  SITE_SETTINGS: 'siteSettings',
  SETTINGS: 'settings',
  SEVERITY: 'severity',
  SHOW: 'show',
  SPLASH_LOGO: 'splashLogo',
  STATISTICS: 'statistics',
  STOCK_DATA: 'stockData',
  TAGS: 'tags',
  TRANSACTIONS_QUEUE: 'transactionsQueue',
  TUTORIAL_COMPLETED: 'tutorialCompleted',
  TUTORIAL_MAX_STEP: 'tutorialMaxStep',
  TUTORIAL_OUTPUTS: 'tutorialOutputs',
  TUTORIAL_SKIPPED: 'tutorialSkipped',
  TUTORIAL_STEP: 'tutorialStep',
  VIEWED_INTRO: 'viewedIntro',
  WIDGET_DEFAULTS: 'widgetDefaults',
};

export default STORE_KEYS;
