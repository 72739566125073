export default {
  ABOUT: false,
  ANBU_BLOCKCHAIN: false,
  BLOG: false,
  BLOG_POST: false,
  CANDLE_MONKEYS: false,
  CORONAVIRUS: false,
  NAVIGATION_MENU: false,
  PHOTOGRAPHY: false,
  POWERED_BY_SCROLL: false,
  PRIVACY_POLICY: false,
  REPORT_BUG: false,
  SIMULATE: true,
  STYLE_GUIDE: false,
};
