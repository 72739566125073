import { sampleIntradayPrices } from './staticIntraday';

export default {
  advancedStats: {
    companyName: 'Apple Inc.',
    marketcap: 760334287200,
    week52high: 156.65,
    week52low: 93.63,
    week52change: 58.801903,
    sharesOutstanding: 5213840000,
    float: 5203997571,
    avg10Volume: 2774000,
    avg30Volume: 12774000,
    day200MovingAvg: 140.60541,
    day50MovingAvg: 156.49678,
    employees: 120000,
    ttmEPS: 16.5,
    ttmDividendRate: 2.25,
    dividendYield: 0.021,
    nextDividendDate: '2019-03-01',
    exDividendDate: '2019-02-08',
    nextEarningsDate: '2019-01-01',
    peRatio: 14,
    maxChangePercent: 153.021,
    year5ChangePercent: 0.5902546932200027,
    year2ChangePercent: 0.3777449874142869,
    year1ChangePercent: 0.39751716851558366,
    ytdChangePercent: -0.36659492036160124,
    month6ChangePercent: 0.12208398133748043,
    month3ChangePercent: 0.08466584665846649,
    month1ChangePercent: 0.009668596145283263,
    day30ChangePercent: -0.002762605699968781,
    day5ChangePercent: -0.005762605699968781,
    beta: 1.4661365583766115,
    totalCash: 66301000000,
    currentDebt: 20748000000,
    revenue: 265809000000,
    grossProfit: 101983000000,
    totalRevenue: 265809000000,
    EBITDA: 80342000000,
    revenuePerShare: 0.02,
    revenuePerEmployee: 2013704.55,
    debtToEquity: 1.07,
    profitMargin: 22.396157,
    enterpriseValue: 1022460690000,
    enterpriseValueToRevenue: 3.85,
    priceToSales: 3.49,
    priceToBook: 8.805916432564608,
    forwardPERatio: 18.14,
    pegRatio: 2.19,
    peHigh: 22.61,
    peLow: 11.98,
    week52highDate: '2019-11-19',
    week52lowDate: '2019-01-03',
    putCallRatio: 0.7611902044412406,
  },
  analystRecommendations: [
    {
      ratingBuy: 23,
      ratingOverweight: 3,
      ratingHold: 12,
      ratingUnderweight: 1,
      ratingSell: 3,
      ratingNone: 0,
      ratingScaleMark: 1.53838,
      consensusStartDate: 1617328445392,
      consensusEndDate: null,
      corporateActionsAppliedDate: 1599205168086,
    },
    {
      ratingBuy: 22,
      ratingOverweight: 3,
      ratingHold: 11,
      ratingUnderweight: 1,
      ratingSell: 3,
      ratingNone: 0,
      ratingScaleMark: 1.5,
      consensusStartDate: 1633255912316,
      consensusEndDate: 1609530703809,
      corporateActionsAppliedDate: 1658848472158,
    },
    {
      ratingBuy: 23,
      ratingOverweight: 3,
      ratingHold: 11,
      ratingUnderweight: 2,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.556,
      consensusStartDate: 1601749654110,
      consensusEndDate: 1621806014423,
      corporateActionsAppliedDate: 1602178985880,
    },
    {
      ratingBuy: 23,
      ratingOverweight: 3,
      ratingHold: 10,
      ratingUnderweight: 2,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.478845,
      consensusStartDate: 1640214780807,
      consensusEndDate: 1649500227657,
      corporateActionsAppliedDate: 1661594186248,
    },
    {
      ratingBuy: 24,
      ratingOverweight: 3,
      ratingHold: 9,
      ratingUnderweight: 2,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.497056,
      consensusStartDate: 1616721075044,
      consensusEndDate: 1665133635179,
      corporateActionsAppliedDate: 1616681991776,
    },
    {
      ratingBuy: 23,
      ratingOverweight: 3,
      ratingHold: 9,
      ratingUnderweight: 1,
      ratingSell: 3,
      ratingNone: 0,
      ratingScaleMark: 1.517344,
      consensusStartDate: 1664184273821,
      consensusEndDate: 1617015950753,
      corporateActionsAppliedDate: 1657994589687,
    },
    {
      ratingBuy: 24,
      ratingOverweight: 3,
      ratingHold: 9,
      ratingUnderweight: 1,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.480697,
      consensusStartDate: 1640321777672,
      consensusEndDate: 1667158250591,
      corporateActionsAppliedDate: 1609371026534,
    },
    {
      ratingBuy: 23,
      ratingOverweight: 3,
      ratingHold: 10,
      ratingUnderweight: 1,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.436936,
      consensusStartDate: 1636208584348,
      consensusEndDate: 1635089622937,
      corporateActionsAppliedDate: 1602126291252,
    },
    {
      ratingBuy: 23,
      ratingOverweight: 3,
      ratingHold: 11,
      ratingUnderweight: 1,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.5,
      consensusStartDate: 1661847880528,
      consensusEndDate: 1610962542196,
      corporateActionsAppliedDate: 1645657804919,
    },
    {
      ratingBuy: 24,
      ratingOverweight: 3,
      ratingHold: 11,
      ratingUnderweight: 2,
      ratingSell: 1,
      ratingNone: 0,
      ratingScaleMark: 1.4758,
      consensusStartDate: 1634669417392,
      consensusEndDate: 1600077263470,
      corporateActionsAppliedDate: 1635121330442,
    },
    {
      ratingBuy: 25,
      ratingOverweight: 3,
      ratingHold: 12,
      ratingUnderweight: 2,
      ratingSell: 1,
      ratingNone: 0,
      ratingScaleMark: 1.431502,
      consensusStartDate: 1647641278479,
      consensusEndDate: 1624793873713,
      corporateActionsAppliedDate: 1635670557868,
    },
    {
      ratingBuy: 25,
      ratingOverweight: 3,
      ratingHold: 10,
      ratingUnderweight: 2,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.47002,
      consensusStartDate: 1592059160943,
      consensusEndDate: 1640214883318,
      corporateActionsAppliedDate: 1630024956215,
    },
    {
      ratingBuy: 26,
      ratingOverweight: 3,
      ratingHold: 10,
      ratingUnderweight: 2,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.50303,
      consensusStartDate: 1631605215753,
      consensusEndDate: 1592194900261,
      corporateActionsAppliedDate: 1656499825237,
    },
    {
      ratingBuy: 25,
      ratingOverweight: 3,
      ratingHold: 10,
      ratingUnderweight: 2,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.49194,
      consensusStartDate: 1628033515870,
      consensusEndDate: 1632088908591,
      corporateActionsAppliedDate: 1636917137918,
    },
    {
      ratingBuy: 26,
      ratingOverweight: 3,
      ratingHold: 10,
      ratingUnderweight: 2,
      ratingSell: 2,
      ratingNone: 0,
      ratingScaleMark: 1.445252,
      consensusStartDate: 1646132957638,
      consensusEndDate: 1627928329658,
      corporateActionsAppliedDate: 1626368861733,
    },
  ],
  balancesheet: {
    symbol: 'AAPL',
    balancesheet: [
      {
        reportDate: '2017-12-31',
        fiscalDate: '2017-12-31',
        currency: 'USD',
        currentCash: 25913000000,
        shortTermInvestments: 40388000000,
        receivables: 23186000000,
        inventory: 3956000000,
        otherCurrentAssets: 12087000000,
        currentAssets: 131339000000,
        longTermInvestments: 170799000000,
        propertyPlantEquipment: 41304000000,
        goodwill: null,
        intangibleAssets: null,
        otherAssets: 22283000000,
        totalAssets: 365725000000,
        accountsPayable: 55888000000,
        currentLongTermDebt: 8784000000,
        otherCurrentLiabilities: 40230000000,
        totalCurrentLiabilities: 116866000000,
        longTermDebt: 93735000000,
        otherLiabilities: 4268000000,
        minorityInterest: 0,
        totalLiabilities: 258578000000,
        commonStock: 40201000000,
        retainedEarnings: 70400000000,
        treasuryStock: null,
        capitalSurplus: null,
        shareholderEquity: 107147000000,
        netTangibleAssets: 107147000000,
      },
      {
        reportDate: '2017-09-30',
        fiscalDate: '2017-09-30',
        currency: 'USD',
        currentCash: 25913000000,
        shortTermInvestments: 40388000000,
        receivables: 23186000000,
        inventory: 3956000000,
        otherCurrentAssets: 12087000000,
        currentAssets: 131339000000,
        longTermInvestments: 170799000000,
        propertyPlantEquipment: 41304000000,
        goodwill: null,
        intangibleAssets: null,
        otherAssets: 22283000000,
        totalAssets: 365725000000,
        accountsPayable: 55888000000,
        currentLongTermDebt: 8784000000,
        otherCurrentLiabilities: 40230000000,
        totalCurrentLiabilities: 116866000000,
        longTermDebt: 93735000000,
        otherLiabilities: 4268000000,
        minorityInterest: 0,
        totalLiabilities: 258578000000,
        commonStock: 40201000000,
        retainedEarnings: 70400000000,
        treasuryStock: null,
        capitalSurplus: null,
        shareholderEquity: 107147000000,
        netTangibleAssets: 107147000000,
      },
      {
        reportDate: '2017-06-30',
        fiscalDate: '2017-06-30',
        currency: 'USD',
        currentCash: 25913000000,
        shortTermInvestments: 40388000000,
        receivables: 23186000000,
        inventory: 3956000000,
        otherCurrentAssets: 12087000000,
        currentAssets: 131339000000,
        longTermInvestments: 170799000000,
        propertyPlantEquipment: 41304000000,
        goodwill: null,
        intangibleAssets: null,
        otherAssets: 22283000000,
        totalAssets: 365725000000,
        accountsPayable: 55888000000,
        currentLongTermDebt: 8784000000,
        otherCurrentLiabilities: 40230000000,
        totalCurrentLiabilities: 116866000000,
        longTermDebt: 93735000000,
        otherLiabilities: 4268000000,
        minorityInterest: 0,
        totalLiabilities: 258578000000,
        commonStock: 40201000000,
        retainedEarnings: 70400000000,
        treasuryStock: null,
        capitalSurplus: null,
        shareholderEquity: 107147000000,
        netTangibleAssets: 107147000000,
      },
      {
        reportDate: '2017-03-31',
        fiscalDate: '2017-03-31',
        currency: 'USD',
        currentCash: 25913000000,
        shortTermInvestments: 40388000000,
        receivables: 23186000000,
        inventory: 3956000000,
        otherCurrentAssets: 12087000000,
        currentAssets: 131339000000,
        longTermInvestments: 170799000000,
        propertyPlantEquipment: 41304000000,
        goodwill: null,
        intangibleAssets: null,
        otherAssets: 22283000000,
        totalAssets: 365725000000,
        accountsPayable: 55888000000,
        currentLongTermDebt: 8784000000,
        otherCurrentLiabilities: 40230000000,
        totalCurrentLiabilities: 116866000000,
        longTermDebt: 93735000000,
        otherLiabilities: 4268000000,
        minorityInterest: 0,
        totalLiabilities: 258578000000,
        commonStock: 40201000000,
        retainedEarnings: 70400000000,
        treasuryStock: null,
        capitalSurplus: null,
        shareholderEquity: 107147000000,
        netTangibleAssets: 107147000000,
      },
    ],
  },
  cashflow: {
    symbol: 'AAPL',
    cashflow: [
      {
        reportDate: '2020-03-31',
        fiscalDate: '2020-03-31',
        currency: 'USD',
        netIncome: 14125000000,
        depreciation: 2754000000,
        changesInReceivables: -9082000000,
        changesInInventories: 1942000000,
        cashChange: -6058000000,
        cashFlow: 19523000000,
        capitalExpenditures: -3041000000,
        investments: -926000000,
        investingActivityOther: 1566000000,
        totalInvestingCashFlows: -3001000000,
        dividendsPaid: -3530000000,
        netBorrowings: -27000000,
        otherFinancingCashFlows: -260000000,
        cashFlowFinancing: -22580000000,
        exchangeRateEffect: null,
      },
      {
        reportDate: '2020-01-31',
        fiscalDate: '2020-01-31',
        currency: 'USD',
        netIncome: 14125000000,
        depreciation: 2754000000,
        changesInReceivables: -9082000000,
        changesInInventories: 1942000000,
        cashChange: -6058000000,
        cashFlow: 19523000000,
        capitalExpenditures: -3041000000,
        investments: -926000000,
        investingActivityOther: 1566000000,
        totalInvestingCashFlows: -3001000000,
        dividendsPaid: -3530000000,
        netBorrowings: -27000000,
        otherFinancingCashFlows: -260000000,
        cashFlowFinancing: -22580000000,
        exchangeRateEffect: null,
      },
      {
        reportDate: '2019-12-31',
        fiscalDate: '2019-12-31',
        currency: 'USD',
        netIncome: 14125000000,
        depreciation: 2754000000,
        changesInReceivables: -9082000000,
        changesInInventories: 1942000000,
        cashChange: -6058000000,
        cashFlow: 19523000000,
        capitalExpenditures: -3041000000,
        investments: -926000000,
        investingActivityOther: 1566000000,
        totalInvestingCashFlows: -3001000000,
        dividendsPaid: -3530000000,
        netBorrowings: -27000000,
        otherFinancingCashFlows: -260000000,
        cashFlowFinancing: -22580000000,
        exchangeRateEffect: null,
      },
      {
        reportDate: '2019-09-30',
        fiscalDate: '2019-09-30',
        currency: 'USD',
        netIncome: 14125000000,
        depreciation: 2754000000,
        changesInReceivables: -9082000000,
        changesInInventories: 1942000000,
        cashChange: -6058000000,
        cashFlow: 19523000000,
        capitalExpenditures: -3041000000,
        investments: -926000000,
        investingActivityOther: 1566000000,
        totalInvestingCashFlows: -3001000000,
        dividendsPaid: -3530000000,
        netBorrowings: -27000000,
        otherFinancingCashFlows: -260000000,
        cashFlowFinancing: -22580000000,
        exchangeRateEffect: null,
      },
    ],
  },
  company: {
    symbol: 'AAPL',
    companyName: 'Apple Inc.',
    exchange: 'NASDAQ',
    industry: 'Telecommunications Equipment',
    website: 'http://www.apple.com',
    description:
      'Apple, Inc. engages in the design, manufacture, and marketing of mobile communication, media devices, personal computers, and portable digital music players. It operates through the following geographical segments: Americas, Europe, Greater China, Japan, and Rest of Asia Pacific. The Americas segment includes North and South America. The Europe segment consists of European countries, as well as India, the Middle East, and Africa. The Greater China segment comprises of China, Hong Kong, and Taiwan. The Rest of Asia Pacific segment includes Australia and Asian countries. The company was founded by Steven Paul Jobs, Ronald Gerald Wayne, and Stephen G. Wozniak on April 1, 1976 and is headquartered in Cupertino, CA.',
    CEO: 'Timothy Donald Cook',
    securityName: 'Apple Inc.',
    issueType: 'cs',
    sector: 'Electronic Technology',
    primarySicCode: 3663,
    employees: 132000,
    tags: ['Electronic Technology', 'Telecommunications Equipment'],
    address: 'One Apple Park Way',
    address2: null,
    state: 'CA',
    city: 'Cupertino',
    zip: '95014-2083',
    country: 'US',
    phone: '1.408.974.3123',
  },
  dividends: [
    {
      symbol: 'AAPL',
      exDate: '2018-02-10',
      paymentDate: '2018-02-17',
      recordDate: '2018-02-14',
      declaredDate: '2018-02-01',
      amount: 0.63,
      flag: 'Dividend income',
      currency: 'USD',
      description: 'Apple declares dividend of .63',
      frequency: 'quarterly',
    },
    {
      symbol: 'AAPL',
      exDate: '2017-11-10',
      paymentDate: '2017-11-17',
      recordDate: '2017-11-14',
      declaredDate: '2017-11-01',
      amount: 0.63,
      flag: 'Dividend income',
      currency: 'USD',
      description: 'Apple declares dividend of .63',
      frequency: 'quarterly',
    },
    {
      symbol: 'AAPL',
      exDate: '2017-08-10',
      paymentDate: '2017-08-17',
      recordDate: '2017-08-14',
      declaredDate: '2017-08-01',
      amount: 0.59,
      flag: 'Dividend income',
      currency: 'USD',
      description: 'Apple declares dividend of .59',
      frequency: 'quarterly',
    },
    {
      symbol: 'AAPL',
      exDate: '2017-05-10',
      paymentDate: '2017-05-17',
      recordDate: '2017-05-14',
      declaredDate: '2017-05-01',
      amount: 0.59,
      flag: 'Dividend income',
      currency: 'USD',
      description: 'Apple declares dividend of .59',
      frequency: 'quarterly',
    },
  ],
  dividendsNext: {
    symbol: 'AAPL',
    exDate: '2018-05-10',
    paymentDate: '2018-05-17',
    recordDate: '2018-05-14',
    declaredDate: '2017805-01',
    amount: 0.71,
    flag: 'Dividend income',
    currency: 'USD',
    description: 'Apple declares dividend of .71',
    frequency: 'quarterly',
  },
  earnings: {
    symbol: 'AAPL',
    earnings: [
      {
        actualEPS: 2.46,
        consensusEPS: 2.36,
        announceTime: 'AMC',
        numberOfEstimates: 34,
        EPSSurpriseDollar: 0.1,
        EPSReportDate: '2019-10-30',
        fiscalPeriod: 'Q3 2019',
        fiscalEndDate: '2019-09-30',
        yearAgo: 2.73,
        yearAgoChangePercent: -0.0989,
      },
      {
        actualEPS: 4.18,
        consensusEPS: 4.17,
        announceTime: 'AMC',
        numberOfEstimates: 35,
        EPSSurpriseDollar: 0.01,
        EPSReportDate: '2019-07-24',
        fiscalPeriod: 'Q2 2019',
        fiscalEndDate: '2019-06-30',
        yearAgo: 3.89,
        yearAgoChangePercent: 0.0746,
      },
      {
        actualEPS: 2.46,
        consensusEPS: 2.36,
        announceTime: 'AMC',
        numberOfEstimates: 34,
        EPSSurpriseDollar: 0.1,
        EPSReportDate: '2019-04-30',
        fiscalPeriod: 'Q1 2019',
        fiscalEndDate: '2019-03-31',
        yearAgo: 2.73,
        yearAgoChangePercent: -0.0989,
      },
      {
        actualEPS: 4.18,
        consensusEPS: 4.17,
        announceTime: 'AMC',
        numberOfEstimates: 35,
        EPSSurpriseDollar: 0.01,
        EPSReportDate: '2019-01-29',
        fiscalPeriod: 'Q4 2018',
        fiscalEndDate: '2018-12-31',
        yearAgo: 3.89,
        yearAgoChangePercent: 0.0746,
      },
    ],
  },
  estimates: {
    symbol: 'AAPL',
    estimates: [
      {
        consensusEPS: 6.02,
        announceTime: 'AMC',
        numberOfEstimates: 14,
        reportDate: '2020-01-31',
        fiscalPeriod: 'Q4 2019',
        fiscalEndDate: '2019-12-31',
        currency: 'USD',
      },
    ],
  },
  income: {
    symbol: 'AAPL',
    income: [
      {
        reportDate: '2019-12-31',
        fiscalDate: '2019-12-31',
        currency: 'USD',
        totalRevenue: 62681000000,
        costOfRevenue: 39086000000,
        grossProfit: 23595000000,
        researchAndDevelopment: 3750000000,
        sellingGeneralAndAdmin: 4216000000,
        operatingExpense: 47052000000,
        operatingIncome: 15629000000,
        otherIncomeExpenseNet: 792000000,
        ebit: 15629000000,
        interestIncome: 868000000,
        pretaxIncome: 16421000000,
        incomeTax: 2296000000,
        minorityInterest: 0,
        netIncome: 14125000000,
        netIncomeBasic: 14125000000,
      },
      {
        reportDate: '2019-09-30',
        fiscalDate: '2019-09-30',
        currency: 'USD',
        totalRevenue: 62681000000,
        costOfRevenue: 39086000000,
        grossProfit: 23595000000,
        researchAndDevelopment: 3750000000,
        sellingGeneralAndAdmin: 4216000000,
        operatingExpense: 47052000000,
        operatingIncome: 15629000000,
        otherIncomeExpenseNet: 792000000,
        ebit: 15629000000,
        interestIncome: 868000000,
        pretaxIncome: 16421000000,
        incomeTax: 2296000000,
        minorityInterest: 0,
        netIncome: 14125000000,
        netIncomeBasic: 14125000000,
      },
      {
        reportDate: '2019-06-30',
        fiscalDate: '2019-06-30',
        currency: 'USD',
        totalRevenue: 62681000000,
        costOfRevenue: 39086000000,
        grossProfit: 23595000000,
        researchAndDevelopment: 3750000000,
        sellingGeneralAndAdmin: 4216000000,
        operatingExpense: 47052000000,
        operatingIncome: 15629000000,
        otherIncomeExpenseNet: 792000000,
        ebit: 15629000000,
        interestIncome: 868000000,
        pretaxIncome: 16421000000,
        incomeTax: 2296000000,
        minorityInterest: 0,
        netIncome: 14125000000,
        netIncomeBasic: 14125000000,
      },
      {
        reportDate: '2019-03-31',
        fiscalDate: '2019-03-31',
        currency: 'USD',
        totalRevenue: 62681000000,
        costOfRevenue: 39086000000,
        grossProfit: 23595000000,
        researchAndDevelopment: 3750000000,
        sellingGeneralAndAdmin: 4216000000,
        operatingExpense: 47052000000,
        operatingIncome: 15629000000,
        otherIncomeExpenseNet: 792000000,
        ebit: 15629000000,
        interestIncome: 868000000,
        pretaxIncome: 16421000000,
        incomeTax: 2296000000,
        minorityInterest: 0,
        netIncome: 14125000000,
        netIncomeBasic: 14125000000,
      },
    ],
  },
  intradayPrices: sampleIntradayPrices,
  keyStats: {
    companyName: 'Apple Inc.',
    marketcap: 760334287200,
    week52high: 156.65,
    week52low: 93.63,
    week52change: 58.801903,
    sharesOutstanding: 5213840000,
    float: 5203997571,
    avg10Volume: 2774000,
    avg30Volume: 12774000,
    day200MovingAvg: 140.60541,
    day50MovingAvg: 156.49678,
    employees: 120000,
    ttmEPS: 16.5,
    ttmDividendRate: 2.25,
    dividendYield: 0.021,
    nextDividendDate: '2019-03-01',
    exDividendDate: '2019-02-08',
    nextEarningsDate: '2019-01-01',
    peRatio: 14,
    beta: 1.25,
    maxChangePercent: 153.021,
    year5ChangePercent: 0.5902546932200027,
    year2ChangePercent: 0.3777449874142869,
    year1ChangePercent: 0.39751716851558366,
    ytdChangePercent: 0.36659492036160124,
    month6ChangePercent: 0.12208398133748043,
    month3ChangePercent: 0.08466584665846649,
    month1ChangePercent: 0.009668596145283263,
    day30ChangePercent: -0.002762605699968781,
    day5ChangePercent: -0.005762605699968781,
  },
  logo: {
    url: 'https://storage.googleapis.com/iex/api/logos/AAPL.png',
  },
  news: [
    {
      datetime: 1545215400000,
      headline: 'Voice Search Technology Creates A New Paradigm For Marketers',
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/stable/news/article/8348646549980454',
      summary:
        '<p>Voice search is likely to grow by leap and bounds, with technological advancements leading to better adoption and fueling the growth cycle, according to Lindsay Boyajian, <a href="http://loupventures.com/how-the-future-of-voice-search-affects-marketers-today/">a guest contributor at Loup Ventu...',
      related: 'AAPL,AMZN,GOOG,GOOGL,MSFT',
      image: 'https://cloud.iexapis.com/stable/news/image/7594023985414148',
      lang: 'en',
      hasPaywall: true,
    },
    {
      datetime: 1545215400000,
      headline: 'Voice Search Technology Creates A New Paradigm For Marketers',
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/stable/news/article/8348646549980454',
      summary:
        '<p>Voice search is likely to grow by leap and bounds, with technological advancements leading to better adoption and fueling the growth cycle, according to Lindsay Boyajian, <a href="http://loupventures.com/how-the-future-of-voice-search-affects-marketers-today/">a guest contributor at Loup Ventu...',
      related: 'AAPL,AMZN,GOOG,GOOGL,MSFT',
      image: 'https://cloud.iexapis.com/stable/news/image/7594023985414148',
      lang: 'en',
      hasPaywall: true,
    },
    {
      datetime: 1545215400000,
      headline: 'Voice Search Technology Creates A New Paradigm For Marketers',
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/stable/news/article/8348646549980454',
      summary:
        '<p>Voice search is likely to grow by leap and bounds, with technological advancements leading to better adoption and fueling the growth cycle, according to Lindsay Boyajian, <a href="http://loupventures.com/how-the-future-of-voice-search-affects-marketers-today/">a guest contributor at Loup Ventu...',
      related: 'AAPL,AMZN,GOOG,GOOGL,MSFT',
      image: 'https://cloud.iexapis.com/stable/news/image/7594023985414148',
      lang: 'en',
      hasPaywall: true,
    },
    {
      datetime: 1545215400000,
      headline: 'Voice Search Technology Creates A New Paradigm For Marketers',
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/stable/news/article/8348646549980454',
      summary:
        '<p>Voice search is likely to grow by leap and bounds, with technological advancements leading to better adoption and fueling the growth cycle, according to Lindsay Boyajian, <a href="http://loupventures.com/how-the-future-of-voice-search-affects-marketers-today/">a guest contributor at Loup Ventu...',
      related: 'AAPL,AMZN,GOOG,GOOGL,MSFT',
      image: 'https://cloud.iexapis.com/stable/news/image/7594023985414148',
      lang: 'en',
      hasPaywall: true,
    },
  ],
  peers: ['MSFT', 'INTC', 'IBM', 'AMD', 'HPQ', 'GOOGL', 'MCHP', 'CRM', 'ZM', 'AMZN'],
  quote: {
    symbol: 'AAPL',
    companyName: 'Apple Inc.',
    calculationPrice: 'tops',
    open: 154,
    openTime: 1506605400394,
    close: 153.28,
    closeTime: 1506605400394,
    high: 154.8,
    low: 153.25,
    latestPrice: 158.73,
    latestSource: 'Previous close',
    latestTime: 'September 19, 2017',
    latestUpdate: 1505779200000,
    latestVolume: 20567140,
    volume: 20567140,
    iexRealtimePrice: 158.71,
    iexRealtimeSize: 100,
    iexLastUpdated: 1505851198059,
    delayedPrice: 158.71,
    delayedPriceTime: 1505854782437,
    oddLotDelayedPrice: 158.7,
    oddLotDelayedPriceTime: 1505854782436,
    extendedPrice: 159.21,
    extendedChange: 1.68,
    extendedChangePercent: 0.0125,
    extendedPriceTime: 1527082200361,
    previousClose: 451.23,
    previousVolume: 22268140,
    change: -1.67,
    changePercent: -0.01158,
    iexMarketPercent: 0.00948,
    iexVolume: 82451,
    avgTotalVolume: 29623234,
    iexBidPrice: 153.01,
    iexBidSize: 100,
    iexAskPrice: 158.66,
    iexAskSize: 100,
    marketCap: 751627174400,
    week52High: 159.65,
    week52Low: 93.63,
    ytdChange: 0.3665,
    peRatio: 17.18,
    lastTradeTime: 1505779200000,
    isUSMarketOpen: false,
  },
};
