export const sampleIntradayPrices = [
  {
    date: '2020-08-11',
    minute: '09:30',
    label: '09:30 AM',
    high: 469.41,
    low: 453.213,
    open: 467.753,
    close: 459.77,
    average: 465.1,
    volume: 5319,
    notional: 2444398.248,
    numberOfTrades: 57,
  },
  {
    date: '2020-08-11',
    minute: '09:31',
    label: '09:31 AM',
    high: 454.3,
    low: 470.44,
    open: 455.3,
    close: 466.94,
    average: 469.73,
    volume: 5967,
    notional: 2699062.339,
    numberOfTrades: 42,
  },
  {
    date: '2020-08-11',
    minute: '09:32',
    label: '09:32 AM',
    high: 470.5,
    low: 466.5,
    open: 464.78,
    close: 455.83,
    average: 455.64,
    volume: 2294,
    notional: 1014993.54,
    numberOfTrades: 26,
  },
  {
    date: '2020-08-11',
    minute: '09:33',
    label: '09:33 AM',
    high: 448.499,
    low: 465.91,
    open: 468.6,
    close: 452.19,
    average: 458.369,
    volume: 1458,
    notional: 649066.21,
    numberOfTrades: 21,
  },
  {
    date: '2020-08-11',
    minute: '09:34',
    label: '09:34 AM',
    high: 453.1,
    low: 448,
    open: 466.4,
    close: 467.88,
    average: 466.306,
    volume: 3225,
    notional: 1450968.352,
    numberOfTrades: 42,
  },
  {
    date: '2020-08-11',
    minute: '09:35',
    label: '09:35 AM',
    high: 451.12,
    low: 447.3,
    open: 454.06,
    close: 446.82,
    average: 447.098,
    volume: 2880,
    notional: 1306999.93,
    numberOfTrades: 44,
  },
  {
    date: '2020-08-11',
    minute: '09:36',
    label: '09:36 AM',
    high: 463.86,
    low: 463.43,
    open: 461.35,
    close: 452.52,
    average: 466.402,
    volume: 1876,
    notional: 844640.33,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '09:37',
    label: '09:37 AM',
    high: 455.21,
    low: 450.669,
    open: 462.07,
    close: 455.22,
    average: 462.937,
    volume: 3217,
    notional: 1474952.77,
    numberOfTrades: 29,
  },
  {
    date: '2020-08-11',
    minute: '09:38',
    label: '09:38 AM',
    high: 466.92,
    low: 450.94,
    open: 449.65,
    close: 464.37,
    average: 449.06,
    volume: 1080,
    notional: 480337.018,
    numberOfTrades: 16,
  },
  {
    date: '2020-08-11',
    minute: '09:39',
    label: '09:39 AM',
    high: 471.11,
    low: 450.72,
    open: 457.33,
    close: 458.672,
    average: 458.675,
    volume: 563,
    notional: 250187.09,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '09:40',
    label: '09:40 AM',
    high: 450.77,
    low: 464.61,
    open: 469.52,
    close: 454.86,
    average: 453.611,
    volume: 648,
    notional: 285433.161,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '09:41',
    label: '09:41 AM',
    high: 461.44,
    low: 464.06,
    open: 462.92,
    close: 456.69,
    average: 449.611,
    volume: 517,
    notional: 232625.827,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '09:42',
    label: '09:42 AM',
    high: 455.68,
    low: 466.63,
    open: 469.53,
    close: 448.52,
    average: 464.906,
    volume: 2475,
    notional: 1084189.05,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '09:43',
    label: '09:43 AM',
    high: 456.97,
    low: 464.39,
    open: 465.12,
    close: 470,
    average: 458.735,
    volume: 252,
    notional: 112546,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '09:44',
    label: '09:44 AM',
    high: 460.75,
    low: 452.39,
    open: 456.95,
    close: 461.99,
    average: 458.071,
    volume: 543,
    notional: 245354.66,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '09:45',
    label: '09:45 AM',
    high: 468.5,
    low: 468.09,
    open: 465.77,
    close: 449.42,
    average: 460.721,
    volume: 210,
    notional: 93013.12,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '09:46',
    label: '09:46 AM',
    high: 450.839,
    low: 463.74,
    open: 470.05,
    close: 450.97,
    average: 470.07,
    volume: 653,
    notional: 299225.23,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '09:47',
    label: '09:47 AM',
    high: 464.64,
    low: 468.31,
    open: 456.96,
    close: 453.34,
    average: 464.62,
    volume: 459,
    notional: 205566.21,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '09:48',
    label: '09:48 AM',
    high: 451.43,
    low: 469.31,
    open: 451.27,
    close: 453.11,
    average: 451.98,
    volume: 600,
    notional: 270146.428,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '09:49',
    label: '09:49 AM',
    high: 462.47,
    low: 455.55,
    open: 467.61,
    close: 462.613,
    average: 466.24,
    volume: 1034,
    notional: 463623.63,
    numberOfTrades: 14,
  },
  {
    date: '2020-08-11',
    minute: '09:50',
    label: '09:50 AM',
    high: 449.13,
    low: 454.82,
    open: 454.38,
    close: 452.89,
    average: 462.69,
    volume: 49,
    notional: 21246.17,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '09:51',
    label: '09:51 AM',
    high: 450.63,
    low: 448.5,
    open: 454.17,
    close: 456.07,
    average: 459.001,
    volume: 1025,
    notional: 460539.93,
    numberOfTrades: 13,
  },
  {
    date: '2020-08-11',
    minute: '09:52',
    label: '09:52 AM',
    high: 457.27,
    low: 461.44,
    open: 450.5,
    close: 449.213,
    average: 455.413,
    volume: 210,
    notional: 92632.14,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '09:53',
    label: '09:53 AM',
    high: 456.09,
    low: 458.69,
    open: 470.28,
    close: 462.97,
    average: 455.45,
    volume: 209,
    notional: 90598,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '09:54',
    label: '09:54 AM',
    high: null,
    low: null,
    open: null,
    close: null,
    average: null,
    volume: 0,
    notional: 0,
    numberOfTrades: 0,
  },
  {
    date: '2020-08-11',
    minute: '09:55',
    label: '09:55 AM',
    high: 463.226,
    low: 450.291,
    open: 451.19,
    close: 470.514,
    average: 450.571,
    volume: 479,
    notional: 218193.1,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '09:56',
    label: '09:56 AM',
    high: 459.09,
    low: 452,
    open: 468.55,
    close: 467.42,
    average: 447.8,
    volume: 101,
    notional: 45509,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '09:57',
    label: '09:57 AM',
    high: 464.17,
    low: 457,
    open: 452.6,
    close: 461.11,
    average: 454.247,
    volume: 2259,
    notional: 978378.32,
    numberOfTrades: 24,
  },
  {
    date: '2020-08-11',
    minute: '09:58',
    label: '09:58 AM',
    high: 452.31,
    low: 458.69,
    open: 458.2,
    close: 459.53,
    average: 461.619,
    volume: 3034,
    notional: 1362904.1,
    numberOfTrades: 19,
  },
  {
    date: '2020-08-11',
    minute: '09:59',
    label: '09:59 AM',
    high: 469.502,
    low: 454.537,
    open: 460.456,
    close: 465.185,
    average: 458.56,
    volume: 2306,
    notional: 993299.65,
    numberOfTrades: 24,
  },
  {
    date: '2020-08-11',
    minute: '10:00',
    label: '10 AM',
    high: 458.58,
    low: 461.36,
    open: 449.48,
    close: 454.73,
    average: 448.941,
    volume: 4110,
    notional: 1879872.67,
    numberOfTrades: 36,
  },
  {
    date: '2020-08-11',
    minute: '10:01',
    label: '10:01 AM',
    high: 453.22,
    low: 454.735,
    open: 449.43,
    close: 460.881,
    average: 454.515,
    volume: 1264,
    notional: 554087.52,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '10:02',
    label: '10:02 AM',
    high: 458.11,
    low: 463.66,
    open: 465.73,
    close: 453.52,
    average: 467.277,
    volume: 346,
    notional: 156464.03,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '10:03',
    label: '10:03 AM',
    high: 466.81,
    low: 467.71,
    open: 458.07,
    close: 464.54,
    average: 467.347,
    volume: 31,
    notional: 13843.9,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '10:04',
    label: '10:04 AM',
    high: 449.24,
    low: 451.31,
    open: 466.34,
    close: 467.365,
    average: 459.425,
    volume: 800,
    notional: 358633.2,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '10:05',
    label: '10:05 AM',
    high: 461.5,
    low: 462.38,
    open: 455.92,
    close: 446.95,
    average: 465.648,
    volume: 664,
    notional: 294280.94,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '10:06',
    label: '10:06 AM',
    high: 466.5,
    low: 446.44,
    open: 457.373,
    close: 460.215,
    average: 452.05,
    volume: 4388,
    notional: 1911112.677,
    numberOfTrades: 30,
  },
  {
    date: '2020-08-11',
    minute: '10:07',
    label: '10:07 AM',
    high: 467.876,
    low: 455.007,
    open: 453.581,
    close: 456.971,
    average: 463.13,
    volume: 2034,
    notional: 907365.575,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '10:08',
    label: '10:08 AM',
    high: 466.1,
    low: 465.32,
    open: 447.422,
    close: 447.448,
    average: 452.417,
    volume: 4953,
    notional: 2116472.808,
    numberOfTrades: 21,
  },
  {
    date: '2020-08-11',
    minute: '10:09',
    label: '10:09 AM',
    high: 459.31,
    low: 458.66,
    open: 465.99,
    close: 445.62,
    average: 454.89,
    volume: 6109,
    notional: 2709103,
    numberOfTrades: 30,
  },
  {
    date: '2020-08-11',
    minute: '10:10',
    label: '10:10 AM',
    high: 457.39,
    low: 454.68,
    open: 461.121,
    close: 450.29,
    average: 452.085,
    volume: 726,
    notional: 323110.19,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '10:11',
    label: '10:11 AM',
    high: 458.01,
    low: 466.38,
    open: 455.13,
    close: 453.9,
    average: 465.14,
    volume: 1055,
    notional: 482205,
    numberOfTrades: 14,
  },
  {
    date: '2020-08-11',
    minute: '10:12',
    label: '10:12 AM',
    high: 455.3,
    low: 465.3,
    open: 450.26,
    close: 459.78,
    average: 455.398,
    volume: 303,
    notional: 138233.4,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '10:13',
    label: '10:13 AM',
    high: 459.1,
    low: 465.818,
    open: 456.9,
    close: 457.8,
    average: 464.068,
    volume: 299,
    notional: 129600.182,
    numberOfTrades: 16,
  },
  {
    date: '2020-08-11',
    minute: '10:14',
    label: '10:14 AM',
    high: 460.4,
    low: 467.44,
    open: 463.42,
    close: 445.901,
    average: 447.855,
    volume: 277,
    notional: 122371.213,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '10:15',
    label: '10:15 AM',
    high: 449.48,
    low: 446.79,
    open: 457.7,
    close: 462.95,
    average: 449.75,
    volume: 463,
    notional: 198653.43,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '10:16',
    label: '10:16 AM',
    high: 458.35,
    low: 463.88,
    open: 456.63,
    close: 464.81,
    average: 450.912,
    volume: 1581,
    notional: 732229.481,
    numberOfTrades: 21,
  },
  {
    date: '2020-08-11',
    minute: '10:17',
    label: '10:17 AM',
    high: 459.343,
    low: 462.09,
    open: 447.88,
    close: 452,
    average: 466.441,
    volume: 1471,
    notional: 629115.755,
    numberOfTrades: 28,
  },
  {
    date: '2020-08-11',
    minute: '10:18',
    label: '10:18 AM',
    high: 454.8,
    low: 446.87,
    open: 450.98,
    close: 449.36,
    average: 456.82,
    volume: 5455,
    notional: 2377713.051,
    numberOfTrades: 84,
  },
  {
    date: '2020-08-11',
    minute: '10:19',
    label: '10:19 AM',
    high: 466.104,
    low: 447.3,
    open: 460.324,
    close: 447.099,
    average: 466.756,
    volume: 772,
    notional: 344336.83,
    numberOfTrades: 13,
  },
  {
    date: '2020-08-11',
    minute: '10:20',
    label: '10:20 AM',
    high: 452.03,
    low: 464.5,
    open: 455.89,
    close: 456.5,
    average: 464.074,
    volume: 2196,
    notional: 984809.9,
    numberOfTrades: 36,
  },
  {
    date: '2020-08-11',
    minute: '10:21',
    label: '10:21 AM',
    high: 465.19,
    low: 445.705,
    open: 459.153,
    close: 446.86,
    average: 465.472,
    volume: 2323,
    notional: 1032371.87,
    numberOfTrades: 21,
  },
  {
    date: '2020-08-11',
    minute: '10:22',
    label: '10:22 AM',
    high: 467.37,
    low: 458.099,
    open: 454.09,
    close: 464.1,
    average: 460.334,
    volume: 1806,
    notional: 782778.33,
    numberOfTrades: 19,
  },
  {
    date: '2020-08-11',
    minute: '10:23',
    label: '10:23 AM',
    high: 450.11,
    low: 458.91,
    open: 456.07,
    close: 462.76,
    average: 463.59,
    volume: 208,
    notional: 92796,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '10:24',
    label: '10:24 AM',
    high: 460.91,
    low: 453.468,
    open: 446.01,
    close: 460.54,
    average: 454.509,
    volume: 109,
    notional: 48006.092,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '10:25',
    label: '10:25 AM',
    high: 458.4,
    low: 448.64,
    open: 451.91,
    close: 464.9,
    average: 457.981,
    volume: 193,
    notional: 85138.42,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '10:26',
    label: '10:26 AM',
    high: 455.47,
    low: 460.572,
    open: 461.385,
    close: 451.19,
    average: 467.766,
    volume: 1727,
    notional: 781221.4,
    numberOfTrades: 18,
  },
  {
    date: '2020-08-11',
    minute: '10:27',
    label: '10:27 AM',
    high: 460.9,
    low: 449.44,
    open: 455.96,
    close: 450.44,
    average: 453.228,
    volume: 959,
    notional: 421391.69,
    numberOfTrades: 13,
  },
  {
    date: '2020-08-11',
    minute: '10:28',
    label: '10:28 AM',
    high: 454.464,
    low: 461.27,
    open: 464.32,
    close: 458,
    average: 462.322,
    volume: 1113,
    notional: 503753.498,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '10:29',
    label: '10:29 AM',
    high: 455.656,
    low: 458.34,
    open: 466.835,
    close: 465.76,
    average: 452.187,
    volume: 3117,
    notional: 1395311.7,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '10:30',
    label: '10:30 AM',
    high: 464.9,
    low: 466.673,
    open: 458.4,
    close: 461.238,
    average: 462.58,
    volume: 956,
    notional: 434996.447,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '10:31',
    label: '10:31 AM',
    high: 461.75,
    low: 452.11,
    open: 455.4,
    close: 447.95,
    average: 455.035,
    volume: 359,
    notional: 158633.15,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '10:32',
    label: '10:32 AM',
    high: 458.448,
    low: 461.05,
    open: 445.556,
    close: 446.27,
    average: 446.75,
    volume: 2675,
    notional: 1190308.797,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '10:33',
    label: '10:33 AM',
    high: 449.67,
    low: 446.757,
    open: 447.89,
    close: 456.285,
    average: 452.725,
    volume: 627,
    notional: 277655,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '10:34',
    label: '10:34 AM',
    high: 448.76,
    low: 451.49,
    open: 448.02,
    close: 446.44,
    average: 458.01,
    volume: 734,
    notional: 313269.69,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '10:35',
    label: '10:35 AM',
    high: 456.589,
    low: 461.253,
    open: 456.279,
    close: 455.12,
    average: 455.799,
    volume: 859,
    notional: 393255.3,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '10:36',
    label: '10:36 AM',
    high: 452.22,
    low: 446.908,
    open: 446.286,
    close: 463.19,
    average: 450.593,
    volume: 704,
    notional: 306568.25,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '10:37',
    label: '10:37 AM',
    high: 458.65,
    low: 458.88,
    open: 447.394,
    close: 463.31,
    average: 446.81,
    volume: 410,
    notional: 184421.3,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '10:38',
    label: '10:38 AM',
    high: 458.79,
    low: 452.7,
    open: 445.8,
    close: 447.68,
    average: 447.43,
    volume: 1204,
    notional: 545961.13,
    numberOfTrades: 14,
  },
  {
    date: '2020-08-11',
    minute: '10:39',
    label: '10:39 AM',
    high: 448.98,
    low: 446.766,
    open: 452.458,
    close: 468.37,
    average: 465.695,
    volume: 3569,
    notional: 1545357.27,
    numberOfTrades: 26,
  },
  {
    date: '2020-08-11',
    minute: '10:40',
    label: '10:40 AM',
    high: 464.22,
    low: 450.084,
    open: 449.64,
    close: 463.719,
    average: 461.38,
    volume: 807,
    notional: 363593.61,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '10:41',
    label: '10:41 AM',
    high: 465.413,
    low: 462.453,
    open: 457.677,
    close: 465.05,
    average: 468.084,
    volume: 1500,
    notional: 647416.67,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '10:42',
    label: '10:42 AM',
    high: 459.75,
    low: 453.09,
    open: 452.48,
    close: 465.86,
    average: 467.979,
    volume: 539,
    notional: 238710.4,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '10:43',
    label: '10:43 AM',
    high: 453.06,
    low: 463,
    open: 450.991,
    close: 461.6,
    average: 454.018,
    volume: 1749,
    notional: 804359.99,
    numberOfTrades: 14,
  },
  {
    date: '2020-08-11',
    minute: '10:44',
    label: '10:44 AM',
    high: 466.152,
    low: 454.387,
    open: 448.499,
    close: 460.591,
    average: 458.32,
    volume: 313,
    notional: 139660,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '10:45',
    label: '10:45 AM',
    high: 466.87,
    low: 452.701,
    open: 453.61,
    close: 458.569,
    average: 467.214,
    volume: 1341,
    notional: 589123.158,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '10:46',
    label: '10:46 AM',
    high: 462.87,
    low: 466,
    open: 446.773,
    close: 460.87,
    average: 467.941,
    volume: 24,
    notional: 10680.76,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '10:47',
    label: '10:47 AM',
    high: 468.11,
    low: 450.86,
    open: 448.07,
    close: 459.16,
    average: 464.766,
    volume: 234,
    notional: 102890.14,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '10:48',
    label: '10:48 AM',
    high: 451.45,
    low: 457.421,
    open: 463,
    close: 452.24,
    average: 453.394,
    volume: 2055,
    notional: 932763.2,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '10:49',
    label: '10:49 AM',
    high: 453.539,
    low: 446.379,
    open: 451.005,
    close: 455.06,
    average: 464.264,
    volume: 811,
    notional: 363641.392,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '10:50',
    label: '10:50 AM',
    high: 450.61,
    low: 467.211,
    open: 459.34,
    close: 457.599,
    average: 454.33,
    volume: 991,
    notional: 432528.35,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '10:51',
    label: '10:51 AM',
    high: 454.45,
    low: 461.044,
    open: 456.275,
    close: 456.501,
    average: 454.572,
    volume: 3648,
    notional: 1626949.94,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '10:52',
    label: '10:52 AM',
    high: 463.779,
    low: 456.65,
    open: 459.557,
    close: 456.267,
    average: 465.887,
    volume: 407,
    notional: 182223,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '10:53',
    label: '10:53 AM',
    high: 461.9,
    low: 460.435,
    open: 467.214,
    close: 454.227,
    average: 467.904,
    volume: 321,
    notional: 139291.9,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '10:54',
    label: '10:54 AM',
    high: 455.15,
    low: 450.745,
    open: 447.26,
    close: 462.13,
    average: 451.17,
    volume: 1124,
    notional: 501961.78,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '10:55',
    label: '10:55 AM',
    high: 463.333,
    low: 458.8,
    open: 457.62,
    close: 453.692,
    average: 460.922,
    volume: 423,
    notional: 187747.73,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '10:56',
    label: '10:56 AM',
    high: 461.2,
    low: 457.73,
    open: 445.835,
    close: 460.08,
    average: 461.561,
    volume: 623,
    notional: 280895.22,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '10:57',
    label: '10:57 AM',
    high: 458.958,
    low: 455.62,
    open: 466.72,
    close: 464.8,
    average: 449.737,
    volume: 623,
    notional: 276268.5,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '10:58',
    label: '10:58 AM',
    high: 459.46,
    low: 465.34,
    open: 458.64,
    close: 445.57,
    average: 447.151,
    volume: 602,
    notional: 277169,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '10:59',
    label: '10:59 AM',
    high: 462.8,
    low: 461.09,
    open: 455.86,
    close: 454.45,
    average: 457.747,
    volume: 755,
    notional: 348338.52,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '11:00',
    label: '11 AM',
    high: 451.978,
    low: 445.8,
    open: 459.26,
    close: 452.6,
    average: 467.505,
    volume: 545,
    notional: 243653.74,
    numberOfTrades: 16,
  },
  {
    date: '2020-08-11',
    minute: '11:01',
    label: '11:01 AM',
    high: 446.19,
    low: 456.806,
    open: 456.68,
    close: 468.44,
    average: 466.8,
    volume: 724,
    notional: 332037.8,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '11:02',
    label: '11:02 AM',
    high: 454.554,
    low: 452.579,
    open: 450.52,
    close: 454.658,
    average: 458.759,
    volume: 5377,
    notional: 2418157.73,
    numberOfTrades: 21,
  },
  {
    date: '2020-08-11',
    minute: '11:03',
    label: '11:03 AM',
    high: 450.706,
    low: 462.29,
    open: 458.6,
    close: 467.44,
    average: 454.896,
    volume: 702,
    notional: 322482,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '11:04',
    label: '11:04 AM',
    high: 458.12,
    low: 450.2,
    open: 463.25,
    close: 468.2,
    average: 454.144,
    volume: 113,
    notional: 49143.2,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '11:05',
    label: '11:05 AM',
    high: 465.5,
    low: 461.17,
    open: 456.09,
    close: 464.72,
    average: 459.475,
    volume: 969,
    notional: 425485.79,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '11:06',
    label: '11:06 AM',
    high: 447.58,
    low: 458.494,
    open: 451.358,
    close: 449.528,
    average: 469.719,
    volume: 211,
    notional: 95685.106,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '11:07',
    label: '11:07 AM',
    high: 460.3,
    low: 461.642,
    open: 462.497,
    close: 469.53,
    average: 451.75,
    volume: 418,
    notional: 190836,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '11:08',
    label: '11:08 AM',
    high: 467.31,
    low: 467.642,
    open: 464.448,
    close: 463.48,
    average: 466.448,
    volume: 719,
    notional: 323781,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '11:09',
    label: '11:09 AM',
    high: 455.961,
    low: 452.3,
    open: 464.01,
    close: 449.279,
    average: 460.27,
    volume: 1732,
    notional: 807139.806,
    numberOfTrades: 18,
  },
  {
    date: '2020-08-11',
    minute: '11:10',
    label: '11:10 AM',
    high: 468.787,
    low: 459.521,
    open: 450.414,
    close: 468.542,
    average: 451.772,
    volume: 315,
    notional: 140196.4,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '11:11',
    label: '11:11 AM',
    high: 468.108,
    low: 459.92,
    open: 450.19,
    close: 452.32,
    average: 450.186,
    volume: 632,
    notional: 287633.652,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '11:12',
    label: '11:12 AM',
    high: 466.42,
    low: 466.28,
    open: 457.43,
    close: 449.41,
    average: 462.882,
    volume: 488,
    notional: 225457.66,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '11:13',
    label: '11:13 AM',
    high: 464.73,
    low: 452.62,
    open: 466.44,
    close: 461.595,
    average: 452.917,
    volume: 422,
    notional: 190503.66,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '11:14',
    label: '11:14 AM',
    high: 465.87,
    low: 453.93,
    open: 451.24,
    close: 449.34,
    average: 452.01,
    volume: 203,
    notional: 90043,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '11:15',
    label: '11:15 AM',
    high: 470,
    low: 464.54,
    open: 455.586,
    close: 457.2,
    average: 465.599,
    volume: 2970,
    notional: 1298075.31,
    numberOfTrades: 22,
  },
  {
    date: '2020-08-11',
    minute: '11:16',
    label: '11:16 AM',
    high: 449.24,
    low: 469.9,
    open: 452.79,
    close: 470.2,
    average: 455.16,
    volume: 102,
    notional: 46273,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '11:17',
    label: '11:17 AM',
    high: 457.09,
    low: 450.68,
    open: 460.57,
    close: 449.35,
    average: 465.26,
    volume: 101,
    notional: 45272,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '11:18',
    label: '11:18 AM',
    high: null,
    low: null,
    open: null,
    close: null,
    average: null,
    volume: 0,
    notional: 0,
    numberOfTrades: 0,
  },
  {
    date: '2020-08-11',
    minute: '11:19',
    label: '11:19 AM',
    high: 469.85,
    low: 449.569,
    open: 468.465,
    close: 456.9,
    average: 449.74,
    volume: 403,
    notional: 181252.6,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '11:20',
    label: '11:20 AM',
    high: 459.24,
    low: 463.76,
    open: 460.69,
    close: 460.38,
    average: 468.885,
    volume: 127,
    notional: 55280.675,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '11:21',
    label: '11:21 AM',
    high: 458,
    low: 448.738,
    open: 462.1,
    close: 455.35,
    average: 467.397,
    volume: 1288,
    notional: 589546.14,
    numberOfTrades: 19,
  },
  {
    date: '2020-08-11',
    minute: '11:22',
    label: '11:22 AM',
    high: 465.575,
    low: 462.152,
    open: 464.675,
    close: 470.51,
    average: 459.599,
    volume: 5525,
    notional: 2447881.93,
    numberOfTrades: 47,
  },
  {
    date: '2020-08-11',
    minute: '11:23',
    label: '11:23 AM',
    high: 468.73,
    low: 464.1,
    open: 452.15,
    close: 466.871,
    average: 458.83,
    volume: 1533,
    notional: 696690.39,
    numberOfTrades: 25,
  },
  {
    date: '2020-08-11',
    minute: '11:24',
    label: '11:24 AM',
    high: 466.54,
    low: 466.44,
    open: 451.8,
    close: 455.5,
    average: 466.616,
    volume: 226,
    notional: 99501.8,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '11:25',
    label: '11:25 AM',
    high: 464.31,
    low: 451.15,
    open: 462.69,
    close: 464.253,
    average: 451.026,
    volume: 2030,
    notional: 884087.498,
    numberOfTrades: 33,
  },
  {
    date: '2020-08-11',
    minute: '11:26',
    label: '11:26 AM',
    high: 459.92,
    low: 449.45,
    open: 464.92,
    close: 458.04,
    average: 456.909,
    volume: 643,
    notional: 286024.73,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '11:27',
    label: '11:27 AM',
    high: 455.863,
    low: 457.3,
    open: 456.85,
    close: 459.349,
    average: 453.74,
    volume: 1198,
    notional: 541678.448,
    numberOfTrades: 45,
  },
  {
    date: '2020-08-11',
    minute: '11:28',
    label: '11:28 AM',
    high: 453.37,
    low: 468.2,
    open: 467.32,
    close: 456.67,
    average: 469.086,
    volume: 632,
    notional: 272937.47,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '11:29',
    label: '11:29 AM',
    high: 463.48,
    low: 458.5,
    open: 455.6,
    close: 449.1,
    average: 454.122,
    volume: 470,
    notional: 217990.899,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '11:30',
    label: '11:30 AM',
    high: 467.734,
    low: 457.99,
    open: 449.479,
    close: 464.91,
    average: 463.707,
    volume: 381,
    notional: 168236.73,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '11:31',
    label: '11:31 AM',
    high: 461.65,
    low: 452.36,
    open: 449.26,
    close: 465.94,
    average: 455.308,
    volume: 213,
    notional: 97213.504,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '11:32',
    label: '11:32 AM',
    high: 456.88,
    low: 468.91,
    open: 458.88,
    close: 450,
    average: 451.611,
    volume: 355,
    notional: 163805.28,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '11:33',
    label: '11:33 AM',
    high: 455.41,
    low: 453.353,
    open: 447.999,
    close: 453.251,
    average: 456.105,
    volume: 206,
    notional: 91332.188,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '11:34',
    label: '11:34 AM',
    high: 467.277,
    low: 464.469,
    open: 462.241,
    close: 466.891,
    average: 448.317,
    volume: 106,
    notional: 45124.886,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '11:35',
    label: '11:35 AM',
    high: 454.67,
    low: 461.53,
    open: 454.072,
    close: 448.4,
    average: 447.032,
    volume: 342,
    notional: 149520.49,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '11:36',
    label: '11:36 AM',
    high: 459.86,
    low: 449.25,
    open: 448.83,
    close: 469.31,
    average: 450.51,
    volume: 2020,
    notional: 875409.81,
    numberOfTrades: 24,
  },
  {
    date: '2020-08-11',
    minute: '11:37',
    label: '11:37 AM',
    high: 460.7,
    low: 466.4,
    open: 448.21,
    close: 448.98,
    average: 450.36,
    volume: 10,
    notional: 4537.4,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '11:38',
    label: '11:38 AM',
    high: 467,
    low: 460.163,
    open: 468.198,
    close: 468.9,
    average: 468.838,
    volume: 419,
    notional: 183219.9,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '11:39',
    label: '11:39 AM',
    high: 456.89,
    low: 457.16,
    open: 460.595,
    close: 452.76,
    average: 467.668,
    volume: 306,
    notional: 140089.8,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '11:40',
    label: '11:40 AM',
    high: 463.238,
    low: 446.93,
    open: 458.44,
    close: 461.68,
    average: 455.06,
    volume: 3295,
    notional: 1466603,
    numberOfTrades: 25,
  },
  {
    date: '2020-08-11',
    minute: '11:41',
    label: '11:41 AM',
    high: 449.38,
    low: 451.52,
    open: 447.66,
    close: 458.35,
    average: 449.946,
    volume: 2558,
    notional: 1148726.81,
    numberOfTrades: 27,
  },
  {
    date: '2020-08-11',
    minute: '11:42',
    label: '11:42 AM',
    high: 448.98,
    low: 463.292,
    open: 455.56,
    close: 461.58,
    average: 466.181,
    volume: 1998,
    notional: 883586.5,
    numberOfTrades: 57,
  },
  {
    date: '2020-08-11',
    minute: '11:43',
    label: '11:43 AM',
    high: 454.635,
    low: 447.798,
    open: 451.779,
    close: 465.07,
    average: 464.394,
    volume: 1008,
    notional: 462804,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '11:44',
    label: '11:44 AM',
    high: 448.469,
    low: 466.41,
    open: 458.43,
    close: 448.11,
    average: 465.717,
    volume: 21,
    notional: 9118.88,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '11:45',
    label: '11:45 AM',
    high: 450.01,
    low: 463.59,
    open: 449.43,
    close: 453.06,
    average: 459.472,
    volume: 1378,
    notional: 629266.541,
    numberOfTrades: 16,
  },
  {
    date: '2020-08-11',
    minute: '11:46',
    label: '11:46 AM',
    high: 457.8,
    low: 465.15,
    open: 445.76,
    close: 456.18,
    average: 461.418,
    volume: 214,
    notional: 97732,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '11:47',
    label: '11:47 AM',
    high: 445.83,
    low: 457.21,
    open: 445.566,
    close: 461.604,
    average: 463.946,
    volume: 620,
    notional: 267517,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '11:48',
    label: '11:48 AM',
    high: 465.988,
    low: 461.225,
    open: 449.407,
    close: 456.54,
    average: 459.294,
    volume: 1217,
    notional: 540272,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '11:49',
    label: '11:49 AM',
    high: 453.93,
    low: 459.9,
    open: 462.6,
    close: 457.12,
    average: 459.158,
    volume: 2527,
    notional: 1164950.99,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '11:50',
    label: '11:50 AM',
    high: 445.9,
    low: 463.31,
    open: 449.807,
    close: 452.552,
    average: 462.686,
    volume: 3794,
    notional: 1645342.19,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '11:51',
    label: '11:51 AM',
    high: 458.04,
    low: 466.23,
    open: 465.3,
    close: 452.93,
    average: 457.136,
    volume: 757,
    notional: 337838.48,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '11:52',
    label: '11:52 AM',
    high: 448.089,
    low: 463.62,
    open: 449.71,
    close: 447.44,
    average: 459.19,
    volume: 998,
    notional: 436069.01,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '11:53',
    label: '11:53 AM',
    high: 453.81,
    low: 461.46,
    open: 448.05,
    close: 464.72,
    average: 461.83,
    volume: 204,
    notional: 91011,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '11:54',
    label: '11:54 AM',
    high: 467.2,
    low: 451.11,
    open: 449.29,
    close: 453.7,
    average: 455.515,
    volume: 1335,
    notional: 596277,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '11:55',
    label: '11:55 AM',
    high: 465.37,
    low: 452.2,
    open: 452.7,
    close: 463.9,
    average: 452.968,
    volume: 524,
    notional: 226821,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '11:56',
    label: '11:56 AM',
    high: 453.03,
    low: 463,
    open: 453.386,
    close: 453.129,
    average: 462.44,
    volume: 904,
    notional: 399568.06,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '11:57',
    label: '11:57 AM',
    high: 465.11,
    low: 451.03,
    open: 450.93,
    close: 462.96,
    average: 450.405,
    volume: 1214,
    notional: 543468,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '11:58',
    label: '11:58 AM',
    high: 454.98,
    low: 455.99,
    open: 454.44,
    close: 450.11,
    average: 453.685,
    volume: 326,
    notional: 148164.8,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '11:59',
    label: '11:59 AM',
    high: 450.05,
    low: 461.9,
    open: 456.795,
    close: 448.34,
    average: 462.899,
    volume: 1446,
    notional: 642792.328,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '12:00',
    label: '12 PM',
    high: 461.58,
    low: 452.475,
    open: 462.022,
    close: 456.8,
    average: 456.271,
    volume: 3894,
    notional: 1741962.162,
    numberOfTrades: 16,
  },
  {
    date: '2020-08-11',
    minute: '12:01',
    label: '12:01 PM',
    high: 455.63,
    low: 465.56,
    open: 466.38,
    close: 455.9,
    average: 465.364,
    volume: 666,
    notional: 306279.89,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '12:02',
    label: '12:02 PM',
    high: 457.21,
    low: 459.88,
    open: 459.76,
    close: 446.28,
    average: 448.22,
    volume: 314,
    notional: 136590,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '12:03',
    label: '12:03 PM',
    high: 460.355,
    low: 449.935,
    open: 467.994,
    close: 458.344,
    average: 449.54,
    volume: 1007,
    notional: 451079,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '12:04',
    label: '12:04 PM',
    high: 456.83,
    low: 464.73,
    open: 458.11,
    close: 447.78,
    average: 456.456,
    volume: 1438,
    notional: 651112.87,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '12:05',
    label: '12:05 PM',
    high: 448.2,
    low: 454.16,
    open: 459.27,
    close: 451.54,
    average: 446.801,
    volume: 1982,
    notional: 886217.485,
    numberOfTrades: 23,
  },
  {
    date: '2020-08-11',
    minute: '12:06',
    label: '12:06 PM',
    high: 449.052,
    low: 464,
    open: 450.22,
    close: 452.042,
    average: 454.05,
    volume: 1780,
    notional: 797038.7,
    numberOfTrades: 19,
  },
  {
    date: '2020-08-11',
    minute: '12:07',
    label: '12:07 PM',
    high: 465.39,
    low: 457.581,
    open: 460.873,
    close: 446.51,
    average: 452.816,
    volume: 803,
    notional: 367355,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '12:08',
    label: '12:08 PM',
    high: 457.25,
    low: 461.46,
    open: 463.94,
    close: 449.76,
    average: 450.141,
    volume: 497,
    notional: 215426.53,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '12:09',
    label: '12:09 PM',
    high: 463.4,
    low: 465.9,
    open: 456.498,
    close: 463.82,
    average: 461.353,
    volume: 908,
    notional: 404962,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '12:10',
    label: '12:10 PM',
    high: 452.56,
    low: 461.9,
    open: 457.43,
    close: 464.33,
    average: 451.68,
    volume: 543,
    notional: 238004.87,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '12:11',
    label: '12:11 PM',
    high: 461.85,
    low: 447.66,
    open: 461.6,
    close: 466.53,
    average: 452.237,
    volume: 214,
    notional: 96118.9,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '12:12',
    label: '12:12 PM',
    high: 457.42,
    low: 459.06,
    open: 462.28,
    close: 465.91,
    average: 451.26,
    volume: 101,
    notional: 46241,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:13',
    label: '12:13 PM',
    high: 459.46,
    low: 461.8,
    open: 453.1,
    close: 461.67,
    average: 452.131,
    volume: 209,
    notional: 91180,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:14',
    label: '12:14 PM',
    high: 466.58,
    low: 467.662,
    open: 447.598,
    close: 459.37,
    average: 447.957,
    volume: 207,
    notional: 91897.088,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '12:15',
    label: '12:15 PM',
    high: null,
    low: null,
    open: null,
    close: null,
    average: null,
    volume: 0,
    notional: 0,
    numberOfTrades: 0,
  },
  {
    date: '2020-08-11',
    minute: '12:16',
    label: '12:16 PM',
    high: 466.22,
    low: 466.57,
    open: 466.04,
    close: 460.21,
    average: 459.761,
    volume: 390,
    notional: 177405.89,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '12:17',
    label: '12:17 PM',
    high: 454.459,
    low: 465.78,
    open: 460.87,
    close: 460.443,
    average: 466.293,
    volume: 537,
    notional: 237936.6,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '12:18',
    label: '12:18 PM',
    high: 463.5,
    low: 449.07,
    open: 459.802,
    close: 458.65,
    average: 459.376,
    volume: 807,
    notional: 364351.28,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '12:19',
    label: '12:19 PM',
    high: 455.41,
    low: 458.28,
    open: 461.09,
    close: 455.66,
    average: 466.578,
    volume: 357,
    notional: 155578.7,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '12:20',
    label: '12:20 PM',
    high: 455.99,
    low: 459.7,
    open: 465.1,
    close: 464.28,
    average: 461.866,
    volume: 1249,
    notional: 558856.9,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '12:21',
    label: '12:21 PM',
    high: 455.3,
    low: 458.35,
    open: 455.88,
    close: 461.7,
    average: 448.282,
    volume: 205,
    notional: 89707,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:22',
    label: '12:22 PM',
    high: 456.71,
    low: 457.293,
    open: 456.48,
    close: 463.43,
    average: 450.271,
    volume: 204,
    notional: 91081.9,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:23',
    label: '12:23 PM',
    high: 453.89,
    low: 459.92,
    open: 458.21,
    close: 447.92,
    average: 450.68,
    volume: 422,
    notional: 189320.66,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '12:24',
    label: '12:24 PM',
    high: 469.287,
    low: 448.77,
    open: 465.81,
    close: 467.123,
    average: 449.19,
    volume: 408,
    notional: 183540.37,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '12:25',
    label: '12:25 PM',
    high: 464.31,
    low: 464.862,
    open: 459.123,
    close: 458.4,
    average: 453.264,
    volume: 125,
    notional: 58284.888,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:26',
    label: '12:26 PM',
    high: 449.625,
    low: 447.884,
    open: 456.4,
    close: 452.323,
    average: 453.261,
    volume: 525,
    notional: 232223.12,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '12:27',
    label: '12:27 PM',
    high: 452.02,
    low: 453.349,
    open: 465.82,
    close: 462.395,
    average: 452.144,
    volume: 401,
    notional: 183775.4,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '12:28',
    label: '12:28 PM',
    high: 456.62,
    low: 463.77,
    open: 457.38,
    close: 463.17,
    average: 467.47,
    volume: 113,
    notional: 50685.1,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:29',
    label: '12:29 PM',
    high: 450.3,
    low: 455.6,
    open: 451.7,
    close: 463.2,
    average: 463.1,
    volume: 101,
    notional: 46247,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:30',
    label: '12:30 PM',
    high: 451.79,
    low: 458.86,
    open: 452.73,
    close: 468.5,
    average: 459.36,
    volume: 740,
    notional: 330022.59,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '12:31',
    label: '12:31 PM',
    high: 452.06,
    low: 446.855,
    open: 466.63,
    close: 459.858,
    average: 463.636,
    volume: 605,
    notional: 274625.327,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '12:32',
    label: '12:32 PM',
    high: 458.051,
    low: 460.782,
    open: 464.843,
    close: 454.756,
    average: 464.841,
    volume: 520,
    notional: 226654,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:33',
    label: '12:33 PM',
    high: 462.629,
    low: 462.272,
    open: 452.423,
    close: 450.478,
    average: 459.302,
    volume: 305,
    notional: 138577.5,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:34',
    label: '12:34 PM',
    high: 461.76,
    low: 450.33,
    open: 448.75,
    close: 456.26,
    average: 467.51,
    volume: 104,
    notional: 46195,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:35',
    label: '12:35 PM',
    high: 450.07,
    low: 446.55,
    open: 465.229,
    close: 464.2,
    average: 450.894,
    volume: 1074,
    notional: 476387.78,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '12:36',
    label: '12:36 PM',
    high: 451.314,
    low: 459.953,
    open: 457.239,
    close: 450.491,
    average: 465.832,
    volume: 201,
    notional: 91872,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:37',
    label: '12:37 PM',
    high: 451.25,
    low: 458.81,
    open: 467.67,
    close: 449.01,
    average: 465.75,
    volume: 302,
    notional: 134819,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:38',
    label: '12:38 PM',
    high: 450.356,
    low: 465.942,
    open: 459.409,
    close: 454.936,
    average: 461.941,
    volume: 301,
    notional: 135779.5,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:39',
    label: '12:39 PM',
    high: 447.4,
    low: 454.74,
    open: 453.53,
    close: 458.07,
    average: 453.244,
    volume: 220,
    notional: 95971.2,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '12:40',
    label: '12:40 PM',
    high: 458.26,
    low: 460.237,
    open: 458.34,
    close: 463.89,
    average: 447.424,
    volume: 371,
    notional: 170049.553,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '12:41',
    label: '12:41 PM',
    high: 456.25,
    low: 459.317,
    open: 452.765,
    close: 449.918,
    average: 462.917,
    volume: 702,
    notional: 303378.263,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '12:42',
    label: '12:42 PM',
    high: 463.085,
    low: 462.177,
    open: 463.627,
    close: 454.051,
    average: 460.643,
    volume: 100,
    notional: 45628.7,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:43',
    label: '12:43 PM',
    high: 448.63,
    low: 462.785,
    open: 456.688,
    close: 467.62,
    average: 455.426,
    volume: 824,
    notional: 365053.5,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '12:44',
    label: '12:44 PM',
    high: 462.52,
    low: 460.38,
    open: 455.82,
    close: 457.67,
    average: 461.66,
    volume: 101,
    notional: 44867,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:45',
    label: '12:45 PM',
    high: 468.86,
    low: 464.28,
    open: 464.33,
    close: 451.5,
    average: 469.13,
    volume: 206,
    notional: 91259,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:46',
    label: '12:46 PM',
    high: 459.57,
    low: 462.22,
    open: 450.35,
    close: 454.41,
    average: 469.183,
    volume: 607,
    notional: 279518,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:47',
    label: '12:47 PM',
    high: 448.5,
    low: 452.6,
    open: 465.42,
    close: 456.44,
    average: 469.37,
    volume: 2220,
    notional: 1009990.8,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '12:48',
    label: '12:48 PM',
    high: 456.177,
    low: 464.667,
    open: 461.33,
    close: 455.508,
    average: 468.189,
    volume: 1360,
    notional: 599591.979,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '12:49',
    label: '12:49 PM',
    high: 464.187,
    low: 451.035,
    open: 455.461,
    close: 451.755,
    average: 463.18,
    volume: 1356,
    notional: 581077.6,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '12:50',
    label: '12:50 PM',
    high: 460.04,
    low: 451.72,
    open: 463.31,
    close: 456.64,
    average: 465.246,
    volume: 205,
    notional: 92083,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:51',
    label: '12:51 PM',
    high: 452.84,
    low: 450.15,
    open: 458.13,
    close: 465.07,
    average: 464.713,
    volume: 284,
    notional: 128157.64,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '12:52',
    label: '12:52 PM',
    high: 453.283,
    low: 446.01,
    open: 452.602,
    close: 456.8,
    average: 462.738,
    volume: 1860,
    notional: 837437.4,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '12:53',
    label: '12:53 PM',
    high: 452.79,
    low: 467.41,
    open: 452.42,
    close: 453.93,
    average: 466.16,
    volume: 1,
    notional: 459.4,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:54',
    label: '12:54 PM',
    high: 461.7,
    low: 448.73,
    open: 454.9,
    close: 448.25,
    average: 458.38,
    volume: 343,
    notional: 152616.3,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '12:55',
    label: '12:55 PM',
    high: 450.17,
    low: 448.58,
    open: 467.918,
    close: 451.27,
    average: 446.74,
    volume: 305,
    notional: 138184.1,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '12:56',
    label: '12:56 PM',
    high: 452.689,
    low: 446.917,
    open: 460.047,
    close: 459.513,
    average: 453.835,
    volume: 102,
    notional: 46271.3,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:57',
    label: '12:57 PM',
    high: 461.394,
    low: 450.949,
    open: 448.82,
    close: 459.925,
    average: 465.895,
    volume: 101,
    notional: 45727,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '12:58',
    label: '12:58 PM',
    high: 465.5,
    low: 467.8,
    open: 453.84,
    close: 457.53,
    average: 462.587,
    volume: 151,
    notional: 69072,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '12:59',
    label: '12:59 PM',
    high: 467.81,
    low: 449.41,
    open: 461.57,
    close: 457.07,
    average: 455.29,
    volume: 100,
    notional: 45492,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '13:00',
    label: '1 PM',
    high: 449.65,
    low: 448.85,
    open: 450.87,
    close: 463.459,
    average: 450.876,
    volume: 308,
    notional: 137008.4,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '13:01',
    label: '1:01 PM',
    high: 445.97,
    low: 456.09,
    open: 459.15,
    close: 446.163,
    average: 461.15,
    volume: 2438,
    notional: 1110158.896,
    numberOfTrades: 22,
  },
  {
    date: '2020-08-11',
    minute: '13:02',
    label: '1:02 PM',
    high: 452.054,
    low: 462.79,
    open: 460.51,
    close: 466.978,
    average: 459.937,
    volume: 288,
    notional: 131552.987,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '13:03',
    label: '1:03 PM',
    high: 451.409,
    low: 465.31,
    open: 455.247,
    close: 453.61,
    average: 466.691,
    volume: 455,
    notional: 208915.727,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '13:04',
    label: '1:04 PM',
    high: 459.82,
    low: 466.843,
    open: 446.525,
    close: 449.378,
    average: 459.611,
    volume: 102,
    notional: 44778.9,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '13:05',
    label: '1:05 PM',
    high: 446.733,
    low: 461.75,
    open: 455.145,
    close: 449.72,
    average: 465.581,
    volume: 727,
    notional: 325903.32,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '13:06',
    label: '1:06 PM',
    high: 455.434,
    low: 467.097,
    open: 466.093,
    close: 467.987,
    average: 452.149,
    volume: 298,
    notional: 130142.66,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:07',
    label: '1:07 PM',
    high: 455.98,
    low: 460.011,
    open: 458.628,
    close: 464.18,
    average: 460.28,
    volume: 420,
    notional: 181123,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '13:08',
    label: '1:08 PM',
    high: 464.1,
    low: 458.93,
    open: 460.1,
    close: 448.455,
    average: 462.455,
    volume: 322,
    notional: 149294.4,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:09',
    label: '1:09 PM',
    high: 456.03,
    low: 447.309,
    open: 455.85,
    close: 450.1,
    average: 451.633,
    volume: 227,
    notional: 102747.358,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:10',
    label: '1:10 PM',
    high: 452.121,
    low: 455.05,
    open: 455.9,
    close: 458.744,
    average: 465.959,
    volume: 714,
    notional: 323208.39,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '13:11',
    label: '1:11 PM',
    high: 450.29,
    low: 452,
    open: 461.12,
    close: 454.03,
    average: 460.714,
    volume: 502,
    notional: 229572,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '13:12',
    label: '1:12 PM',
    high: 449.635,
    low: 461.48,
    open: 454.05,
    close: 465.552,
    average: 457.626,
    volume: 450,
    notional: 204918.614,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '13:13',
    label: '1:13 PM',
    high: 450.292,
    low: 446.94,
    open: 450.82,
    close: 465.144,
    average: 461.544,
    volume: 728,
    notional: 328705.054,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '13:14',
    label: '1:14 PM',
    high: 466.34,
    low: 446.495,
    open: 452.21,
    close: 449.8,
    average: 456.252,
    volume: 211,
    notional: 93225.94,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '13:15',
    label: '1:15 PM',
    high: 452.33,
    low: 460.8,
    open: 465.56,
    close: 468.11,
    average: 464.786,
    volume: 1178,
    notional: 511258.82,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '13:16',
    label: '1:16 PM',
    high: 458.02,
    low: 447.83,
    open: 465.77,
    close: 459.27,
    average: 458.913,
    volume: 1302,
    notional: 582193.7,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '13:17',
    label: '1:17 PM',
    high: 465.86,
    low: 447,
    open: 455.13,
    close: 450.36,
    average: 452.969,
    volume: 1470,
    notional: 650003.55,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '13:18',
    label: '1:18 PM',
    high: 467.85,
    low: 460.631,
    open: 454.736,
    close: 458.52,
    average: 447.488,
    volume: 204,
    notional: 92198.3,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '13:19',
    label: '1:19 PM',
    high: 446.5,
    low: 460.614,
    open: 446.762,
    close: 455.895,
    average: 456.478,
    volume: 125,
    notional: 56312.83,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:20',
    label: '1:20 PM',
    high: 458.34,
    low: 457.44,
    open: 447.338,
    close: 450.55,
    average: 451.949,
    volume: 232,
    notional: 102631.18,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '13:21',
    label: '1:21 PM',
    high: 448.334,
    low: 454.08,
    open: 458.938,
    close: 447.106,
    average: 452.48,
    volume: 870,
    notional: 379225,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '13:22',
    label: '1:22 PM',
    high: 450.37,
    low: 467,
    open: 465.32,
    close: 455.88,
    average: 453.958,
    volume: 312,
    notional: 136215,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '13:23',
    label: '1:23 PM',
    high: 465.92,
    low: 448.23,
    open: 458.29,
    close: 461.5,
    average: 456.896,
    volume: 838,
    notional: 389535.05,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '13:24',
    label: '1:24 PM',
    high: 456.645,
    low: 467.471,
    open: 456.163,
    close: 461.1,
    average: 450.254,
    volume: 724,
    notional: 326305.9,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '13:25',
    label: '1:25 PM',
    high: 457.878,
    low: 450.358,
    open: 456.347,
    close: 454.93,
    average: 463.986,
    volume: 413,
    notional: 184672.728,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:26',
    label: '1:26 PM',
    high: 456.47,
    low: 458.375,
    open: 453.18,
    close: 457.3,
    average: 446.824,
    volume: 1050,
    notional: 456797.3,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '13:27',
    label: '1:27 PM',
    high: 467.24,
    low: 459.306,
    open: 457.03,
    close: 450.39,
    average: 448.807,
    volume: 1147,
    notional: 502720.122,
    numberOfTrades: 16,
  },
  {
    date: '2020-08-11',
    minute: '13:28',
    label: '1:28 PM',
    high: 456.793,
    low: 447.6,
    open: 468.976,
    close: 446.907,
    average: 467.223,
    volume: 103,
    notional: 45139.4,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '13:29',
    label: '1:29 PM',
    high: 463.22,
    low: 452.778,
    open: 448.97,
    close: 468.766,
    average: 466.07,
    volume: 335,
    notional: 152138.223,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '13:30',
    label: '1:30 PM',
    high: 447.251,
    low: 451.26,
    open: 461.133,
    close: 460.41,
    average: 457.717,
    volume: 462,
    notional: 203808.036,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '13:31',
    label: '1:31 PM',
    high: 467.845,
    low: 462.977,
    open: 462.445,
    close: 461.287,
    average: 459.93,
    volume: 246,
    notional: 112166.09,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:32',
    label: '1:32 PM',
    high: 460.25,
    low: 448.59,
    open: 463.67,
    close: 451.34,
    average: 466.159,
    volume: 509,
    notional: 224083.7,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:33',
    label: '1:33 PM',
    high: 451.94,
    low: 458.673,
    open: 451.227,
    close: 458.14,
    average: 450.918,
    volume: 209,
    notional: 93646.4,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '13:34',
    label: '1:34 PM',
    high: 464.545,
    low: 458.5,
    open: 466.9,
    close: 451.619,
    average: 450.67,
    volume: 309,
    notional: 134814.225,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:35',
    label: '1:35 PM',
    high: 451.81,
    low: 455.93,
    open: 448.79,
    close: 450.61,
    average: 463.379,
    volume: 721,
    notional: 319432.94,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '13:36',
    label: '1:36 PM',
    high: null,
    low: null,
    open: null,
    close: null,
    average: null,
    volume: 0,
    notional: 0,
    numberOfTrades: 0,
  },
  {
    date: '2020-08-11',
    minute: '13:37',
    label: '1:37 PM',
    high: 460.17,
    low: 455.43,
    open: 450.17,
    close: 464.72,
    average: 454.502,
    volume: 398,
    notional: 173038.7,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '13:38',
    label: '1:38 PM',
    high: 458.845,
    low: 457.149,
    open: 449.96,
    close: 452.47,
    average: 456.115,
    volume: 209,
    notional: 89312,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '13:39',
    label: '1:39 PM',
    high: null,
    low: null,
    open: null,
    close: null,
    average: null,
    volume: 0,
    notional: 0,
    numberOfTrades: 0,
  },
  {
    date: '2020-08-11',
    minute: '13:40',
    label: '1:40 PM',
    high: 457.588,
    low: 467.89,
    open: 453.82,
    close: 447.37,
    average: 460.179,
    volume: 208,
    notional: 90958.4,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '13:41',
    label: '1:41 PM',
    high: 458.62,
    low: 446.7,
    open: 453.55,
    close: 449.8,
    average: 464.534,
    volume: 306,
    notional: 136016,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '13:42',
    label: '1:42 PM',
    high: 456.8,
    low: 454.58,
    open: 466.55,
    close: 449.297,
    average: 447.427,
    volume: 854,
    notional: 375804,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '13:43',
    label: '1:43 PM',
    high: 456.92,
    low: 459.48,
    open: 447.94,
    close: 467.79,
    average: 449.857,
    volume: 779,
    notional: 358541.768,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '13:44',
    label: '1:44 PM',
    high: 460.88,
    low: 465.5,
    open: 463.2,
    close: 461.22,
    average: 454.438,
    volume: 303,
    notional: 136230.32,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:45',
    label: '1:45 PM',
    high: 460.63,
    low: 460.97,
    open: 449.67,
    close: 446.36,
    average: 458.02,
    volume: 205,
    notional: 92760,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '13:46',
    label: '1:46 PM',
    high: 453.39,
    low: 456.585,
    open: 446.197,
    close: 462.43,
    average: 461.86,
    volume: 311,
    notional: 135615.6,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '13:47',
    label: '1:47 PM',
    high: 456.01,
    low: 464.48,
    open: 467.22,
    close: 451.35,
    average: 453.87,
    volume: 208,
    notional: 90361,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '13:48',
    label: '1:48 PM',
    high: 463.83,
    low: 452.545,
    open: 454.62,
    close: 451.934,
    average: 466.729,
    volume: 410,
    notional: 183025,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '13:49',
    label: '1:49 PM',
    high: 464.205,
    low: 467.63,
    open: 450.615,
    close: 461.33,
    average: 466.546,
    volume: 206,
    notional: 89164.6,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '13:50',
    label: '1:50 PM',
    high: 459.769,
    low: 450.6,
    open: 456.882,
    close: 451.266,
    average: 454.896,
    volume: 2604,
    notional: 1154169.3,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '13:51',
    label: '1:51 PM',
    high: 463.735,
    low: 447.458,
    open: 449.312,
    close: 460.704,
    average: 454.232,
    volume: 105,
    notional: 45708.4,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '13:52',
    label: '1:52 PM',
    high: 457.879,
    low: 464.51,
    open: 461.53,
    close: 459.486,
    average: 459.557,
    volume: 523,
    notional: 226535.9,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '13:53',
    label: '1:53 PM',
    high: 455.7,
    low: 464.91,
    open: 455.72,
    close: 466.1,
    average: 467.66,
    volume: 102,
    notional: 45231,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '13:54',
    label: '1:54 PM',
    high: 453.95,
    low: 461.34,
    open: 447.598,
    close: 456.91,
    average: 464.389,
    volume: 312,
    notional: 136118.3,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '13:55',
    label: '1:55 PM',
    high: 459.55,
    low: 460.264,
    open: 466.46,
    close: 467.129,
    average: 459.929,
    volume: 387,
    notional: 170063.033,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '13:56',
    label: '1:56 PM',
    high: 466.16,
    low: 457.103,
    open: 466.5,
    close: 467.53,
    average: 454.976,
    volume: 2533,
    notional: 1102291.67,
    numberOfTrades: 13,
  },
  {
    date: '2020-08-11',
    minute: '13:57',
    label: '1:57 PM',
    high: 465,
    low: 457,
    open: 455,
    close: 460,
    average: 451,
    volume: 102,
    notional: 45851,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '13:58',
    label: '1:58 PM',
    high: 457.33,
    low: 452.36,
    open: 460.82,
    close: 458.26,
    average: 454.105,
    volume: 107,
    notional: 48751.2,
    numberOfTrades: 2,
  },
  {
    date: '2020-08-11',
    minute: '13:59',
    label: '1:59 PM',
    high: 451.911,
    low: 467.49,
    open: 464.245,
    close: 462.265,
    average: 461.075,
    volume: 699,
    notional: 317046.526,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '14:00',
    label: '2 PM',
    high: 449.21,
    low: 449.4,
    open: 460.27,
    close: 464.091,
    average: 463.384,
    volume: 712,
    notional: 319335.736,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '14:01',
    label: '2:01 PM',
    high: 453.79,
    low: 461.82,
    open: 466.08,
    close: 458.61,
    average: 467.909,
    volume: 2149,
    notional: 974259.465,
    numberOfTrades: 21,
  },
  {
    date: '2020-08-11',
    minute: '14:02',
    label: '2:02 PM',
    high: 461.088,
    low: 448.246,
    open: 452.012,
    close: 468.17,
    average: 466.51,
    volume: 1092,
    notional: 490502.57,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '14:03',
    label: '2:03 PM',
    high: 462.59,
    low: 450.63,
    open: 451.36,
    close: 458.98,
    average: 456.122,
    volume: 1141,
    notional: 498295.237,
    numberOfTrades: 18,
  },
  {
    date: '2020-08-11',
    minute: '14:04',
    label: '2:04 PM',
    high: 467.04,
    low: 452.2,
    open: 447.62,
    close: 466.72,
    average: 465.768,
    volume: 906,
    notional: 405388.267,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '14:05',
    label: '2:05 PM',
    high: 463.67,
    low: 463.931,
    open: 450.64,
    close: 462.526,
    average: 458.212,
    volume: 789,
    notional: 347877.105,
    numberOfTrades: 16,
  },
  {
    date: '2020-08-11',
    minute: '14:06',
    label: '2:06 PM',
    high: 465.39,
    low: 459.7,
    open: 451.51,
    close: 449.12,
    average: 450.13,
    volume: 407,
    notional: 183071,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '14:07',
    label: '2:07 PM',
    high: 460.47,
    low: 460.95,
    open: 466.5,
    close: 461.18,
    average: 449.208,
    volume: 402,
    notional: 179948.23,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '14:08',
    label: '2:08 PM',
    high: 455.75,
    low: 463.1,
    open: 448.68,
    close: 455.85,
    average: 459.339,
    volume: 1108,
    notional: 481657.44,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '14:09',
    label: '2:09 PM',
    high: 446.357,
    low: 447.349,
    open: 446.838,
    close: 463.553,
    average: 463.715,
    volume: 415,
    notional: 183808.89,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '14:10',
    label: '2:10 PM',
    high: 463.9,
    low: 464.73,
    open: 467.69,
    close: 459.61,
    average: 451.64,
    volume: 182,
    notional: 81782.81,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '14:11',
    label: '2:11 PM',
    high: 464.061,
    low: 460.22,
    open: 462.1,
    close: 460.1,
    average: 446.849,
    volume: 312,
    notional: 139752.3,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '14:12',
    label: '2:12 PM',
    high: 449.53,
    low: 459.25,
    open: 458.29,
    close: 457.37,
    average: 465.327,
    volume: 513,
    notional: 228365,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '14:13',
    label: '2:13 PM',
    high: 465.309,
    low: 449.91,
    open: 456.25,
    close: 448.65,
    average: 450.019,
    volume: 2432,
    notional: 1063500.51,
    numberOfTrades: 30,
  },
  {
    date: '2020-08-11',
    minute: '14:14',
    label: '2:14 PM',
    high: 455.15,
    low: 456,
    open: 465.54,
    close: 462.8,
    average: 446.574,
    volume: 2035,
    notional: 876108.45,
    numberOfTrades: 25,
  },
  {
    date: '2020-08-11',
    minute: '14:15',
    label: '2:15 PM',
    high: 445.46,
    low: 453.959,
    open: 450.81,
    close: 458.604,
    average: 456.68,
    volume: 2296,
    notional: 1055193.231,
    numberOfTrades: 20,
  },
  {
    date: '2020-08-11',
    minute: '14:16',
    label: '2:16 PM',
    high: 450.29,
    low: 453,
    open: 450.79,
    close: 457.6,
    average: 463.04,
    volume: 1533,
    notional: 680684.67,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '14:17',
    label: '2:17 PM',
    high: 454.42,
    low: 448.44,
    open: 459.36,
    close: 447.19,
    average: 445.548,
    volume: 2548,
    notional: 1125571.67,
    numberOfTrades: 33,
  },
  {
    date: '2020-08-11',
    minute: '14:18',
    label: '2:18 PM',
    high: 465.86,
    low: 462.5,
    open: 444.72,
    close: 461.61,
    average: 456.59,
    volume: 2090,
    notional: 923340.66,
    numberOfTrades: 30,
  },
  {
    date: '2020-08-11',
    minute: '14:19',
    label: '2:19 PM',
    high: 445.4,
    low: 462.96,
    open: 466.49,
    close: 459.31,
    average: 455.253,
    volume: 1002,
    notional: 431307.23,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '14:20',
    label: '2:20 PM',
    high: 459.61,
    low: 449.79,
    open: 465.39,
    close: 451.01,
    average: 464.039,
    volume: 1886,
    notional: 822192.95,
    numberOfTrades: 23,
  },
  {
    date: '2020-08-11',
    minute: '14:21',
    label: '2:21 PM',
    high: 462.05,
    low: 462.15,
    open: 447,
    close: 461.31,
    average: 448.173,
    volume: 611,
    notional: 280806.1,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '14:22',
    label: '2:22 PM',
    high: 449.01,
    low: 461.51,
    open: 451.049,
    close: 454.31,
    average: 465.162,
    volume: 766,
    notional: 336797.6,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '14:23',
    label: '2:23 PM',
    high: 450.43,
    low: 448.36,
    open: 445.795,
    close: 459.113,
    average: 463.59,
    volume: 571,
    notional: 248530.856,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '14:24',
    label: '2:24 PM',
    high: 457.568,
    low: 463.32,
    open: 453.912,
    close: 458.97,
    average: 449.251,
    volume: 3763,
    notional: 1664443.873,
    numberOfTrades: 27,
  },
  {
    date: '2020-08-11',
    minute: '14:25',
    label: '2:25 PM',
    high: 465.07,
    low: 449.47,
    open: 444.08,
    close: 449.48,
    average: 457.85,
    volume: 101,
    notional: 44731,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '14:26',
    label: '2:26 PM',
    high: 463.1,
    low: 449.99,
    open: 446.49,
    close: 461.11,
    average: 460.976,
    volume: 686,
    notional: 304088.45,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '14:27',
    label: '2:27 PM',
    high: 457.42,
    low: 465.75,
    open: 461.43,
    close: 443.957,
    average: 464.952,
    volume: 2004,
    notional: 910180.994,
    numberOfTrades: 40,
  },
  {
    date: '2020-08-11',
    minute: '14:28',
    label: '2:28 PM',
    high: 445.57,
    low: 462.4,
    open: 446.64,
    close: 453.57,
    average: 458.254,
    volume: 610,
    notional: 276178.345,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '14:29',
    label: '2:29 PM',
    high: 444.7,
    low: 456.5,
    open: 464.71,
    close: 445.44,
    average: 461.139,
    volume: 1148,
    notional: 508393,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '14:30',
    label: '2:30 PM',
    high: 446.01,
    low: 445.6,
    open: 462.19,
    close: 456.42,
    average: 450.157,
    volume: 1021,
    notional: 449088.891,
    numberOfTrades: 14,
  },
  {
    date: '2020-08-11',
    minute: '14:31',
    label: '2:31 PM',
    high: 451.01,
    low: 458.934,
    open: 444.313,
    close: 447.21,
    average: 464.342,
    volume: 664,
    notional: 298972.86,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '14:32',
    label: '2:32 PM',
    high: 451.5,
    low: 447.99,
    open: 464,
    close: 456.77,
    average: 460.124,
    volume: 1040,
    notional: 453064.6,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '14:33',
    label: '2:33 PM',
    high: 450.039,
    low: 450.88,
    open: 449,
    close: 463.249,
    average: 453.182,
    volume: 1968,
    notional: 878006,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '14:34',
    label: '2:34 PM',
    high: 445.06,
    low: 447.27,
    open: 445.31,
    close: 447.21,
    average: 461.011,
    volume: 242,
    notional: 106093.6,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '14:35',
    label: '2:35 PM',
    high: 445.417,
    low: 459.414,
    open: 454.079,
    close: 449.45,
    average: 445.215,
    volume: 2279,
    notional: 999610.1,
    numberOfTrades: 23,
  },
  {
    date: '2020-08-11',
    minute: '14:36',
    label: '2:36 PM',
    high: 451.3,
    low: 450.68,
    open: 456.68,
    close: 446.98,
    average: 461.446,
    volume: 1148,
    notional: 500368.764,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '14:37',
    label: '2:37 PM',
    high: 457.279,
    low: 463.34,
    open: 449.89,
    close: 459.2,
    average: 450.661,
    volume: 5821,
    notional: 2491553.79,
    numberOfTrades: 35,
  },
  {
    date: '2020-08-11',
    minute: '14:38',
    label: '2:38 PM',
    high: 454.48,
    low: 443.03,
    open: 445.4,
    close: 454.3,
    average: 464.924,
    volume: 1215,
    notional: 551909.28,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '14:39',
    label: '2:39 PM',
    high: 461.81,
    low: 460.72,
    open: 444.6,
    close: 464.19,
    average: 460.224,
    volume: 926,
    notional: 415563.7,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '14:40',
    label: '2:40 PM',
    high: 454.32,
    low: 454.576,
    open: 445.909,
    close: 463.01,
    average: 455.36,
    volume: 1271,
    notional: 567899.594,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '14:41',
    label: '2:41 PM',
    high: 460.9,
    low: 451.65,
    open: 460.1,
    close: 451.27,
    average: 457.245,
    volume: 618,
    notional: 270281,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '14:42',
    label: '2:42 PM',
    high: 447.88,
    low: 447.14,
    open: 448.7,
    close: 463.9,
    average: 452.821,
    volume: 453,
    notional: 200528.7,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '14:43',
    label: '2:43 PM',
    high: 449.655,
    low: 459.59,
    open: 462.682,
    close: 448.114,
    average: 460.548,
    volume: 623,
    notional: 276572.5,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '14:44',
    label: '2:44 PM',
    high: 453.13,
    low: 449.9,
    open: 449.683,
    close: 455.56,
    average: 462.271,
    volume: 409,
    notional: 179584,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '14:45',
    label: '2:45 PM',
    high: 460.12,
    low: 443.37,
    open: 458.32,
    close: 445.155,
    average: 458.65,
    volume: 306,
    notional: 140219.05,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '14:46',
    label: '2:46 PM',
    high: 449.78,
    low: 459.488,
    open: 459.56,
    close: 458.937,
    average: 451.031,
    volume: 2121,
    notional: 930215.53,
    numberOfTrades: 14,
  },
  {
    date: '2020-08-11',
    minute: '14:47',
    label: '2:47 PM',
    high: 450.769,
    low: 459.203,
    open: 449.4,
    close: 454.272,
    average: 457.93,
    volume: 2907,
    notional: 1263589.255,
    numberOfTrades: 26,
  },
  {
    date: '2020-08-11',
    minute: '14:48',
    label: '2:48 PM',
    high: 445.47,
    low: 463.53,
    open: 447.45,
    close: 459.62,
    average: 463.351,
    volume: 2543,
    notional: 1128845.161,
    numberOfTrades: 18,
  },
  {
    date: '2020-08-11',
    minute: '14:49',
    label: '2:49 PM',
    high: 458.82,
    low: 445.3,
    open: 456.54,
    close: 444.47,
    average: 450.58,
    volume: 1261,
    notional: 548017.75,
    numberOfTrades: 13,
  },
  {
    date: '2020-08-11',
    minute: '14:50',
    label: '2:50 PM',
    high: 459.7,
    low: 463.86,
    open: 457,
    close: 458.43,
    average: 460.767,
    volume: 3349,
    notional: 1468370.82,
    numberOfTrades: 22,
  },
  {
    date: '2020-08-11',
    minute: '14:51',
    label: '2:51 PM',
    high: 463.77,
    low: 464.24,
    open: 449.085,
    close: 460.97,
    average: 464.218,
    volume: 1000,
    notional: 437909.98,
    numberOfTrades: 10,
  },
  {
    date: '2020-08-11',
    minute: '14:52',
    label: '2:52 PM',
    high: 463.933,
    low: 449.96,
    open: 456.872,
    close: 463.943,
    average: 451.21,
    volume: 3148,
    notional: 1373227.1,
    numberOfTrades: 23,
  },
  {
    date: '2020-08-11',
    minute: '14:53',
    label: '2:53 PM',
    high: 447.821,
    low: 445.93,
    open: 444.82,
    close: 456.24,
    average: 456.684,
    volume: 1854,
    notional: 829967.29,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '14:54',
    label: '2:54 PM',
    high: 443.924,
    low: 461.25,
    open: 462.16,
    close: 448.56,
    average: 453.64,
    volume: 5479,
    notional: 2405884.572,
    numberOfTrades: 38,
  },
  {
    date: '2020-08-11',
    minute: '14:55',
    label: '2:55 PM',
    high: 456.244,
    low: 445.106,
    open: 464.18,
    close: 455.5,
    average: 462.742,
    volume: 751,
    notional: 329534.83,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '14:56',
    label: '2:56 PM',
    high: 449.155,
    low: 460.84,
    open: 458.866,
    close: 449.959,
    average: 452.812,
    volume: 1612,
    notional: 716759.371,
    numberOfTrades: 19,
  },
  {
    date: '2020-08-11',
    minute: '14:57',
    label: '2:57 PM',
    high: 444.3,
    low: 449.27,
    open: 462.23,
    close: 459.06,
    average: 443.814,
    volume: 570,
    notional: 246543.65,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '14:58',
    label: '2:58 PM',
    high: 460.47,
    low: 449.264,
    open: 464.632,
    close: 461.525,
    average: 452.584,
    volume: 1,
    notional: 457.74,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '14:59',
    label: '2:59 PM',
    high: 445.7,
    low: 454.74,
    open: 463.71,
    close: 462.08,
    average: 452.006,
    volume: 982,
    notional: 431612.7,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '15:00',
    label: '3 PM',
    high: 449.23,
    low: 443.53,
    open: 461.94,
    close: 456.48,
    average: 454.701,
    volume: 598,
    notional: 270403.17,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '15:01',
    label: '3:01 PM',
    high: 447.387,
    low: 459.443,
    open: 446.69,
    close: 453.48,
    average: 446.453,
    volume: 629,
    notional: 268425.6,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '15:02',
    label: '3:02 PM',
    high: 465.3,
    low: 459.51,
    open: 450.6,
    close: 456.37,
    average: 462.19,
    volume: 702,
    notional: 319862,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '15:03',
    label: '3:03 PM',
    high: 463.55,
    low: 447.842,
    open: 447.529,
    close: 450.22,
    average: 459.235,
    volume: 6,
    notional: 2732.37,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '15:04',
    label: '3:04 PM',
    high: 455.51,
    low: 454.599,
    open: 461.95,
    close: 442.201,
    average: 452.142,
    volume: 3196,
    notional: 1418811.1,
    numberOfTrades: 47,
  },
  {
    date: '2020-08-11',
    minute: '15:05',
    label: '3:05 PM',
    high: 464.09,
    low: 460.52,
    open: 453,
    close: 462.72,
    average: 443.639,
    volume: 3056,
    notional: 1360946.298,
    numberOfTrades: 36,
  },
  {
    date: '2020-08-11',
    minute: '15:06',
    label: '3:06 PM',
    high: 453.57,
    low: 458.21,
    open: 462.97,
    close: 460.51,
    average: 449.73,
    volume: 1148,
    notional: 491510.8,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '15:07',
    label: '3:07 PM',
    high: 460.06,
    low: 446.11,
    open: 448.71,
    close: 458.228,
    average: 447.719,
    volume: 131,
    notional: 57715.11,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '15:08',
    label: '3:08 PM',
    high: 456.85,
    low: 450.77,
    open: 460.82,
    close: 448.25,
    average: 459.007,
    volume: 307,
    notional: 138723,
    numberOfTrades: 3,
  },
  {
    date: '2020-08-11',
    minute: '15:09',
    label: '3:09 PM',
    high: 444.77,
    low: 453.08,
    open: 461.845,
    close: 452.68,
    average: 443.764,
    volume: 411,
    notional: 179814.616,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '15:10',
    label: '3:10 PM',
    high: 453.52,
    low: 441.9,
    open: 441.858,
    close: 452.69,
    average: 458.76,
    volume: 773,
    notional: 347028.267,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '15:11',
    label: '3:11 PM',
    high: 457.26,
    low: 457.09,
    open: 453.65,
    close: 452.71,
    average: 445.02,
    volume: 810,
    notional: 366726.03,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '15:12',
    label: '3:12 PM',
    high: 462.75,
    low: 455,
    open: 450.165,
    close: 460.77,
    average: 451.188,
    volume: 521,
    notional: 229549.61,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '15:13',
    label: '3:13 PM',
    high: 454.25,
    low: 446.495,
    open: 452.269,
    close: 458.64,
    average: 453.561,
    volume: 496,
    notional: 220248.809,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '15:14',
    label: '3:14 PM',
    high: 446.54,
    low: 463.773,
    open: 451,
    close: 453.246,
    average: 450.399,
    volume: 1792,
    notional: 762483.68,
    numberOfTrades: 25,
  },
  {
    date: '2020-08-11',
    minute: '15:15',
    label: '3:15 PM',
    high: 463,
    low: 455.49,
    open: 454.6,
    close: 452.82,
    average: 454.214,
    volume: 1223,
    notional: 553969.34,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '15:16',
    label: '3:16 PM',
    high: 452.93,
    low: 459.47,
    open: 447.95,
    close: 441.67,
    average: 462.803,
    volume: 1236,
    notional: 550874.99,
    numberOfTrades: 17,
  },
  {
    date: '2020-08-11',
    minute: '15:17',
    label: '3:17 PM',
    high: 450.76,
    low: 450.26,
    open: 451.16,
    close: 453.45,
    average: 445.857,
    volume: 403,
    notional: 177133.1,
    numberOfTrades: 4,
  },
  {
    date: '2020-08-11',
    minute: '15:18',
    label: '3:18 PM',
    high: 445.02,
    low: 460,
    open: 456.9,
    close: 449.82,
    average: 453.9,
    volume: 1137,
    notional: 482775.13,
    numberOfTrades: 24,
  },
  {
    date: '2020-08-11',
    minute: '15:19',
    label: '3:19 PM',
    high: 447.93,
    low: 460.41,
    open: 457.11,
    close: 456.62,
    average: 450.039,
    volume: 2126,
    notional: 940371.232,
    numberOfTrades: 23,
  },
  {
    date: '2020-08-11',
    minute: '15:20',
    label: '3:20 PM',
    high: 445.92,
    low: 459.188,
    open: 459.499,
    close: 457.41,
    average: 446.954,
    volume: 1064,
    notional: 485504.7,
    numberOfTrades: 12,
  },
  {
    date: '2020-08-11',
    minute: '15:21',
    label: '3:21 PM',
    high: 456.95,
    low: 455.01,
    open: 449.8,
    close: 440.951,
    average: 451.685,
    volume: 1943,
    notional: 849733.53,
    numberOfTrades: 19,
  },
  {
    date: '2020-08-11',
    minute: '15:22',
    label: '3:22 PM',
    high: 454.21,
    low: 444.32,
    open: 456.53,
    close: 444.48,
    average: 442.135,
    volume: 3026,
    notional: 1270100.57,
    numberOfTrades: 20,
  },
  {
    date: '2020-08-11',
    minute: '15:23',
    label: '3:23 PM',
    high: 444.55,
    low: 440.01,
    open: 447.06,
    close: 456.56,
    average: 446.748,
    volume: 2000,
    notional: 920716.2,
    numberOfTrades: 14,
  },
  {
    date: '2020-08-11',
    minute: '15:24',
    label: '3:24 PM',
    high: 460.8,
    low: 457.225,
    open: 446.41,
    close: 448.28,
    average: 455.65,
    volume: 770,
    notional: 329369.89,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '15:25',
    label: '3:25 PM',
    high: 457.508,
    low: 450.163,
    open: 459.454,
    close: 449.529,
    average: 458.769,
    volume: 10,
    notional: 4575.78,
    numberOfTrades: 1,
  },
  {
    date: '2020-08-11',
    minute: '15:26',
    label: '3:26 PM',
    high: 453.73,
    low: 452.25,
    open: 457.35,
    close: 442.66,
    average: 448.337,
    volume: 751,
    notional: 332200.95,
    numberOfTrades: 6,
  },
  {
    date: '2020-08-11',
    minute: '15:27',
    label: '3:27 PM',
    high: 446.84,
    low: 441.02,
    open: 449.01,
    close: 454.428,
    average: 455.911,
    volume: 3138,
    notional: 1374637.8,
    numberOfTrades: 22,
  },
  {
    date: '2020-08-11',
    minute: '15:28',
    label: '3:28 PM',
    high: 452.15,
    low: 459.5,
    open: 453.41,
    close: 456.43,
    average: 444.238,
    volume: 406,
    notional: 182055,
    numberOfTrades: 5,
  },
  {
    date: '2020-08-11',
    minute: '15:29',
    label: '3:29 PM',
    high: 449.254,
    low: 442.75,
    open: 445.38,
    close: 459.47,
    average: 441.787,
    volume: 459,
    notional: 207520.53,
    numberOfTrades: 8,
  },
  {
    date: '2020-08-11',
    minute: '15:30',
    label: '3:30 PM',
    high: 459.06,
    low: 457.6,
    open: 453.53,
    close: 456.33,
    average: 459.061,
    volume: 1474,
    notional: 639466.244,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '15:31',
    label: '3:31 PM',
    high: 452.71,
    low: 449.324,
    open: 461.43,
    close: 456.908,
    average: 453.337,
    volume: 1952,
    notional: 883636.957,
    numberOfTrades: 19,
  },
  {
    date: '2020-08-11',
    minute: '15:32',
    label: '3:32 PM',
    high: 443.625,
    low: 460.82,
    open: 445.504,
    close: 441.76,
    average: 459.324,
    volume: 1403,
    notional: 609956.82,
    numberOfTrades: 7,
  },
  {
    date: '2020-08-11',
    minute: '15:33',
    label: '3:33 PM',
    high: 442.1,
    low: 460.66,
    open: 458.65,
    close: 459.12,
    average: 451.5,
    volume: 2254,
    notional: 1024704.806,
    numberOfTrades: 19,
  },
  {
    date: '2020-08-11',
    minute: '15:34',
    label: '3:34 PM',
    high: 448.68,
    low: 452.94,
    open: 452.12,
    close: 447.11,
    average: 459.495,
    volume: 957,
    notional: 420492.65,
    numberOfTrades: 11,
  },
  {
    date: '2020-08-11',
    minute: '15:35',
    label: '3:35 PM',
    high: 457.24,
    low: 445.58,
    open: 451.61,
    close: 458.78,
    average: 452.67,
    volume: 1581,
    notional: 708724.35,
    numberOfTrades: 22,
  },
  {
    date: '2020-08-11',
    minute: '15:36',
    label: '3:36 PM',
    high: 444.06,
    low: 446.8,
    open: 449.7,
    close: 444.81,
    average: 451.445,
    volume: 1173,
    notional: 515387.39,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '15:37',
    label: '3:37 PM',
    high: 440.42,
    low: 439.848,
    open: 458.951,
    close: 451.81,
    average: 456.748,
    volume: 3269,
    notional: 1412416.302,
    numberOfTrades: 56,
  },
  {
    date: '2020-08-11',
    minute: '15:38',
    label: '3:38 PM',
    high: 453.84,
    low: 452.4,
    open: 461.357,
    close: 443.24,
    average: 454.288,
    volume: 4330,
    notional: 1906002.432,
    numberOfTrades: 51,
  },
  {
    date: '2020-08-11',
    minute: '15:39',
    label: '3:39 PM',
    high: 443.548,
    low: 459.2,
    open: 456.3,
    close: 447.4,
    average: 457.527,
    volume: 13245,
    notional: 5999832.002,
    numberOfTrades: 30,
  },
  {
    date: '2020-08-11',
    minute: '15:40',
    label: '3:40 PM',
    high: 441.31,
    low: 451.82,
    open: 458.683,
    close: 458.538,
    average: 443.082,
    volume: 1505,
    notional: 665963.57,
    numberOfTrades: 23,
  },
  {
    date: '2020-08-11',
    minute: '15:41',
    label: '3:41 PM',
    high: 458.702,
    low: 454.58,
    open: 453.908,
    close: 444.06,
    average: 450.26,
    volume: 835,
    notional: 358587.654,
    numberOfTrades: 15,
  },
  {
    date: '2020-08-11',
    minute: '15:42',
    label: '3:42 PM',
    high: 441.97,
    low: 440.88,
    open: 451.44,
    close: 443.23,
    average: 446.361,
    volume: 857,
    notional: 369613.94,
    numberOfTrades: 16,
  },
  {
    date: '2020-08-11',
    minute: '15:43',
    label: '3:43 PM',
    high: 459.612,
    low: 451.38,
    open: 447.979,
    close: 445.6,
    average: 443.528,
    volume: 1622,
    notional: 715439.45,
    numberOfTrades: 18,
  },
  {
    date: '2020-08-11',
    minute: '15:44',
    label: '3:44 PM',
    high: 457.81,
    low: 454.15,
    open: 450.23,
    close: 439.99,
    average: 447.632,
    volume: 644,
    notional: 277302.74,
    numberOfTrades: 9,
  },
  {
    date: '2020-08-11',
    minute: '15:45',
    label: '3:45 PM',
    high: 447,
    low: 438.53,
    open: 449.4,
    close: 452.17,
    average: 441.041,
    volume: 3571,
    notional: 1600399.98,
    numberOfTrades: 35,
  },
  {
    date: '2020-08-11',
    minute: '15:46',
    label: '3:46 PM',
    high: 442.99,
    low: 448.131,
    open: 442.63,
    close: 446.62,
    average: 445.69,
    volume: 2304,
    notional: 986006.093,
    numberOfTrades: 20,
  },
  {
    date: '2020-08-11',
    minute: '15:47',
    label: '3:47 PM',
    high: 453.52,
    low: 454.27,
    open: 448.46,
    close: 438.08,
    average: 451.33,
    volume: 2381,
    notional: 1034648.7,
    numberOfTrades: 27,
  },
  {
    date: '2020-08-11',
    minute: '15:48',
    label: '3:48 PM',
    high: 439.2,
    low: 439.987,
    open: 444.2,
    close: 442.68,
    average: 457.673,
    volume: 1069,
    notional: 482821.86,
    numberOfTrades: 13,
  },
  {
    date: '2020-08-11',
    minute: '15:49',
    label: '3:49 PM',
    high: 452.5,
    low: 446.44,
    open: 459.71,
    close: 456.84,
    average: 454.618,
    volume: 4363,
    notional: 1956906.3,
    numberOfTrades: 27,
  },
  {
    date: '2020-08-11',
    minute: '15:50',
    label: '3:50 PM',
    high: 439.8,
    low: 439,
    open: 458.99,
    close: 458.349,
    average: 444.058,
    volume: 4209,
    notional: 1803810.277,
    numberOfTrades: 55,
  },
  {
    date: '2020-08-11',
    minute: '15:51',
    label: '3:51 PM',
    high: 439.91,
    low: 442.5,
    open: 455.1,
    close: 445.93,
    average: 437.711,
    volume: 10358,
    notional: 4700734.32,
    numberOfTrades: 133,
  },
  {
    date: '2020-08-11',
    minute: '15:52',
    label: '3:52 PM',
    high: 451.11,
    low: 448.39,
    open: 455.53,
    close: 442.31,
    average: 450.069,
    volume: 5331,
    notional: 2301168.64,
    numberOfTrades: 30,
  },
  {
    date: '2020-08-11',
    minute: '15:53',
    label: '3:53 PM',
    high: 442.54,
    low: 443.62,
    open: 442.15,
    close: 453.521,
    average: 441.289,
    volume: 2635,
    notional: 1193256.394,
    numberOfTrades: 29,
  },
  {
    date: '2020-08-11',
    minute: '15:54',
    label: '3:54 PM',
    high: 457.2,
    low: 449.32,
    open: 437.25,
    close: 452.19,
    average: 447.889,
    volume: 12123,
    notional: 5274366.44,
    numberOfTrades: 132,
  },
  {
    date: '2020-08-11',
    minute: '15:55',
    label: '3:55 PM',
    high: 437.97,
    low: 453.07,
    open: 437.9,
    close: 456.719,
    average: 452.91,
    volume: 9051,
    notional: 3961634.1,
    numberOfTrades: 94,
  },
  {
    date: '2020-08-11',
    minute: '15:56',
    label: '3:56 PM',
    high: 438.29,
    low: 438.31,
    open: 449.4,
    close: 448.135,
    average: 453.028,
    volume: 4913,
    notional: 2161054.82,
    numberOfTrades: 58,
  },
  {
    date: '2020-08-11',
    minute: '15:57',
    label: '3:57 PM',
    high: 453.7,
    low: 452.94,
    open: 449.35,
    close: 449.983,
    average: 449.424,
    volume: 4253,
    notional: 1824588.478,
    numberOfTrades: 42,
  },
  {
    date: '2020-08-11',
    minute: '15:58',
    label: '3:58 PM',
    high: 448.206,
    low: 445.037,
    open: 455.58,
    close: 448.45,
    average: 451.982,
    volume: 3808,
    notional: 1625944.811,
    numberOfTrades: 48,
  },
  {
    date: '2020-08-11',
    minute: '15:59',
    label: '3:59 PM',
    high: 454.41,
    low: 444.56,
    open: 452.35,
    close: 453.172,
    average: 458.471,
    volume: 1610,
    notional: 707352.497,
    numberOfTrades: 45,
  },
];
